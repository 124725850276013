<template>
  <div data-app>
    <v-dialog
      v-model="showModal"
      max-width="800"
      persistent
    >
      <v-card
        elevation="2"
        raised
      >
        <v-form v-model="valid" ref="addNotificationForm">
          <v-container>
            <v-card-title>
              Create a Notification
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="notificationTitle"
                    :rules="notificationTitleRules"
                    label="*Notification Title"
                    placeholder="*Type the notification's title here."
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="notification"
                    :rules="notificationContentRules"
                    label="Notification Content"
                    placeholder="*Type the notification's content here."
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="belongsTo"
                    :rules="userSelectionRules"
                    :items="users"
                    item-text="name_or_email"
                    item-value="id"
                    label="Who Should Get This Notification?"
                    multiple
                  >
                    <template v-slot:prepend-item>
                      <v-container>
                        <v-text-field
                          id="search_users"
                          label="Search User"
                          append-icon="search"
                          hint="Hit enter to search users"
                          v-model="searchQuery"
                          @keydown.enter.prevent="searchUsers"
                        />
                      </v-container>
                      <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggle"
                        >
                        <v-list-item-action>
                          <v-icon>
                            {{ selectIcon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Select All
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:append-item>
                      <div v-intersect="onIntersect" class="mt-2">
                        <LoadingSpinner
                          :loading="usersLoading"
                          :size="'25px'"
                          :color="'#70A300'"
                          :borderWidth="'3px'"
                        />
                      </div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                @click="$emit('closeModal')"
                raised
              >
                Close
              </v-btn>
              <v-btn
                class="green-button ml-2"
                @click="validateForm"
                raised
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LoadingSpinner from '../LoadingSpinner.vue';

export default {
  name: 'AddNotificationModal',
  components: {
    LoadingSpinner,
  },
  props: {
    showModal: { type: Boolean, required: true },
    clearForm: { type: Boolean, requried: true },
  },
  data() {
    return {
      valid: false,
      notificationTitle: '',
      notification: '',
      belongsTo: [],
      icons: [],
      iconSelected: '',
      searchQuery: '',
      users: [],
      page: 1,
      usersLoading: false,
      searchLoading: false,
      enteredSearchString: '',
      lastPageUsersList: 1,
      cacheData: false,
      notificationTitleRules: [
        (v) => !!v || 'Notification title is required',
        (v) => v.length > 3 || 'Notification title must be greater than 3 characters',
      ],
      notificationContentRules: [
        (v) => !!v || 'Notification content is required',
        (v) => v.length > 3 || 'Notification content must be greater than 3 characters',
      ],
      userSelectionRules: [
        (v) => v.length > 0 || 'Select at least one user',
      ],
    };
  },
  async created() {
    await this.$store.dispatch('admin/getUsers');
    this.users = this.$store.state.admin.users;
    this.icons = this.$store.state.notifications.icons;
    this.lastPageUsersList = this.$store.state.admin.lastPageUsersList;
  },
  watch: {
    clearForm(newVal) {
      if (newVal) {
        this.clearFormData();
      }
    },
  },
  computed: {
    lastPage() {
      return this.$store.state.admin.lastPageUsersList;
    },
    selectAllUsers() {
      return (
        this.belongsTo.length > 0 && this.belongsTo.length === this.users.length
      );
    },
    selectSomeUsers() {
      return this.belongsTo.length > 0 && !this.selectAllUsers;
    },
    selectIcon() {
      if (this.selectAllUsers) return 'check_box';
      if (this.selectSomeUsers) return 'disabled_by_default';
      return 'check_box_outline_blank';
    },
  },
  methods: {
    closeModal() {
      this.notificationTitle = '';
      this.notification = '';
      this.belongsTo = [];
      this.$emit('closeModal');
      this.$refs.addNotificationForm.resetValidation();
    },
    validateForm() {
      if (this.$refs.addNotificationForm.validate()) {
        this.saveForm();
        this.$refs.addNotificationForm.resetValidation();
      }
    },
    async saveForm() {
      const data = {
        notification:
        {
          title: this.notificationTitle,
          text: this.notification,
          user_ids: this.belongsTo,
          icon: this.iconSelected,
        },
      };
      await this.$store.dispatch('notifications/saveNotification', data);
      this.closeModal();
    },
    clearFormData() {
      this.notificationTitle = '';
      this.notification = '';
      this.belongsTo = '';
      this.$refs.addNotificationForm.resetValidation();
    },
    toggle() {
      this.$nextTick(async () => {
        this.searchLoading = true;
        if (this.selectAllUsers) {
          this.belongsTo = [];
        } else {
          if (this.enteredSearchString === '' && this.cacheData !== true) {
            await this.$store.dispatch('admin/getUsers', {
              selectAll: true,
            });
            this.users = this.$store.state.admin.users;
            this.lastPageUsersList = this.$store.state.admin.lastPageUsersList;
            this.cacheData = true;
          }
          this.belongsTo = this.users.map((user) => user.id);
        }
        this.searchLoading = false;
      });
    },
    async onIntersect() {
      if (this.enteredSearchString === '' && this.lastPageUsersList >= this.page) {
        return this.fetchMoreUsers();
      }
    },
    async searchUsers() {
      this.page = 1;
      this.searchLoading = true;
      this.enteredSearchString = this.searchQuery;
      await this.$store.dispatch('admin/getUsers', {
        searchQuery: this.enteredSearchString,
      });

      this.users = this.$store.state.admin.users;
      this.lastPageUsersList = this.$store.state.admin.lastPageUsersList;
      this.searchLoading = false;
    },
    async fetchMoreUsers() {
      this.usersLoading = true;
      this.page += 1;

      await this.$store.dispatch('admin/getUsers', {
        pageQuery: this.page,
      });
      this.users = [...this.users, ...this.$store.state.admin.users];
      this.lastPageUsersList = this.$store.state.admin.lastPageUsersList;
      this.usersLoading = false;
    },
  },
};
</script>

<style scoped>
/* field text not visible fix */
.not-found-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  line-height: 1.25em;
  white-space: nowrap;
  color: #fff !important;
}
#icon:after,
#belongs-to:after,
#notification_title:after {
  background-color: #444 !important;
}
.select-all-active-text {
  color: #448aff !important;
}

.select-icon-active {
  color: #448aff !important;
}

.search-box {
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.search-field {
  color: #fff !important;
  border-color: #fff !important;
}

.search-field >>> .v-text-field {
  color: #fff !important;
}

.search-field >>> .v-messages__wrapper {
  color: rgb(221, 221, 221) !important;
}

.search-field >>> .v-label--active {
  transform: translateY(-18px) translateX(-10px) scale(0.75);
}

.search-field
  >>> .v-text-field:not(.v-input--has-state):hover
  > .v-input__control
  > .v-input__slot:before {
  border-color: #fff !important;
}
.search-field >>> .v-input__slot:before {
  border-color: #fff !important;
  opacity: 0.8;
}
.search-field >>> input {
  color: #fff !important;
}

.search-field >>> .v-label {
  color: #fff !important;
}

.add-notification-modal {
  width: 600px;
}
.md-dialog-container.md-theme-default {
  min-height: 200px;
  max-height: 1200px;
  min-width: 600px !important;
  max-width: 1000px;
  overflow-y: auto !important;
}
.md-has-textarea {
  border: 1px #424242 solid !important;
  border-radius: 5px !important;
}
.md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
  border-color: #70A300 !important;
}
.md-has-textarea:active {
  border-color: none;
}
.md-field.md-theme-default label {
  color: #424242 !important;
}
.md-list-item-text {
  color: #424242 !important;
}
.md-list.md-theme-default {
  background-color: #fff !important;
  color: #424242 !important;
}

.md-field .md-input,
.md-field .md-textarea,
.md-input {
  border-bottom: 1px black solid;
  -webkit-text-fill-color: #424242 !important;
}

.md-menu-content.md-select-menu {
  margin-left: 1rem;
}

.md-checkbox.md-theme-default .md-checkbox-container {
  border-color: #424242 !important;
  background-color: #fff !important;
}
.md-checkbox.md-theme-default.md-checked .md-checkbox-container:after {
  border-color: #70A300;
}
.md-list.md-theme-default .md-selected .md-list-item-content {
  color: #70A300;
}
.default.md-has-value .md-input {
  -webkit-text-fill-color: #424242;
}

.md-card {
  background-color: #fff !important;
}
.text {
  color: #424242 !important;
}
.green-button {
  background-color: #70A300 !important;
  color: white !important;
}
.close-button {
  background-color: #505050 !important;
}
.not-valid {
  color: red;
  border: 1px red solid;
  border-radius: 3px;
}
.md-field.md-has-value label {
  padding-left: 0 !important;
}
.md-dialog-container {
  max-height: 100% !important;
}

.loading-wrapper >>> .md-list-item-content {
  display: flex;
  align-items: center;
  justify-content: center !important;
}
</style>
