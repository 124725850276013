import pkceChallenge from 'pkce-challenge';
// eslint-disable-next-line import/no-cycle
import ErrorService from '../../../helpers/ErrorHandling';

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
const FRONTEND_URL = process.env.VUE_APP_FRONTEND_URL;

export default {
  async getChallenge() {
    return pkceChallenge(128);
  },

  async checkUser({ commit }) {
    const response = await fetch(
      `${BACKEND_URL}/sso/check_user`,
      {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
      },
    );
    if (response.status !== 404) {
      const data = await response.json();
      commit('setUserRoles', data);
    }

    return response.status;
  },

  async loginUrl() {
    let ssoURL = '';
    const env = process.env.NODE_ENV;

    if (env === 'production') {
      ssoURL = `https://identity.randallreilly.com/connect/authorize?client_id=StrategizeClient&redirect_uri=${FRONTEND_URL}/callback&response_type=code&scope=openid profile role offline_access&response_mode=query`;
    } else {
      ssoURL = `https://int-identity.randallreilly.com/connect/authorize?client_id=StrategizeClient&redirect_uri=${FRONTEND_URL}/callback&response_type=code&scope=openid profile role offline_access&response_mode=query`;
    }

    return ssoURL;
  },

  async ssoLogin({ commit }, payload) {
    const params = [
      `auth_code=${payload.auth_code}`,
      `code_verifier=${payload.code_verifier}`,
    ].join('&');

    const response = await fetch(
      `${BACKEND_URL}/sso/login?${params}`,
      {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: {
          sso: {
            auth_code: payload.auth_code,
            code_verifier: payload.code_verifier,
          },
        },
      },
    );
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      commit('deleteCodeChallengeMutation');
      commit('setAuthenticated', data.authenticated);
      commit('setUserData', data.user_info);
    }
  },

  async ssoLogout() {
    const response = await fetch(`${BACKEND_URL}/sso/logout`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
    });
    const data = await response.json();
    sessionStorage.removeItem('lastPageViewed');
    localStorage.removeItem('lastPageViewed');
    if (data.redirect_url) {
      window.location.href = data.redirect_url;
    } else {
      const env = process.env.NODE_ENV;
      localStorage.clear(); // Clears local storage
      sessionStorage.clear(); // Clears session storage
      document.cookie = ''; // Clears all cookies
      if (env === 'production') {
        window.location.href = 'https://identity.randallreilly.com/Account/login';
      } else {
        window.location.href = 'https://int-identity.randallreilly.com/Account/login';
      }
    }
  },
};
