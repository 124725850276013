<template>
  <!-- eslint-disable max-len -->
  <div
    class="pill-button"
    :style="`height: ${height}; width: ${width};
    background-color: ${backgroundColor} !important;
    color: ${textColor};
    font-size: ${fontSize};
    font-family: ${fontFamily};
    border-style: ${border ? 'solid' : ''};
    border-color: ${borderColor};
    border-width: ${borderWidth};
    padding-right: ${paddingRight};
    `"
  >
    {{ text }}
    <v-icon v-if="closeButton" @click.native="doAction" class="close-icon ml-2">close</v-icon>
  </div>
</template>
<script>
export default {
  name: 'PillButton',
  props: {
    action: { type: String, required: false },
    height: { type: String, required: true },
    text: { type: String, required: false },
    width: { type: String, required: false },
    backgroundColor: { type: String, required: false },
    border: { type: Boolean, required: false, default: false },
    borderColor: { type: String, required: false },
    textColor: { type: String, required: false },
    fontFamily: { type: String, required: false },
    fontSize: { type: String, required: false },
    paddingRight: { type: String, required: false },
    borderWidth: { type: String, required: false },
    closeButton: { type: Boolean, reuqired: true, default: true },
  },
  methods: {
    doAction() {
      this.$emit(`${this.$props.action}`, this.$props.text);
    },
  },
};
</script>
