import ErrorService from '../../../helpers/ErrorHandling';

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
const ADMIN_URL = `${BACKEND_URL}/strategize_frontend/admin`;

// Actions
export default {
  async getUsers({ commit }, payload) {
    const page = payload && payload.pageQuery !== undefined ? payload.pageQuery : 1;
    const url = new URL(`${ADMIN_URL}/users`);

    if (payload && payload.searchQuery !== undefined && payload.searchQuery !== '') {
      url.searchParams.set('query', payload.searchQuery);
    }
    if (payload && payload.selectAll !== undefined) {
      url.searchParams.set('select_all', true);
    }
    url.searchParams.set('page', page);
    const response = await fetch(url.href, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
    });
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) await commit('setUsers', data);
  },
};
