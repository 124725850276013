<template>
  <div class="bar background">
    <div class="bar progress" v-bind:style="progressStyle">
      <div class="bar loading">
        <div class="hole">
          <span v-bind:style="scoreStyle" :id="id">
            {{ this.score }}
          </span>
          <div class="for-rounded-border" v-bind:style="forRoundedBorder"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScoreProgressCircle',
  props: {
    score: { type: Number, required: true },
    id: { type: String, required: false },
  },
  computed: {
    progressStyle() {
      let gradientColors = [];

      const scorePercent = (this.score / 10) * 100;
      if (this.score <= 3) {
        gradientColors = [`#70A300 ${scorePercent}%`];
      } else if (this.score <= 6) {
        gradientColors = ['#70A300 0', '#70A300 20%', '#1B87C0 35%', `#1B87C0 ${scorePercent}%`];
      } else {
        gradientColors = [
          '#70A300 0',
          '#70A300 20%',
          '#1B87C0 35%',
          '#1B87C0 58%',
          '#BC2333 68%',
          `#BC2333 ${scorePercent}%`,
        ];
      }

      const colorStops = ['from 180deg', ...gradientColors, 'transparent 0'].join(',');

      return {
        background: `conic-gradient(${colorStops})`,
      };
    },
    scoreStyle() {
      let color = '';
      if (this.score <= 3) {
        color = '#70A300';
      } else if (this.score <= 6) {
        color = '#1B87C0';
      } else {
        color = '#BC2333';
      }

      return {
        color,
      };
    },
    forRoundedBorder() {
      const degree = 360 * (this.score / 10);
      let color = '';
      if (this.score <= 3) {
        color = '#70A300';
      } else if (this.score <= 6) {
        color = '#1B87C0';
      } else {
        color = '#BC2333';
      }

      return {
        '--degree': `${degree}deg`,
        '--bgColor': color,
      };
    },
  },
};
</script>

<style scoped>
.bar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.bar.background {
  background: lightgrey;
}

.bar.loading {
  --hide-amount: 100%;
  animation: 1s rotate linear forwards;
  transform: rotateY(180deg);
  background: conic-gradient(from 180deg, lightgrey var(--hide-amount), transparent 0);
}

.hole {
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 80%;
  width: 80%;
  border-radius: 50%;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
  position: relative;
}

.hole::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  bottom: -10px;
  background: #70A300;
  position: absolute;
}

.for-rounded-border {
  width: 10px;
  height: 50%;
  background: transparent;
  position: absolute;
  transform-origin: top;
  transform: translateY(50%) rotate(0deg);
  animation: 1s roundedEdgeAnimation cubic-bezier(0.79, 0.33, 0.65, 0.82) forwards;
}

.for-rounded-border::after {
  content: '';
  position: absolute;
  bottom: -10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: 1s roundedDotAnimation cubic-bezier(0.79, 0.33, 0.65, 0.82) forwards;
}

.hole span {
  font-weight: bold;
  font-size: 3.75rem;
}

@property --hide-amount {
  syntax: '<percentage>';
  initial-value: 100%;
  inherits: false;
}

@keyframes rotate {
  to {
    --hide-amount: 0%;
  }
}

@keyframes roundedEdgeAnimation {
  to {
    transform: translateY(50%) rotate(var(--degree));
  }
}

@keyframes roundedDotAnimation {
  from {
    background: transparent;
  }
  to {
    background: var(--bgColor);
  }
}
</style>
