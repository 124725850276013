<template>
  <div>
    <v-list
      color="grey darken-1"
      dark
    >
        <v-container>
          <v-row>
           <v-col>
             <v-list-item @click.native="goToModule('https://analyze.randallreilly.com')"
             >
               <img
                 src="../../assets/Analyze-WaffleMenu-Icon.svg"
                 style="height:65px;width:65px;"
                 alt="analyze login button"
               />
             </v-list-item>
           </v-col>
           <v-col>
             <v-list-item @click.native="goToModule('https://connect.randallreilly.com')">
               <img
                 src="../../assets/Connect-WaffleMenu-Icon.svg"
                 style="height:65px;width:65px;"
                 alt="connect login button"
               />
             </v-list-item>
           </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-list-item
                @click.native="goToModule('https://reach.randallreilly.com')"
              >
                <img
                  src="../../assets/Reach-WaffleMenu-Icon.svg"
                  style="height:65px;width:65px"
                  alt="reach login button"
                />
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item
                @click.native="goToModule('https://strategize.randallreilly.com')"
              >
                <img
                  src="../../assets/Strategize-WaffleMenu-Icon.svg"
                  style="height:65px;width:65px;"
                  alt="strategize login button"
                />
              </v-list-item>
            </v-col>
          </v-row>
        </v-container>
    </v-list>
  </div>
</template>

<script>

export default {
  name: 'WaffleMenu',
  data() {
    return {
    };
  },
  created() {
  },
  updated() {
  },
  methods: {
    goToModule(name) {
      window.location.href = name;
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-list-item img:hover {
    cursor: pointer;
  }
</style>
