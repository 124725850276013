<template>
  <div>
    <canvas :id="`bar-chart-canvas-${id}`"></canvas>
  </div>
</template>
<script>
import Chart from 'chart.js/auto';

export default {
  name: 'BarChart',
  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
    barChartData: {
      type: Array,
      required: true,
      default: () => [],
    },
    labelKey: {
      type: String,
      required: true,
      default: '',
    },
    dataKey: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    const barChartElement = document.getElementById(`bar-chart-canvas-${this.id}`);
    const chartData = this.barChartData.map((d) => d[this.dataKey]);
    const labels = this.barChartData.map((d) => d[this.labelKey]);

    this.chart = new Chart(barChartElement, {
      type: 'bar',
      data: {
        labels,
        datasets: [{
          data: chartData,
        }],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        elements: {
          bar: {
            backgroundColor: '#70A300',
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title(tooltipItem) {
                return `$${Number.parseInt(tooltipItem[0].label, 10).toLocaleString()}`;
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              drawTicks: false,
              display: false,
            },
            ticks: {
              precision: 0,
              // eslint-disable-next-line object-shorthand, no-unused-vars
              callback: function (value, index, ticks) {
                return `$${this.chart.data.labels[index].toLocaleString()}`;
              },
            },
          },
          y: {
            grid: {
              display: false,
              drawTicks: false,
            },
            ticks: {
              autoSkip: false,
            },
          },
        },
      },
    });
  },
};
</script>
<style lang="scss" scoped>
</style>
