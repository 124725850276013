import Vue from 'vue';
import Vuex from 'vuex';

// modules
import auth from './modules/auth';
import global from './modules/global';
import locations from './modules/locations';
import trends from './modules/trends';
import admin from './modules/admin';
import pay from './modules/pay';
import notifications from './modules/notifications';
import jobPostings from './modules/job_postings';
import maps from './modules/maps';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    admin,
    auth,
    trends,
    global,
    locations,
    notifications,
    pay,
    jobPostings,
    maps,
  },
});
