<template>
  <div>
    <div class="area-container">
      <div class="col-12">
        <div class="map-container">
          <div id="map" class="m-0"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import * as turf from '@turf/turf';

export default {
  name: 'LocationMap',
  props: {
    selectedLocation: { type: Object, required: true },
  },
  data() {
    return {
      isVisible: true,
      accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
      mapStyle: process.env.VUE_APP_MAPBOX_STYLE,
      mapbox: null,
      defaultZoom: 1,
      defaultCenter: [-90.4601, 32.3492],
      visibleFeatures: [],
      mapType: {
        state: {
          layerName: process.env.VUE_APP_MAPBOX_SOURCE_NAME_STATES,
          layerURL: process.env.VUE_APP_MAPBOX_SOURCE_URL_STATES,
        },
        msa: {
          layerName: process.env.VUE_APP_MAPBOX_SOURCE_NAME_MSA,
          layerURL: process.env.VUE_APP_MAPBOX_SOURCE_URL_MSA,
        },
        county: {
          layerName: process.env.VUE_APP_MAPBOX_SOURCE_NAME_COUNTIES,
          layerURL: process.env.VUE_APP_MAPBOX_SOURCE_URL_COUNTIES,
        },
      },
    };
  },
  mounted() {
    mapboxgl.accessToken = this.accessToken;
    const map = new mapboxgl.Map({
      container: 'map',
      style: this.mapStyle,
      center: this.defaultCenter,
      zoom: this.defaultZoom, // starting zoom
    });
    this.mapbox = map;
    map.on('load', () => {
      const sourceLayerURL = this.mapType[`${this.selectedLocation.location_type}`].layerURL;
      const sourceLayerName = this.mapType[`${this.selectedLocation.location_type}`].layerName;
      map.addSource('locations', {
        type: 'vector',
        url: sourceLayerURL,
      });

      map.addLayer(
        {
          id: 'locations',
          type: 'fill',
          source: 'locations',
          'source-layer': sourceLayerName,
          paint: {
            'fill-outline-color': 'rgba(0, 0, 0, 0.15)',
            'fill-color': 'rgba(0, 0, 0, 0.03)',
          },
        },
      );

      map.addLayer(
        {
          id: 'locations-highlighted',
          type: 'fill',
          source: 'locations',
          'source-layer': sourceLayerName,
          paint: {
            'fill-outline-color': '#484896',
            'fill-color': 'rgba(112, 163, 0, .5)', // #82bc00
            'fill-opacity': 0.75,
          },
          filter: ['in', 'GEOID', ''],
        },
      );

      // For initial page load
      map.once('idle', () => {
        this.updateVisibleFeatures();
      });
    });
  },
  methods: {
    updateVisibleFeatures() {
      this.visibleFeatures = this.mapbox
        .queryRenderedFeatures(null, { layers: ['locations'] });

      const location = this.formattedLocation();

      if (location !== undefined) {
        // add zero in front to match GEOID present in locations-highlighted layer
        const locationGeoid = this.selectedLocation.location_type === 'county'
          ? location.properties.GEOID.toString().padStart(5, '0')
          : location.properties.GEOID.toString().padStart(2, '0');

        this.mapbox.setFilter(
          'locations-highlighted',
          ['in', 'GEOID', locationGeoid],
        );

        // Zoom in on the highlighted location
        const bounds = turf.envelope(location.geometry).bbox;

        if (this.selectedLocation.location_type === 'state') {
          if (this.selectedLocation.location_name === 'Alaska (AK)') {
            this.setZoomLevel(bounds, 2.3, 300, 10, 10, 10);
          } else {
            this.setZoomLevel(bounds, 4.5, 20, 20, 20, 20);
          }
        } else {
          const county = this.selectedLocation.location_type === 'county';

          this.setZoomLevel(bounds, county ? 8 : 7, 20, 20, 20, 20);
        }
      }

      // Add scale
      const scale = new mapboxgl.ScaleControl({
        maxWidth: 200,
        unit: 'imperial',
      });

      this.mapbox.addControl(scale);
    },
    formattedLocation() {
      const GEOID = this.selectedLocation.location_type === 'msa'
        ? `${this.selectedLocation.location_code_geoid.substr(1)}0`
        : this.selectedLocation.location_code_geoid;

      return this.visibleFeatures.filter(
        (feature) => (feature.properties.GEOID === GEOID),
      )[0];
    },
    setZoomLevel(bounds, maxZoom, paddingTop, paddingBottom, paddingLeft, paddingRight) {
      this.mapbox.fitBounds(bounds, {
        maxZoom,
        padding: {
          top: paddingTop, bottom: paddingBottom, left: paddingLeft, right: paddingRight,
        },
      });
    },
  },
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 100%;
}
.map-container, .mapboxgl-map {
  height: 100%;
  min-height: 350px;
}
.map-container {
  flex: 1;
  position: relative;
}
.area-container {
  height: calc(100% - 20vh);
  display: flex;
}
</style>
