<template>
  <div>
    <canvas :id="`pie-chart-canvas-${id}`"></canvas>
  </div>
</template>
<script>
import Chart from 'chart.js/auto';

export default {
  name: 'DoughnutChart',
  props: {
    pieChartData: {
      type: Number,
      required: true,
      default: 0,
    },
    percentageData: {
      type: Number,
      required: false,
      default: 0,
    },
    id: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      chart: null,
      defaultData: null,
    };
  },
  mounted() {
    // eslint-disable-next-line max-len
    this.defaultData = Math.round(((this.pieChartData / this.percentageData) * 100)) - this.pieChartData;
    const pieChartElement = document.getElementById(`pie-chart-canvas-${this.id}`);
    const formattedPercentData = `${this.percentageData.toFixed(0)}%`;
    this.chart = new Chart(pieChartElement, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            label: 'asdf',
            data: [this.pieChartData, this.defaultData],
            backgroundColor: ['#70A300', '#BDBDBD'],
            borderWidth: 0,
          },
        ],
      },
      options: {
        percentData: formattedPercentData,
        maintainAspectRatio: false,
        responsive: true,
        aspectRatio: 2,
        plugins: {
          tooltip: {
            callbacks: {
              label(toolTipItem) {
                return `Postings: ${toolTipItem.formattedValue}`;
              },
            },
          },
        },
      },
    });
    Chart.register({
      id: 'custom_canvas_background_color',
      beforeDraw(chart) {
        if (chart.config.type === 'doughnut') {
          const { width, height, ctx } = chart;
          const { percentData } = chart.config.options;
          ctx.restore();
          const fontSize = (height / 5).toFixed(2);
          ctx.font = `700 ${fontSize}px Roboto, sans-serif`;
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#70A300';
          const text = percentData;
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = height / 1.8;

          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      },
    });
  },
};
</script>
<style lang="scss" scoped>
</style>
