<template>
  <div data-app class="filter-select">
    <v-select
      v-model="selected"
      :name="`${name}-select`"
      :title="title"
      :id="`${name}-select`"
      :class="`${name}`"
      :items="values"
      item-text="item_text"
      item-value="item_value"
      @input="emitValue"
      :label="label"
      :menu-props="{offsetY: true, 'content-class': contentClass}"
    >
    </v-select>
  </div>
</template>
<script>

export default {
  name: 'FilterSelect',
  props: {
    name: { type: String, required: true },
    title: { type: String, required: true },
    emitVal: { type: String, required: false },
    values: { type: Array, required: true },
    chosenOne: { type: String, required: false },
    label: { type: String, required: true },
    contentClass: { type: String, required: false },
  },
  data() {
    return {
      selected: this.$props.chosenOne,
      items: {},
    };
  },
  mounted() {
    this.items = this.$props.values.map((val) => ({ item_text: val, item_value: val }));
  },
  methods: {
    emitValue(value) {
      this.$emit(`${this.$props.emitVal}`, value);
    },
  },
};
</script>
