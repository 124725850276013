<template>
  <v-tooltip
    top
    v-model="tooltipVisible"
    max-width="350px"
    :open-on-focus=false
    :open-on-hover=false
    color="grey darken-4"
    :content-class="'info-tooltip-stat-point-graph pb-3'"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        ref="icon"
        color="primary"
        dark
        v-bind="attrs"
        icon
        v-click-outside="onClickOutside"
        aria-label="Show tooltip"
      >
        <v-icon :color="color">mdi-information-outline</v-icon>
      </v-btn>
    </template>
      <template v-if="tooltipText">
        <p class="title-tooltip text-justify font-weight-bold">
          {{ this.tooltipTitle }}
        </p>
        <hr class="divider" />
        <div v-html="line" v-for="line in tooltipText" :key="line"/>
      </template>

      <slot v-if="!tooltipText"></slot>
  </v-tooltip>

</template>

<script>
export default {
  name: 'TooltipIcon',
  props: {
    tip: { type: [String, Array] },
    tooltipTitle: { type: String },
    icon: { type: String, default: 'mdi-information-outline' },
    color: { type: String, default: '#666666' },
  },
  data() {
    return {
      tooltipText: this.tip && !Array.isArray(this.tip) ? [this.tip] : this.tip,
      tooltipVisible: false,
    };
  },
  computed: {
    iconStyle() {
      return `color: ${this.color}`;
    },
  },
  methods: {
    onScroll() {
      this.tooltipVisible = false;
    },
    toggleVisibility() {
      this.tooltipVisible = !this.tooltipVisible;
    },
    onClickOutside() {
      this.tooltipVisible = false;
    },
    mouseEnter(e) {
      e.preventDefault();
      e.stopPropagation();
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
    this.$refs.icon.$el.removeEventListener('mouseenter', this.mouseEnter, true);
  },
};
</script>

<style scoped lang="scss">
.title-tooltip {
  font-size: 14px;
  color: white;
}

hr {
  background-color: white;
}
</style>
