<template>
  <v-app-bar
    color="grey darken-3 text--white"
    fixed
    height="70px"
  >
  <v-row id="nav-mobile-grid">
    <v-col>
      <div class="text-center">
        <v-menu
          tile
          absolute
          nudge-bottom="45"
          :nudge-width="200"
          ref="mdMenuRef"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <span class="mobile-nav-header-text"> MARKET </span>
                <v-list-item-subtitle>
                  <a
                    id="mdrivers"
                    class="mobile-nav-router-link mobile-nav-page-link"
                    href="/market/trends"
                    style="text-decoration: none"
                    @click="
                    makeActiveNavItem('Trends');
                    closeMobileNav();
                    ">
                    <v-icon class="md-light mobile-nav-icon" id="drivers-icon">
                      mdi-account-group
                    </v-icon>
                    <span class="mobile-nav-sub-text mobile-nav-text" id="drivers-text">
                      Trends
                    </span>
                  </a>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <a
                    id="mobile-nav-locations"
                    class="mobile-nav-router-link mobile-nav-page-link"
                    href="/market/locations"
                    style="text-decoration: none"
                    @click="
                    makeActiveNavItem('Locations');
                    closeMobileNav();
                  ">
                    <v-icon class="md-light mobile-nav-icon">
                      mdi-map-marker
                    </v-icon>
                    <span class="mobile-nav-sub-text mobile-nav-text">
                      Locations
                    </span>
                  </a>
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <span class="mobile-nav-header-text"> COMPETITION </span>
                <v-list-item-subtitle>
                  <a
                    id="mobile-nav-competition"
                    class="mobile-nav-router-link mobile-nav-page-link"
                    href="/competition/"
                    style="text-decoration: none"
                    @click="
                    makeActiveNavItem('Job Postings');
                    closeMobileNav();
                  ">
                    <v-icon class="md-light mobile-nav-icon">
                      mdi-currency-usd
                    </v-icon>
                    <span class="mobile-nav-sub-text mobile-nav-text">
                      Advertised Pay
                    </span>
                  </a>
                </v-list-item-subtitle>
              </v-list-item-title>

            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <span class="mobile-nav-header-text"> ACCOUNT </span>
                <v-list-item-subtitle>
                  <a
                    v-if="isAdmin"
                    class="mobile-nav-router-link mobile-nav-page-link"
                    style="text-decoration: none"
                    @click="
                    goToAdminPage();
                    closeMobileNav();
                  ">
                    <v-icon class="md-light mobile-nav-icon">
                      mdi-shield-account-variant
                    </v-icon>
                    <span class="mobile-nav-sub-text mobile-nav-text">
                      Admin
                    </span>
                  </a>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <a
                    class="mobile-nav-router-link mobile-nav-page-link"
                    style="text-decoration: none"
                    @click="
                    logout();
                    closeMobileNav();
                  ">
                    <v-icon class="md-light mobile-nav-icon">
                      mdi-logout
                    </v-icon>
                    <span class="mobile-nav-sub-text mobile-nav-text">
                      Logout
                    </span>
                  </a>
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item>
            <span class="mobile-nav-bottom-grid">
          <a
            id="help"
            class="help-button"
            href="/help"
            @click="
              makeActiveNavItem('Help');
              closeMobileNav();
            "
            aria-label="Help"
          >
            <v-icon class="help-icon grey-text text-lighten-3">mdi-help-circle-outline</v-icon>
          </a>
          <div class="notifications-button-mobile">
            <v-btn icon>
              <Notifications icon-color="#545454" />
            </v-btn>
          </div>
        </span>
          </v-list>
        </v-menu>
      </div>
    </v-col>
    <v-spacer></v-spacer>
    <v-col>
      <div class="strategize-logo-mobile" />
    </v-col>
    <v-spacer></v-spacer>
    <v-col>
      <div class="text-center">
        <v-menu
          tile
          absolute
          nudge-bottom="45"
          :nudge-width="200"
          ref="mdMenuRef"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-apps</v-icon>
            </v-btn>
          </template>
          <WaffleMenu />
        </v-menu>
      </div>
    </v-col>
  </v-row>
  </v-app-bar>
</template>

<script>
import WaffleMenu from './WaffleMenu.vue';
import Notifications from './Notifications-FullSize.vue';

export default {
  name: 'NavBarMobile',
  props: {
    isAdmin: { type: Boolean, required: false },
  },
  components: {
    WaffleMenu,
    Notifications,
  },
  data() {
    return {
      mobileNavActive: false,
    };
  },
  mounted() {
    this.addMobileNavLinks();
  },
  methods: {
    openMobileNav() {
      this.mobileNavActive = true;
    },
    closeMobileNav() {
      this.mobileNavActive = false;
    },
    logout() {
      this.$store.dispatch('auth/ssoLogout');
    },
    goToAdminPage() {
      let route = { linkId: 'Admin' };
      route = JSON.stringify(route);
      sessionStorage.setItem('lastPageViewed', route);
      this.$router.push({ name: 'Admin' });
    },
    makeActiveNavItem(data) {
      let route = { linkId: data };
      route = JSON.stringify(route);
      sessionStorage.setItem('lastPageViewed', route);
    },
    async addMobileNavLinks() {
      const data = {
        link: JSON.parse(sessionStorage.getItem('lastPageViewed')),
      };
      setTimeout(() => {
        this.$store.dispatch(
          'global/addPageLinkHighlightAndNavLinkBorder',
          data,
        );
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list {
  padding: 0;
}
.v-list-item {
  border-bottom: none !important;
  &:last-of-type {
    margin-bottom: 15px;
  }
}
.nav-mobile-grid {
  display: grid;
  grid-template-rows: 5px 60px 5px;
  grid-template-columns: 30px 10% 76% 10% 2%;
  background-color: #444444;
  box-shadow: 0 3px 6px -3px #343333;
}
.strategize-logo-spot-mobile {
  grid-row: 2;
  grid-column: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
}
.strategize-logo-mobile {
  content: "";
  background-image: url("../../assets/Strategize-Horizontal-white-TM.svg");
  background-repeat: no-repeat;
  height: 40px;
  width: 145px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.center {
  display: flex;
  justify-self: center;
  align-self: center;
}
.is-active {
  border-bottom: 0.1rem solid #70A300;
  opacity: 1;
}
.mobile-nav-header-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 3%;
  margin-top: 29px;
}
.mobile-nav-header-text-competition {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 3%;
  margin-top: 25px;
}
.mobile-nav-header-text-account {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 3%;
  margin-top: 25px;
}
.navigation-link {
  font-family: Roboto;
  font-size: 0.8vmax;
  color: #e3e3e3;
  height: 22px;
  cursor: pointer;
}
.strategize-navlink {
  grid-row: 2;
  grid-column: 4;
}
.competition-navlink {
  grid-row: 2;
  grid-column: 6;
}
.tools-navlink {
  grid-row: 2;
  grid-column: 8;
}
.mobile-nav-page-link {
  display: grid;
  grid-template-columns: 10% 20% 60% 10%;
  grid-template-rows: 35px;
}
.mobile-nav-icon {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(172, 172, 172) !important;
}
.mobile-nav-text {
  grid-row: 1;
  grid-column: 3;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.mobile-nav-sub-text {
  font-family: Roboto;
  font-weight: 400;
  color: #444444;
  display: flex;
  align-items: center;
}
.menu-icons {
  cursor: pointer;
}
.menu-icons:hover {
  transform: scale(1.1);
}
.help-icon {
  grid-row: 2;
  grid-column: 11;
}
.notifications-icon {
  grid-row: 2;
  grid-column: 13;
}
.mobile-waffle-menu-icon {
  grid-row: 2;
  grid-column: 4;
}
.mobile-hamburger {
  grid-row: 2;
  grid-column: 2;
  max-height: 70vh !important;
}
.waffle-menu-content-mobile {
  background-color: #545454 !important;
}
.menu-icons {
  cursor: pointer;
}
.md-light:hover {
  color: yellowgreen;
}
.menu-icons:hover {
  transform: scale(1.1);
}
.md-ripple:hover {
  background-color: transparent;
}
.mobile-stratas-logo {
  grid-row: 2;
  grid-column: 8;
  content: "";
  background-image: url("../../assets/Stratas-Logo-Color.svg");
  background-repeat: no-repeat;
  height: 45px;
  width: 150px;
}
.mobile-stratas-logo-background {
  grid-row: 1 / 4;
  grid-column: 8;
  background-color: #5a5959;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.md-menu-content {
  top: 51px;
  left: 677px;
  max-height: 70vh !important;
  width: 200px !important;
}
.md-menu-item {
  padding-right: 0;
}
.mobile-nav-bottom-grid {
  display: grid;
  grid-template-rows: 50px;
  grid-template-columns: 50% 50%;
  background-color: rgba(130, 130, 130, 0.564);
}
.mobile-nav-bottom-grid-with-admin {
  display: grid;
  grid-template-rows: 50px;
  grid-template-columns: 33% 34% 33%;
  background-color: rgba(130, 130, 130, 0.564);
  margin-top: 10%;
}
.admin-button {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px rgb(74, 74, 74) solid;
  text-decoration: none;
}
.help-button {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px rgb(74, 74, 74) solid;
  text-decoration: none;
}
.help-button-with-admin {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px rgb(74, 74, 74) solid;
  text-decoration: none;
}
.notifications-button-mobile {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px rgb(74, 74, 74) solid;
  text-decoration: none;
}
.notifications-button-with-admin {
  grid-row: 1;
  grid-column: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px rgb(74, 74, 74) solid;
  text-decoration: none;
}
.md-theme-default a:not(.md-button) {
  text-decoration: none;
}
.help-icon {
  transition: all 0.2s ease-in-out;
}
.help-icon:hover {
  color: yellowgreen !important;
  transform: scale(1.2);
  cursor: pointer;
}
.notifications-icon {
  transition: all 0.2s ease-in-out;
}
.notifications-icon:hover {
  color: yellowgreen !important;
  transform: scale(1.2);
  cursor: pointer;
}
.mobile-nav-active {
  background-color: rgba(227, 227, 227, 0.091);
  border-right: 3px yellowgreen solid;
}
.mobile-nav-router-link:hover {
  background-color: rgba(152, 210, 38, 0.502);
  cursor: pointer;
}
</style>
