<template>
  <div>
    <div v-if="noData" class="no-data">No Data Available</div>
    <v-container>
      <v-row class="mb-0">
        <v-col>
          <div class="difference-banner-title difference-font">
            <h1 class="chart-title">{{ title }}</h1>
            <span><TooltipIcon :tip="tooltipTip" :tooltipTitle="tooltipTitle" /></span>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pb-0 pt-0">
          <div class="difference-banner-value" :style="{ color: color }">
            {{ convertedValue ? convertedValue : value }}
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0">
          <div class="yoy">
            <div class="yoy-grid">
              <div class="difference-banner-yoy">YoY:</div>
              <div v-if="increase" class="difference-banner-increase">
                <svg width="40" height="70" viewBox="0 0 40 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.5 30L32.1913 41.25H8.80866L20.5 30Z" fill="#666666"/>
                </svg>
              </div>
              <div v-else class="difference-banner-increase">
                <svg width="40" height="70" viewBox="0 0 40 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.5 41L8.80866 29.75L32.1913 29.75L20.5 41Z" fill="#70A300"/>
                </svg>
              </div>
              <div
                v-if="isDollarValue"
                class="difference-banner-difference"
                :style="{ 'font-size': differenceFontSize }"
              >
                {{ convertedDifferenceValue ? convertedDifferenceValue : difference }} ({{
                  percentChange
                }}%)
              </div>
              <div
                v-else
                class="difference-banner-difference"
                :style="{ 'font-size': differenceFontSize }"
              >
                {{ difference }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Currency from '../services/Currency';
import TooltipIcon from './Market/TooltipIcon.vue';

const currency = new Currency();
export default {
  props: {
    value: { type: String },
    color: { type: String },
    title: { type: String },
    increase: { type: Boolean },
    difference: { type: String },
    differenceFontSize: { type: String, default: '24px' },
    isDollarValue: { type: Boolean, default: false },
    percentChange: { type: Number, required: false },
    noData: { type: Boolean, required: true },
    tooltipTitle: { type: String, required: true },
    tooltipTip: { type: String, required: true },
  },
  computed: {
    convertedValue() {
      if (this.isDollarValue) {
        return `$${currency.moneyWithCommas(this.$props.value)}`;
      }
      return '';
    },
    convertedDifferenceValue() {
      return `$${currency.moneyWithCommas(Math.abs(this.difference))}`;
    },
  },
  components: { TooltipIcon },
};
</script>
<style scoped lang="scss">
.difference-banner-grid {
  height: 100%;
  justify-content: center;
  align-items: center;
}
.yoy {
  display: flex;
  justify-content: center;
  align-items: center;
}
.yoy-grid {
  display: flex;
  justify-content: center;
  align-items: center;
}
.difference-font {
  font-family: Roboto;
}
.difference-banner-value {
  font-size: 3vw;
  font-family: Roboto;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.difference-banner-title {
  line-height: 32px;
  color: #5e5e5e;
  font-size: 26px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.difference-banner-yoy {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  font-size: 24px;
}
.difference-banner-increase {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70px !important;
}
.difference-banner-difference {
  color: #666666;
  font-size: 24px;
  font-family: Roboto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.no-data {
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.chart-title {
  font-size: 26px;
  line-height: 32px;
  display: inline-block;
  margin: 0;
  text-shadow: none;
  color: #5e5e5e;
  font-weight: 700;
}
@media (max-width: 1500px) {
  .difference-banner-title {
    font-size: 26px;
  }
}
@media (max-width: 1300px) {
  .difference-banner-value {
    font-size: 30px;
  }
}
@media (max-width: 950px) {
  .difference-banner-title {
    font-size: 25px;
  }
  .difference-banner-difference {
    font-size: 20px;
  }
}
@media (max-width: 850px) {
  .difference-banner-title {
    font-size: 22px;
  }
}
@media (max-width: 700px) {
  .difference-banner-title {
    font-size: 18px;
  }
  .difference-banner-yoy {
    font-size: 18px;
  }
  .difference-banner-difference {
    font-size: 18px !important;
  }
}
</style>
