import { getCurrentPage } from '../utils';
import ErrorService from '../../../helpers/ErrorHandling';

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
const HEADERS = {
  'Content-Type': 'application/json',
};

export default {
  async getAdminNotifications({ commit, state }, payload) {
    const page = getCurrentPage(payload, state.adminNotificationsCurrentPage);
    const response = await fetch(
      `${BACKEND_URL}/strategize_frontend/admin/notifications?page=${page}`,
      {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
      },
    );
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setAdminNotifications', data);
    }
  },

  async getUserNotifications({ commit }) {
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/notifications`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
    });
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setUserNotifications', data);
    }
  },

  async saveNotification({ commit }, payload) {
    const notificationData = JSON.stringify(payload);
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/admin/notifications`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
      body: notificationData,
    });
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('addNotificationToAdminTable', data);
    }
  },

  async readAllNotifications({ commit }) {
    const response = await fetch(
      `${BACKEND_URL}/strategize_frontend/notifications/read_all_notifications`,
      {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
      },
    );
    const data = await response.json();

    if (ErrorService.handleResponse(response, data)) {
      await commit('setUserNotifications', data);
    }
  },

  async markAsRead({ commit }, payload) {
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/notifications/${payload}`, {
      method: 'PUT',
      credentials: 'include',
      mode: 'cors',
    });
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setUserNotifications', data);
    }
  },
  toggleReadMore({ commit }, payload) {
    commit('setUserNotificationsReadMoreActivate', payload);
  },
};
