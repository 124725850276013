<template>
  <div class="location-selected-select filter-style">
    <v-text-field
      ref="location-listing-text-field"
      @paste="handlePastedText"
      @click="handleTextFieldClick"
      @click:clear="handleClearInput"
      v-model="searchedLocation"
      :id="searchFieldId"
      :placeholder="placeholder"
      :label="label"
      :outlined="outlined"
      required
      :clearable="true"
      :hint="hint"
      persistent-hint
      cache-items
    >
      <template v-if="outsideIcon.name" v-slot:append-outer>
        <v-icon :left="true" x-large>
          {{ outsideIcon.name }}
        </v-icon>
      </template>
    </v-text-field>
    <div class="menu-wrapper" v-click-outside="toggleDropDownOpen">
      <v-card
        v-on:scroll.native="handleLocationScroll"
        class="mx-auto location-options-menu"
        tile
        v-if="
          searchedLocation &&
          searchedLocation.length >= 3 &&
          dropdownOpen &&
          initialLocation.location_name !== searchedLocation
        "
      >
        <v-list>
          <v-list-item-group color="primary" v-if="!loading">
            <v-list-item v-if="filteredLocations.length === 0" @click="toggleDropDownOpen">
              <v-list-item-title> No Result Found</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="(location, i) in filteredLocations"
              :id="`location_item${i}`"
              tabindex="0"
              :key="i"
              v-bind:class="{
                'dropdown-submenu':
                  location.location_type === 'zip' || location.location_type === 'city',
              }"
              @click.prevent="determineOpenOrEmit(location, i)"
            >
              <v-list-item-content @keydown.enter="determineOpenOrEmit(location, i)">
                <div
                  class="list-item-content"
                  v-if="location.location_type === 'zip' || location.location_type === 'city'"
                >
                  <div>
                    <v-list-item-title> {{ determineLocationName(location) }}</v-list-item-title>
                    <v-list-item-subtitle class="location_type">{{
                      location.location_type
                    }}</v-list-item-subtitle>
                  </div>
                  <v-icon aria-label="expand location to view more" color="grey lighten-1"
                    >arrow_right</v-icon
                  >
                </div>

                <div v-else>
                  <v-list-item-title> {{ location.location_name }}</v-list-item-title>
                  <v-list-item-subtitle class="location_type">{{
                    location.location_type
                  }}</v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <template v-else>
            <loading-spinner
              class="loading-spinner"
              :loading="loading"
              :color="'#70A300'"
              :size="'50px'"
            />
          </template>
        </v-list>
      </v-card>
      <SubMenuItems
        v-if="isSubMenuVisible"
        :subMenuData="this.subMenuData"
        :selectedMenuItem="this.selectedMenuItem"
        @searchLoc="searchLocation($event)"
      ></SubMenuItems>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import LoadingSpinner from './LoadingSpinner.vue';
import strip from '../../helpers/html-strip';
import SubMenuItems from './SubMenuItems.vue';

export default {
  name: 'LocationListings',
  components: {
    LoadingSpinner,
    SubMenuItems,
  },
  props: {
    initialLocation: { type: [String, Object], required: true },
    // eslint-disable-next-line arrow-body-style
    outsideIcon: { type: Object, required: false, default: () => { return {}; } },
    hint: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    outlined: { type: Boolean, required: false, default: false },
    clearAfterSelection: { type: Boolean, required: false, default: false },
    focusOnLoad: { type: Boolean, required: false, default: false },
    searchFieldId: { type: String, required: true },
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      selectedOption: null,
      searchedLocation: '',
      filteredLocations: [],
      selectedLocation: null,
      dropdownOpen: false,
      loading: false,
      subMenuData: {},
      isSubMenuVisible: false,
      isPasted: false,
      selectedMenuItem: '',
    };
  },
  mounted() {
    if (this.focusOnLoad) {
      this.$refs['location-listing-text-field'].focus();
    }
  },
  watch: {
    async searchedLocation() {
      if (this.isPasted) {
        this.searchedLocation = strip(this.searchedLocation);
        if (this.searchedLocation.length >= 3) {
          this.loading = true;
          this.dropdownOpen = true;
          await this.$store.dispatch('locations/getLocations', this.searchedLocation.slice(0, 3));
          this.filteredLocations = this.$store.state.locations.locations.filter(
            (location) => location.location_name.toLowerCase().includes(
              this.searchedLocation.toLowerCase(),
            ),
          );
          this.loading = false;
          this.isPasted = false;
        }
      } else {
        this.filterLocations();
      }
    },
    initialLocation: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.location_name) this.searchedLocation = val.location_name;
      },
    },
  },
  methods: {
    handleClearInput() {
      this.isSubMenuVisible = false;
    },
    handlePastedText() {
      this.isPasted = true;
    },
    handleTextFieldClick() {
      if (this.initialLocation.location_name === this.searchedLocation) {
        this.$refs['location-listing-text-field'].reset();
        this.searchedLocation = '';
      }
    },
    determineOpenOrEmit(location, i) {
      this.selectedMenuItem = `#location_item${i}`;

      if (location.location_type === 'zip' || location.location_type === 'city') {
        this.handleDataForSubMenu(location);
      } else {
        this.searchLocation(
          {
            location_code: location.location_code,
            location_type: location.location_type,
            location_name: location.location_name,
            location_code_geoid: location.location_code,
          },
          location.location_name,
        );
      }
    },
    searchLocation(value, locationName) {
      this.loading = false;
      this.dropdownOpen = false;
      this.isSubMenuVisible = false;
      this.searchedLocation = locationName;
      this.filteredLocations = [];
      this.$emit(`selected`, value);
      if (this.clearAfterSelection) {
        this.$refs['location-listing-text-field'].reset();
        this.$refs['location-listing-text-field'].focus();
      }
    },
    determineState(location) {
      if (location.location_type === 'city') {
        return `, ${location.state}`;
      }
    },
    async filterLocations() {
      if (this.searchedLocation && this.searchedLocation.length === 3) {
        this.loading = true;
        this.dropdownOpen = true;
        await this.$store.dispatch('locations/getLocations', this.searchedLocation);
        this.filteredLocations = this.$store.state.locations.locations;
        this.loading = false;
      } else if (this.searchedLocation && this.searchedLocation.length > 3) {
        this.dropdownOpen = true;
        this.filteredLocations = this.$store.state.locations.locations.filter(
          (location) => location.location_name.toLowerCase().includes(
            this.searchedLocation.toLowerCase(),
          ),
        );
      } else {
        this.$store.commit('locations/setLocations', []);
        this.dropdownOpen = false;
        this.filteredLocations = [];
      }
    },
    toggleDropDownOpen() {
      if (this.dropdownOpen) {
        this.dropdownOpen = false;
      }
      this.toggleSubMenu();
    },
    toggleSubMenu() {
      if (this.isSubMenuVisible) {
        this.isSubMenuVisible = false;
      }
    },
    handleDataForSubMenu(location) {
      this.subMenuData = location;
      this.isSubMenuVisible = true;
    },
    determineLocationName(location) {
      if (location.location_type === 'city') {
        return `${location.location_name}, ${location.state}`;
      }
      return location.location_name;
    },
    handleLocationScroll() {
      this.toggleSubMenu();
    },
  },
};
</script>

<style scoped lang="scss">
.menu-wrapper {
  position: relative;
  .v-list-item.theme--light {
    text-align: left !important;
  }
}
.location-options-menu {
  width: 60%;
  position: absolute;
  top: 100%;
  max-height: 50vh;
  overflow-y: auto;
  z-index: 2;
}

.location_type {
  font-style: italic;
}

.list-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.submenu-title {
  color: #70a300 !important;
  font-weight: bold;
  font-size: 1.1rem;
}

@media (max-width: 866px) {
  .location-options-submenu {
    left: unset;
    right: -3%;
    width: 60%;
  }
}
</style>
