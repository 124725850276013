/* ============
 * Pay Module
 * ============
 */

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// initial state
const state = () => ({
  allCompanies: [],
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
