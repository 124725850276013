/* eslint-disable no-alert, no-console, no-param-reassign */

import ErrorService from '../../../helpers/ErrorHandling';

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;

const HEADERS = {
  'Content-Type': 'application/json',
};

export default {
  async getLocations({ commit }, searchQuery) {
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/locations/get_locations?search_query=${searchQuery}`, {
      credentials: 'include',
      mode: 'cors',
    });
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setLocations', JSON.parse(data.result));
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },

  async getLocationBreakdown({ commit }, payload) {
    await commit('setLocationBreakdownLoading', true);

    const body = JSON.stringify(payload);
    const url = `${BACKEND_URL}/strategize_frontend/locations/get_location_breakdown`;
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
      body,
    });

    if (response.status === 204) {
      await commit('setLocationBreakdown', null);
      await commit('setLocationBreakdownLoading', false);
      return;
    }

    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setLocationBreakdown', data.result);
      await commit('setLocationBreakdownLoading', false);
    }
  },

  async getLocationModalData({ commit }, payload) {
    await commit('setLocationModalLoading', true);

    // Rename keys as required on backend
    payload.location_modal_first_column = payload.firstColumn;
    payload.location_modal_second_column = payload.secondColumn;
    payload.location_code = payload.locationCode;
    payload.location_type = payload.locationType;

    // Delete unnecessary properties
    delete payload.firstColumn;
    delete payload.secondColumn;
    delete payload.locationCode;
    delete payload.locationType;

    const body = JSON.stringify(payload);
    const response = await fetch(
      `${BACKEND_URL}/strategize_frontend/locations/get_location_modal_data`,
      {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: HEADERS,
        body,
      },
    );
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setLocationModalData', data.result);
      await commit('setLocationModalLoading', false);
    }
  },

  async fetchStateRegionSubregionOptions({ commit }) {
    const url = `${BACKEND_URL}/strategize_frontend/locations/get_states_regions_subregions`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
    });

    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setStateRegionSubregionOptions', data.result);
    }
  },
};
