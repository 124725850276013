<template>
  <div>
    <div style="display:flex;justify-content: space-around;">
      <div ref="postingsCountDiv" v-if="!this.showCompaniesAndJobPostingsLoading" class="stacked">
        <h1 class="bin-numbers">{{
          formatNumberWithComma(jobPostingsAndUniqueCompaniesData?.count_of_postings)
        }}</h1>
        <p class="banner-title">Job Postings</p>
      </div>
      <div v-else class="stacked">
        <LoadingSpinner
          class="loading-spinner py-2"
          :loading="true"
          :color="'#70A300'"
          :size="'80px'"
        />
      </div>
      <div ref="uniqueCompaniesDiv" v-if="!this.showCompaniesAndJobPostingsLoading" class="stacked">
        <h1 class="bin-numbers">{{
          formatNumberWithComma(jobPostingsAndUniqueCompaniesData?.distinct_companies)
        }}</h1>
        <p class="banner-title">Unique Companies</p>
      </div>
      <div v-else class="stacked">
        <LoadingSpinner
          class="loading-spinner py-2"
          :loading="true"
          :color="'#70A300'"
          :size="'80px'"
        />
      </div>
      <div ref="advertisedWageDiv" v-if="!this.showMedianAdvertisedWageLoading" class="stacked">
        <h1 class="bin-numbers">{{
          formatYearlyWage(medianAdvertisedTimeframeWageData?.timeframe_wage_median)
        }}</h1>
        <p class="banner-title">Median Advertised {{wageTimeFrame}} Wage</p>
      </div>
      <div v-else class="stacked">
        <LoadingSpinner
          class="loading-spinner py-2"
          :loading="true"
          :color="'#70A300'"
          :size="'80px'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import LoadingSpinner from '../StyledComponents/LoadingSpinner.vue';

export default {
  name: 'JobPostingsBanner',
  components: {
    LoadingSpinner,
  },
  props: {
    wageTimeFrame: {
      type: String,
      default: 'Yearly',
      required: true,
    },
    showCompaniesAndJobPostingsLoading: {
      type: Boolean,
      required: true,
    },
    showMedianAdvertisedWageLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      jobPostingsAndUniqueCompaniesData: 'jobPostings/getJobPostingsAndUniqueCompaniesData',
      medianAdvertisedTimeframeWageData: 'jobPostings/getMedianAdvertisedTimeframeWageData',
    }),
  },
  methods: {
    formatNumberWithComma(number) {
      if (!number) return '-';
      return number.toLocaleString();
    },
    formatYearlyWage(wage) {
      if (!wage) return '-';

      if (this.wageTimeFrame === 'CPM') {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          roundingMode: 'ceil',
          maximumFractionDigits: 2,
        }).format(wage);
      }

      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        roundingMode: 'ceil',
        maximumFractionDigits: 0,
      }).format(Math.round(wage));
    },
    setWidth() {
      this.$refs.postingsCountDiv.style.minWidth = `${this.$refs.postingsCountDiv.offsetWidth}px`;
      this.$refs.uniqueCompaniesDiv.style.minWidth = `${this.$refs.uniqueCompaniesDiv.offsetWidth}px`;
      this.$refs.advertisedWageDiv.style.minWidth = `${this.$refs.advertisedWageDiv.offsetWidth}px`;
    },
  },
};

</script>
<style scoped lang="scss">
.bin-numbers {
  color: #70A300;
  font-family: Roboto,sans-serif!important;
  font-size: 40px !important;
  font-weight: 500;
}
.banner-title {
  font-size: 16px;
  font-family: Roboto,sans-serif!important;
  color: #444444;
  font-weight: 400;
}
.stacked {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
