<template>
  <div
    class="no-data-component"
  >
    <h5>{{ errorMessage }}</h5>
    <h6>{{ errorSubMessage }}</h6>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: 'Looks like that query has no results.',
    },
    errorSubMessage: {
      type: String,
      required: false,
      defualt: '',
    },
  },
};
</script>
<style scoped>
.no-data-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  line-height: 30px;
}
</style>
