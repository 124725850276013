<template>
  <div class="legend-container">
    <div
      v-for="value in legendValues"
      :key="`${value.title} - ${Math.random()}`"
      class="one-legend-element"
    >
      <hr
        :style="`background-color: ${value.color} !important; opacity: 1; height:3px;`"
        width="30px;"
        size="8"
      />
      <div class="line-title">
        {{ value.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Legend',
  props: {
    legendValues: { type: Array, required: true },
  },
  mounted() {},
};
</script>
<style scoped>
.legend-container > :first-child {
  margin-right: 2rem;
}
.legend-container > :last-child {
  margin-left: 2rem;
}
.one-legend-element {
  display: flex;
  justify-content: center;
  align-items: center;
}
.line-title {
  margin-left: 15px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #575757;
}
</style>
