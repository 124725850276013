export default {
  getCompaniesSelected(state) {
    return state.selectedCompanies;
  },
  getCpmChartKey(state) {
    return state.cpmChartKey;
  },
  getCphData(state) {
    return state.cphData;
  },
  getCphChartKey(state) {
    return state.cphChartKey;
  },
  getLthData(state) {
    return state.lthData;
  },
  getCplData(state) {
    return state.cplData;
  },
  getCthData(state) {
    return state.cthData;
  },
  getYoyLTHChange(state) {
    return state.yoyLTHChange;
  },
  getCplChartKey(state) {
    return state.cplChartKey;
  },
  getLthChartKey(state) {
    return state.lthChartKey;
  },
  getDriverValues(state) {
    return state.driverValues;
  },
  getRouteTypeValues(state) {
    return state.routeTypeValues;
  },
  getHaulTypeValues(state) {
    return state.haulTypeValues;
  },
  getEndorsementRequiredValues(state) {
    return state.endorsementRequiredValues;
  },
  getDriverOrMechanicData(state) {
    return state.driverOrMechanicData;
  },
};
