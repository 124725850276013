export default {
  setLocations(state, payload) {
    state.locations = payload;
    state.locationsLoading = false;
  },

  setLocationModalData(state, payload) {
    state.locationRowModalData = payload;
  },

  setLocationModalLoading(state, payload) {
    state.locationRowLoading = payload;
  },

  setLocationBreakdown(state, payload) {
    state.locationBreakdown = payload;
  },

  setLocationBreakdownLoading(state, payload) {
    state.locationBreakdownLoading = payload;
  },

  setStateRegionSubregionOptions(state, options) {
    state.stateRegionSubregionOptions = options;
  },
};
