/* ============
 * Auth Module
 * ============
 */

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// initial state
const state = () => ({
  locations: [],
  locationsLoading: true,
  locationRowModalData: [],
  locationRowLoading: true,
  locationBreakdown: {},
  locationBreakdownLoading: true,
  stateRegionSubregionOptions: [],
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
