/* ============
 * Notifications Module
 * ============
 */

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// initial state
const state = () => ({
  today: new Date(),
  icons: ['done', 'calendar_today', 'schedule', 'pending', 'trending_up'],
  adminNotifications: [],
  userNotifications: [],
  adminNotificationsCurrentPage: 1,
  adminTotal: 0,
  userTotal: 0,
  last_page: 1,
  loading: false,
  silence_notifications: false,
  modalOpen: false,
  users: [],
  lastNotificationReceived: {}, // Avoid duplication
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
