import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
  companies: [],
  hasMoreCompanies: true,
  titleData: null,
  inputtedCompanyDistributionStatistics: '',
  inputtedCompanyDistributionStatisticsLoading: false,
  inputtedCompanyName: '',
  inputtedYearlyWageRange: {
    timeFrame: 'Yearly', min: null, max: null, completed: false,
  },
  inputtedWeeklyWageRange: {
    timeFrame: 'Weekly', min: null, max: null, completed: false,
  },
  inputtedHourlyWageRange: {
    timeFrame: 'Hourly', min: null, max: null, completed: false,
  },
  inputtedCPMWageRange: {
    timeFrame: 'Cents per Mile', min: null, max: null, completed: false,
  },
  jobPostingsAndUniqueCompaniesData: null,
  medianAdvertisedTimeframeWageData: null,
  selectedDistributionWageRangesUsed: false,
  selectedDistributionWageRangesLoading: false,
  minimumLoadingTimeAchieved: false,
  selectedDistributionWageRanges: [],
  advertisedSalaryDistribution: {},
  advertisedSalaryDistributionLoading: true,
  advertisedWageRangesLoading: true,
  fetchBannerJobPostingsAndDistinctCompaniesDataLoading: false,
  fetchBannerMedianAdvertisedTimeframeWageLoading: false,
  advertisedWageRanges: {},
  advertisedSalaryDistributionPresence: false,
  advertisedWageRangePresence: false,
  postingsByCompany: [],
  postingsByCompanyPresence: true,
  postingsByCompanyLoading: false,
  bonusData: [],
  bonusDataLoading: false,
  bonusDataPresence: true,
  hometimeBenefitData: [],
  hometimeBenefitDataLoading: false,
  hometimeBenefitDataPresence: true,
  showUserWageChart: false,
  hometimePostingBenefitsDetailsData: [],
  hometimePostingBenefitsDetailsDataLoading: false,
  geographicalData: [],
  geographicalChartData: [],
  jobPostingsMapLoading: true,
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
