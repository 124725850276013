<template>
  <div>
    <v-icon
    v-if="up"
    class="difference-banner-increase"
    :style="`color: ${color}; font-size: ${size} !important`"
    >
      mdi-menu-up
    </v-icon>
    <v-icon
      v-else
      class="difference-banner-increase"
      :style="`color: ${color}; font-size: ${size} !important`"
    >
      mdi-menu-down
    </v-icon>
  </div>
</template>
<script>
export default {
  name: 'UpOrDownArrow',
  props: {
    up: { type: Boolean, required: true },
    color: { type: String, required: true },
    size: { type: String, required: true },
  },
};
</script>
