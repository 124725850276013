import * as turf from '@turf/turf';
import polylabel from 'polylabel';

/**
 * Finds the largest polygon within a given feature or array feature
 * @param {GeoJSON|Array<GeoJSON>} feature
 * @returns An object with the coordinates and turf.js area object
 */
function findLargestArea(feature) {
  if (Array.isArray(feature)) {
    const centers = feature.map((f) => findLargestArea(f));

    return centers.reduce(
      (maxCenter, center) => (center.area > maxCenter.area ? center : maxCenter),
      { area: 0 },
    );
  }

  const geom = feature.geometry;

  if (geom.type === 'Polygon') {
    return { coordinates: geom.coordinates, area: turf.area(geom) };
  }

  return geom.coordinates.reduce((obj, coords) => {
    const area = turf.area({ type: 'Polygon', coordinates: coords });
    return area > obj.area ? { coordinates: coords, area } : obj;
  }, { area: 0 });
}

/**
 * Creates a buffer from the given coordinates and a radius in miles
 * @param {Array<number>} lngLatArray
 * @param {number} radiusInMiles
 * @returns A turf.js buffer object
 */
function createBuffer(lngLatArray, radiusInMiles) {
  const coords = Array.isArray(lngLatArray) ? lngLatArray : [lngLatArray.lng, lngLatArray.lat];
  const point = turf.point(coords);
  return turf.buffer(point, radiusInMiles, { units: 'miles' });
}

/**
 * Creates a GeoJSON feature collection object with the given features
 * @param {Array<GeoJSON>} features
 * @returns A feature collection object
 */
function createFeatureCollection(features) {
  return {
    type: 'FeatureCollection',
    features,
  };
}

function createPoint(coordinates) {
  return {
    type: 'Point',
    coordinates,
  };
}

function createFeature(geometry, properties) {
  return {
    type: 'Feature',
    geometry,
    properties: properties ?? {},
  };
}

/**
 * Returns the features found within the buffer
 * @param {*} features The features to filter
 * @param {*} buffer The buffer to search within
 * @returns A list of features
 */
function getFeaturesInBuffer(features, buffer) {
  return features.filter((feature) => {
    const coords = polylabel(findLargestArea(feature).coordinates, 1.0);

    return turf.booleanPointInPolygon(coords, buffer);
  });
}

export {
  createBuffer,
  createFeature,
  createFeatureCollection,
  createPoint,
  findLargestArea,
  getFeaturesInBuffer,
};
