<template>
  <div class="pagination-wrapper m-2 mt-4">
    <v-row class="">
      <v-col class="total-companies">
        <p><b> {{ determineCurrentlyViewingCount }}
           of {{ countOfData.toLocaleString() }} {{ keyForFooter }}</b></p>
      </v-col>
      <v-col class="d-flex total-pages">
        <v-select
          class="mt-0 pt-0 pagination-select"
          v-model="pageNumber"
          @change="$emit('page-selected', pageNumber)"
          :height="20"
          :items="Array.from({length: paginations}, (_, i) => i + 1)"
          :menu-props="{offsetY: true, top: true, maxHeight: 120}"
          append-icon="expand_more"
          aria-label="Select Page"
        />
        <div class="pages-length">
          <p>of {{ paginations }} pages</p>
        </div>
      </v-col>
      <v-col class="d-flex">
        <v-col class="flex-grow-0 pt-0">
          <svg :class="{'disabled': pageNumber === 1}" @click="handlePageChange(-1)" style="cursor: pointer;" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <!-- eslint-disable-next-line max-len -->
            <path d="M0.212187 5.53366L6.07894 0.193175C6.36189 -0.0643917 6.82062 -0.0643917 7.10354 0.193175L7.7878 0.816057C8.07026 1.07318 8.0708 1.48991 7.789 1.74765L3.1395 6.00001L7.789 10.2524C8.0708 10.5101 8.07026 10.9268 7.7878 11.1839L7.10354 11.8068C6.82059 12.0644 6.36186 12.0644 6.07894 11.8068L0.212187 6.46637C-0.0707293 6.2088 -0.0707293 5.79122 0.212187 5.53366Z" fill="#000000"/>
          </svg>
        </v-col>
        <v-col class="flex-grow-0 pt-0">
          <svg :class="{'disabled': pageNumber === paginations}" @click="handlePageChange(1)" style="cursor: pointer;" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <!-- eslint-disable-next-line max-len -->
            <path  d="M7.78781 6.46634L1.92106 11.8068C1.63811 12.0644 1.17938 12.0644 0.896463 11.8068L0.212204 11.1839C-0.0702607 10.9268 -0.0708043 10.5101 0.210996 10.2524L4.8605 5.99999L0.210996 1.74764C-0.0708043 1.48991 -0.0702607 1.07318 0.212204 0.816056L0.896463 0.193175C1.17941 -0.0643921 1.63814 -0.0643921 1.92106 0.193175L7.78781 5.53363C8.07073 5.7912 8.07073 6.20878 7.78781 6.46634Z" fill="#000000"/>
          </svg>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    paginations: {
      type: Number,
      required: false,
      default: 2,
    },
    countOfData: {
      type: Number,
      required: true,
      default: 0,
    },
    dataPerPage: {
      type: Number,
      required: true,
      default: 250,
    },
    keyForFooter: {
      type: String,
      required: false,
      default: 'companies',
    },
  },
  data() {
    return {
      pageNumber: 1,
    };
  },
  computed: {
    determineCurrentlyViewingCount() {
      if (this.countOfData < this.dataPerPage) {
        return this.countOfData;
      }
      if (this.pageNumber === 1) {
        return `1 - ${this.pageNumber * this.dataPerPage}`;
      }
      if (this.pageNumber === this.paginations) {
        return `${(((this.pageNumber - 1) * this.dataPerPage) + this.pageNumber - 1).toLocaleString()} - ${this.countOfData.toLocaleString()}`;
      }
      return `${(((this.pageNumber - 1) * this.dataPerPage) + this.pageNumber - 1).toLocaleString()} - ${((this.pageNumber * this.dataPerPage) + this.pageNumber - 1).toLocaleString()}`;
    },
  },
  methods: {
    handleInput(input) {
      if (Number.isNaN(Number.parseInt(input.data, 10)) || input.srcElement.valueAsNumber === 0) {
        this.$refs['input-component'].value = '';
        this.$refs['input-component'].focus();
      }
      if (input.srcElement.valueAsNumber > this.paginations) {
        this.$refs['input-component'].value = input.srcElement.value.slice(0, -1);
        this.$refs['input-component'].focus();
      }
    },
    handlePageChange(forwardOrBackward) {
      if (forwardOrBackward === 1 && this.pageNumber + 1 <= this.paginations) {
        this.pageNumber += 1;
      } else if (forwardOrBackward === -1 && this.pageNumber - 1 >= 1) {
        this.pageNumber -= 1;
      }
      this.$emit('page-selected', this.pageNumber);
    },
  },
};

</script>
<style lang="scss" scoped>
.pagination-wrapper {
  flex-grow: 1;
  justify-content: space-between;
  .total-companies {
    flex: 2;
  }
  .total-pages {
    flex: 2;
    .pagination-select {
      flex: 1;
      max-width: 50px;
    }
    .pages-length {
      flex: 2;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  textarea:focus, input:focus{
    outline: none;
  }
  .pagination-input {
    border: solid #BDBDBD;
  }
  .disabled {
    path {
      fill: #E0E0E0
    }
  }
}
</style>
