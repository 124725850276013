import Vue from 'vue';

export default {
  setToastMessage(state, payload) {
    Vue.$toast.open({
      message: payload.message,
      type: payload.type,
      duration: 3000,
    });
  },
  setDriverOrMechanicData(state, payload) {
    state.driverOrMechanicData = payload;
  },
};
