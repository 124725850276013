import Vue from 'vue';

export default {

  setToastMessage(state, payload) {
    Vue.$toast.open({
      message: payload.message,
      type: payload.type,
      duration: 3000,
    });
  },
  setUsers(state, data) {
    state.users = data.result;
    state.lastPageUsersList = parseInt(data.total_pages, 10);
  },
};
