<template>
  <div>
    <slot>
      <LocationRowModal
        v-if="locationRowModalActive && !locationModalDataLoading"
        :showModal="locationRowModalActive"
        :columnHeaders="columnHeaders"
        :columnValues="columnValues"
        :locationRowModalData="locationRowModalData"
        :rowClicked="title"
        @closeModal="closeModal"
      />
      <LoadingSpinner
        class="loading-spinner"
        :loading="locationRowModalActive && locationModalDataLoading"
        :color="'#70A300'"
        :size="'150px'"
      />
    </slot>
    <div class="wrapper">
      <div class="mt-4 align-self-center">
        <span class="card-title">
          {{ title }}
        </span>
        <TooltipIcon v-if="tooltip" :tip="tooltip" :tooltipTitle="title" />
      </div>
      <span class="card-subtitle align-self-center">
        {{ subTitle }}
      </span>
      <div class="align-self-center">
        <v-tooltip
          :left="tooltipPosition === 'left'"
          :right="tooltipPosition === 'right'"
          :content-class="'tooltip-stat-point-graph'"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <h1
                :class="'title ' + textClass"
                class="d-inline-block mr-3"
                style="font-size: 2.5rem !important"
              >
                {{ appendPlusWhenRequired(value) }}
              </h1>
              <span
                class="icon"
                @click="
                  openLocationRowModal({
                    columnHeaders: [
                      locationModalColumnData[chartId].columnHeader1,
                      locationModalColumnData[chartId].columnHeader2,
                    ],
                    columnValues: [
                      locationModalColumnData[chartId].columnValue1,
                      locationModalColumnData[chartId].columnValue2,
                    ],
                  })
                "
              >
                <v-icon
                  size="large"
                  class="gray-icon mb-3"
                  @click="locationRowModalActive = true"
                  aria-label="View Location Row Data"
                >mdi-file-document-multiple</v-icon>
              </span>
            </div>
          </template>
          <div class="tooltip-content">
            <div class="tooltip-header">
              <p class='info-box-title' v-html="infoBoxTitle"></p>
              <p class='info-box-subtitle' v-html="infoBoxSubTitle"></p>
            </div>
            <div class="tooltip-body">
              <div v-html="infoBoxContent"></div>
            </div>
          </div>
        </v-tooltip>
      </div>
      <div class="chart-wrapper">
        <DotBarSlider
          :canvasId="chartId"
          :minProgress="minRange"
          :maxProgress="maxRange"
          :progressData="percentValue"
          :colorBarDirection="colorBarDirection"
          :colorScaleReversed="colorScaleReversed"
          :pointWidth="pointWidth"
          :barHeight="barHeight"
          :thrashHoldOne="thrashHoldOne"
          :thrashHoldTwo="thrashHoldTwo"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import LocationRowModal from '../StyledComponents/Modals/LocationRowModal.vue';

import LoadingSpinner from '../StyledComponents/LoadingSpinner.vue';
import TooltipIcon from './TooltipIcon.vue';
import DotBarSlider from './DotBarSlider.vue';

export default {
  name: 'StatPointGraph',
  components: {
    TooltipIcon,
    DotBarSlider,
    LocationRowModal,
    LoadingSpinner,
  },
  props: {
    value: { type: String, required: true },
    tooltip: [String, Array],
    title: String,
    subTitle: String,
    popoverContent: String,
    chartId: String,
    reversed: Boolean,
    minRange: Number,
    maxRange: Number,
    barHeight: Number,
    pointWidth: Number,
    colorBarDirection: String,
    colorScaleReversed: Boolean,
    percentValue: String,
    locationType: { type: String },
    locationCode: { type: String },
    thrashHoldOne: Number,
    thrashHoldTwo: Number,
    tooltipPosition: { type: String, default: 'right' },
  },
  data() {
    // Don't show undefined in popover subtitle
    let infoBoxSubTitle = this.subTitle;

    if (infoBoxSubTitle === undefined) {
      infoBoxSubTitle = '';
    }

    return {
      locationRowModalActive: false,
      columnHeaders: [],
      columnValues: [],
      locationModalColumnData: {
        estimatedcostPerLead: {
          columnHeader1: 'Estimated Cost per Lead',
          columnHeader2: 'Estimated Cost per Lead Percentile',
          columnValue1: 'estimated_cpl',
          columnValue2: 'estimated_cpl_percentile',
        },
        leadToHireRatio: {
          columnHeader1: 'Lead-to-Hire Ratio (Randall Reilly, 12 Months)',
          columnHeader2: 'Lead-to-Hire Ratio Percentile',
          columnValue1: 'lead_to_hire_ratio_12mo',
          columnValue2: 'lead_to_hire_ratio_12mo_percentile',
        },
        estimatedCostPerHire: {
          columnHeader1: 'Estimated Cost Per Hire',
          columnHeader2: 'Estimated Cost Per Hire Percentile',
          columnValue1: 'estimated_cph',
          columnValue2: 'estimated_cph_percentile',
        },
        postingCompetitionScore: {
          columnHeader1: 'Posting Competition Compared to the National Avg.',
          columnHeader2: 'Posting Competition Percentile',
          columnValue1: 'posting_competition_from_national_avg',
          columnValue2: 'posting_competition_percentile',
        },
        driverInterestScore: {
          columnHeader1: 'Driver Interest Compared to the National Avg.',
          columnHeader2: 'Driver Interest Percentile',
          columnValue1: 'driver_interest_from_national_avg',
          columnValue2: 'driver_interest_percentile',
        },
        driverPopulationUniqueRespondents: {
          columnHeader1: 'Unique Respondents (Randall Reilly past 12 months)',
          columnHeader2: 'Randall Reilly Unique Respondents Percentile',
          columnValue1: 'unique_respondents_12mo',
          columnValue2: 'unique_respondents_12mo_percentile',
        },
        driverPopulationDriverContacts: {
          columnHeader1: 'Randall Reilly Unique Driver Contacts (past 5 years)',
          columnHeader2: 'Randall Reilly Unique Driver Contacts Percentile',
          columnValue1: 'audience_count_5yr',
          columnValue2: 'audience_count_alltime_percentile',
        },
        driverPopulationTruckingEmploymentCount: {
          columnHeader1: 'Trucking Employment Count',
          columnHeader2: 'Trucking Employment Count Percentile',
          columnValue1: 'trucking_employment_count',
          columnValue2: 'trucking_employment_count_percentile',
        },
        driverPopulationLaborForceSize: {
          columnHeader1: 'Labor Force Size',
          columnHeader2: 'Labor Force Size Percentile',
          columnValue1: 'labor_force_size',
          columnValue2: 'labor_force_size_percentile',
        },
        driverPopulationTlfRatio: {
          columnHeader1: 'Trucking Employment to Labor Force Ratio',
          columnHeader2: 'Trucking Employment to Labor Force Ratio Percentile',
          columnValue1: 'trucking_employment_to_labor_force_ratio_number',
          columnValue2: 'trucking_employment_to_labor_force_ratio_number_percentile',
        },
        competitionStatsActiveJobPostings: {
          columnHeader1: 'Active Job Postings',
          columnHeader2: 'Active Job Postings Percentile',
          columnValue1: 'active_job_postings',
          columnValue2: 'active_job_postings_percentile',
        },
        competitionStatsCompaniesWithJobPostings: {
          columnHeader1: 'Companies with a Job Posting (past 3 months)',
          columnHeader2: 'Companies with a Job Posting Percentile',
          columnValue1: 'active_job_posting_companies',
          columnValue2: 'active_job_posting_companies_percentile',
        },
        competitionStatsTruckingEmployerCount: {
          columnHeader1: 'Trucking Companies Domiciled in Area',
          columnHeader2: 'Trucking Companies Domiciled in Area Percentile',
          columnValue1: 'employer_count_trucking',
          columnValue2: 'employer_count_trucking_percentile',
        },
        competitionStatsUnemploymentRate: {
          columnHeader1: 'Overall Unemployment Rate',
          columnHeader2: 'Overall Unemployment Rate Percentile',
          columnValue1: 'unemployment_rate',
          columnValue2: 'unemployment_rate_percentile',
        },
        driverMarketTotalHires: {
          columnHeader1: 'Attributed Hires (Randall Reilly, past 12 months)',
          columnHeader2: 'Randall Reilly Attributed Hires Percentile',
          columnValue1: 'hire_count_12mo',
          columnValue2: 'hire_count_12mo_percentile',
        },
        driverMarketLeadCount: {
          columnHeader1: 'Aggregated Lead Count (Randall Reilly, past 12 months)',
          columnHeader2: 'Randall Reilly Aggregated Lead Count Percentile',
          columnValue1: 'lead_count_12mo',
          columnValue2: 'lead_count_12mo_percentile',
        },
        driverMarketMedianAdvertisedSalary: {
          columnHeader1: 'Median Advertised Salary from Job Postings',
          columnHeader2: 'Median Advertised Salary from Job Postings Percentile',
          columnValue1: 'median_advertised_salary',
          columnValue2: 'median_advertised_salary_percentile',
        },
        driverMarketAverageReportedSalary: {
          columnHeader1: 'Average Reported Salary',
          columnHeader2: 'Average Reported Salary Percentile',
          columnValue1: 'average_reported_salary',
          columnValue2: 'average_reported_salary_percentile',
        },
      },
      infoBoxTitle: this.title,
      infoBoxSubTitle,
      infoBoxContent: this.popoverContent,
    };
  },
  computed: {
    ...mapGetters({
      locationRowLoading: 'locations/getLocationRowLoading',
    }),
    locationRowModalData() {
      return this.$store.state.locations.locationRowModalData;
    },
    locationModalDataLoading() {
      return this.$store.state.locations.locationRowLoading;
    },
    chartValue() {
      return parseInt(this.percentValue, 10);
    },
    textClass() {
      let text = '';

      if (this.colorScaleReversed) {
        if (this.chartValue >= this.minRange && this.chartValue <= this.thrashHoldOne) {
          text = 'greenText';
        } else if (
          this.chartValue >= this.thrashHoldOne + 1
          && this.chartValue <= this.thrashHoldTwo
        ) {
          text = 'blueText';
        } else if (this.chartValue >= this.thrashHoldTwo + 1 && this.chartValue <= this.maxRange) {
          text = 'redText';
        } else if (this.chartValue > this.maxRange) {
          text = 'redText';
        }
      } else if (this.chartValue >= this.minRange && this.chartValue <= this.thrashHoldOne) {
        text = 'redText';
      } else if (
        this.chartValue >= this.thrashHoldOne + 1
        && this.chartValue <= this.thrashHoldTwo
      ) {
        text = 'blueText';
      } else if (this.chartValue >= this.thrashHoldTwo + 1 && this.chartValue <= this.maxRange) {
        text = 'greenText';
      } else if (this.chartValue > this.maxRange) {
        text = 'greenText';
      }

      return text;
    },
  },
  methods: {
    closeModal() {
      this.columnHeaders = [];
      this.locationRowModalActive = false;
    },
    async openLocationRowModal(value) {
      const data = {
        locationType: this.locationType,
        locationCode: this.locationCode,
        firstColumn: value.columnValues[0],
        secondColumn: value.columnValues[1],
      };
      this.locationRowModalActive = true;
      this.columnHeaders = value.columnHeaders;
      this.columnValues = value.columnValues;
      await this.$store.dispatch('locations/getLocationModalData', data);
    },
    appendPlusWhenRequired(value) {
      if (this.title === 'Posting Competition' || this.title === 'Driver Interest') {
        if (!value.startsWith('-')) {
          return `+${value}`;
        }
      }

      return value;
    },
  },
};
</script>

<style lang="scss">
  // Style the tooltip
  .tooltip-stat-point-graph {
    opacity: 1 !important;
    text-align: justify;
    max-width: 300px;
    background: #666666 !important;

    .tooltip-content {
      padding: 0.85rem 0.65rem 0.85rem 0.65rem !important;

      .tooltip-header {
        color: white;
        border-bottom: 2px solid white;
        padding: 0.25rem 0 0.75rem 0 !important;

        p {
          margin-bottom: 0px !important;
        }

        .info-box-title {
          color: white;
          font-weight: bold;
          font-size: 1rem;
        }

        .info-box-subtitle {
          color: white !important;
          margin-bottom: 0 !important;
          font-size: 0.75rem !important;
        }
      }

      .tooltip-body {
        background: #666666;
        padding: 0.75rem 0 0rem 0rem !important;

        p {
          font-weight: normal;
          color: white;
          font-size: 0.875rem;
        }

        i {
          font-weight: 400;
          color: #dddddd;
        }
      }
    }

    // Style the input inside popover on locations page as a slider
    .slidecontainer {
      width: 100%;
    }
    .regular {
      .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 0.3rem;
        background: #70a300; /* Old browsers */
        background: -moz-linear-gradient(
          left,
          #bc2333 0%,
          #bc2333 33%,
          #1b87c0 33%,
          #1b87c0 66%,
          #70a300 66%,
          #70a300 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          left,
          #bc2333 0%,
          #bc2333 33%,
          #1b87c0 33%,
          #1b87c0 66%,
          #70a300 66%,
          #70a300 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to right,
          #bc2333 0%,
          #bc2333 33%,
          #1b87c0 33%,
          #1b87c0 66%,
          #70a300 66%,
          #70a300 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(
          startColorstr='#BC2333',
          endColorstr='#70A300',
          GradientType=1
        ); /* IE6-9 */
        outline: none;
        opacity: 1;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
      }
      .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 0;
        height: 0;
        background: transparent;
        cursor: pointer;
        margin-bottom: 5.5%;
        border-left: 0.42rem solid transparent;
        border-right: 0.42rem solid transparent;
        border-top: 0.42rem solid #ffffff;
      }
      .slider::-moz-range-thumb {
        appearance: none;
        width: 0;
        height: 0.8rem;
        background: transparent;
        cursor: pointer;
        border-bottom: none;
        border-left: 0.55rem solid transparent;
        border-right: 0.55rem solid transparent;
        border-top: 0.5rem solid #ffffff;
      }
    }
    .reversed {
      .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 0.3rem;
        background: #70a300; /* Old browsers */
        background: -moz-linear-gradient(
          left,
          #70a300 0%,
          #70a300 33%,
          #1b87c0 33%,
          #1b87c0 66%,
          #bc2333 66%,
          #bc2333 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          left,
          #70a300 0%,
          #70a300 33%,
          #1b87c0 33%,
          #1b87c0 66%,
          #bc2333 66%,
          #bc2333 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to right,
          #70a300 0%,
          #70a300 33%,
          #1b87c0 33%,
          #1b87c0 66%,
          #bc2333 66%,
          #bc2333 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(
          startColorstr='#70A300',
          endColorstr='#BC2333',
          GradientType=1
        ); /* IE6-9 */
        outline: none;
        opacity: 1;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
      }
      .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 0;
        height: 0;
        background: transparent;
        cursor: pointer;
        margin-bottom: 5.5%;
        border-left: 0.42rem solid transparent;
        border-right: 0.42rem solid transparent;
        border-top: 0.42rem solid #ffffff;
      }
      .slider::-moz-range-thumb {
        appearance: none;
        width: 0;
        height: 0.8rem;
        background: transparent;
        cursor: pointer;
        border-bottom: none;
        border-left: 0.55rem solid transparent;
        border-right: 0.55rem solid transparent;
        border-top: 0.5rem solid #ffffff;
      }
    }
  }
</style>

<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 212px;
  padding: 0 8px;
  margin-bottom: 6%;
}

.chart-wrapper {
  padding: 0.5rem;
}

.title {
  margin-bottom: 32px;
  flex-grow: 1;
  text-shadow: none;
}

.card-title {
  font-size: 16px;
  color: #666666;
  flex-grow: 1;
  vertical-align: middle;
}

.graph {
  margin-top: 8px;
}

.stat-point-graph-popover {
  text-align: justify;
  -webkit-box-shadow: 0px 1px 20px 4px rgba(59, 59, 59, 0.2);
  -moz-box-shadow: 0px 1px 20px 4px rgba(59, 59, 59, 0.2);
  box-shadow: 0px 1px 20px 4px rgba(59, 59, 59, 0.2);
  background: #eeeeee;
  transform: translate3d(619px, 395px, 0px);
}

.greenText {
  color: #70a300;
}

.blueText {
  color: #1b87c0;
}

.redText {
  color: #bc2333;
}

.gray-icon {
  color: #666666;

  &:hover {
    color: darken(#666666, 20%);
  }
}

.card-subtitle {
  font-size: 12px;
  color: #666666;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  vertical-align: middle;
}
</style>
