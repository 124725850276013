import XLSX from 'xlsx';

const excelParser = () => {
  function exportDataFromJSON(data, newFileName, sheetName, fileType) {
    if (!data) return;
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, `${newFileName}.${fileType}`, { compression: true });
  }

  return {
    exportDataFromJSON,
  };
};

export default excelParser;
