<!-- eslint-disable max-len -->
<template>
  <v-dialog v-model="restrictPageAccess" persistent max-width="500">
    <v-card class="no-access-dialog">
      <v-card-title class="headline"
        :class="{'driver-color': restrictedPageAccessType === 'Driver', 'mechanic-color': restrictedPageAccessType === 'Mechanic'}">
        Hello! It looks like you aren't subscribed to {{ restrictedPageAccessType }} Insights.</v-card-title>
      <v-card-text v-if="restrictedPageAccessType === 'Driver'">
        The <b> Driver Insights </b> section of Strategize illustrates the difficulty level in hiring truck drivers in specific locations, and whether your job offering is competitive there.</v-card-text>
      <v-card-text v-else>The <b>Mechanic Insights </b> section of Strategize provides detailed information about diesel technician mechanic job postings.</v-card-text>
      <v-card-text v-if="restrictedPageAccessType === 'Driver'">Use these insights to help decide where to bid on freight and what to pay drivers.</v-card-text>
      <v-card-text v-else>Understand who you are competing with for mechanics and whether your job offering stands out in minutes.</v-card-text>
      <v-card-text class="gray">For more details, contact your Account team, or submit a form through the button below.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog">Go back</v-btn> <v-btn @click="supportRequest">Contact Support</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- eslint-enable max-len -->
</template>

<script>
import { mapGetters } from 'vuex';
import setDialogPosition from '../../helpers/contact-us-dialog';

export default {
  name: 'NoAccessDialog',
  data: () => ({}),
  computed: {
    ...mapGetters({
      competitionPageSelected: `global/getDriverOrMechanicData`,
      restrictPageAccess: 'auth/getRestrictPageAccess',
      restrictedPageAccessType: 'auth/getRestrictedPageAccessType',
    }),
  },
  methods: {
    closeDialog() {
      this.$store.commit('auth/setRestrictPageAccess', false);
    },
    supportRequest() {
      this.closeDialog();
      // eslint-disable-next-line no-undef
      createHubspotForm();
      setDialogPosition();
    },
  },
};
</script>
<style scoped lang="scss">
.no-access-dialog {
  .v-card__text {
    color: black !important;
  }
  .gray {
    color: rgba(0,0,0,.6) !important;
  }
  .driver-color {
    color: #5E8703;
  }
  .mechanic-color {
    color: #1597AF;
  }
}
</style>
