<template>
  <div class="locations-page">
    <div :class="classNameSlider">
      <vue-slider
        v-model="progress"
        :silent="true"
        :disabled="true"
        :marks="marks"
        :tooltip-formatter="tooltipFormatter"
        :contained="true"
        :direction="sliderDirection"
        :hide-label="true"
        :min="minProgress"
        :max="maxProgress"
      ></vue-slider>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import ordinalSuffixOf from '../../helpers/number-utilities';

export default {
  name: 'DotBarSlider',
  components: {
    VueSlider,
  },
  props: {
    progressData: String,
    minProgress: Number,
    maxProgress: Number,
    colorBarDirection: String,
    colorScaleReversed: Boolean,
    pointWidth: Number,
    barHeight: Number,
    canvasId: String,
    offset: Number,
    thrashHoldOne: Number,
    thrashHoldTwo: Number,
  },
  data() {
    return {
      borderColor: '#808080',
      chartPointOffset: this.offset,
      marks: [this.minProgress, (this.minProgress + this.maxProgress) / 2, this.maxProgress],
    };
  },
  computed: {
    progress() {
      let value = parseInt(this.progressData, 10);
      value = value >= this.maxProgress ? this.maxProgress : value;

      return value;
    },
    classNameSlider() {
      let sliderClass;

      if (this.colorScaleReversed) {
        if (this.progress >= this.minProgress && this.progress <= this.thrashHoldOne) {
          sliderClass = 'reverse-threshhold-zero-to-one';
        } else if (this.progress >= this.thrashHoldOne + 1 && this.progress <= this.thrashHoldTwo) {
          sliderClass = 'reverse-threshhold-one-to-two';
        } else if (this.progress >= this.thrashHoldTwo + 1 && this.progress <= this.maxProgress) {
          sliderClass = 'reverse-threshhold-two-to-max';
        }
      } else if (this.progress >= this.minProgress && this.progress <= this.thrashHoldOne) {
        sliderClass = 'threshhold-zero-to-one';
      } else if (this.progress >= this.thrashHoldOne + 1 && this.progress <= this.thrashHoldTwo) {
        sliderClass = 'threshhold-one-to-two';
      } else if (this.progress >= this.thrashHoldTwo + 1 && this.progress <= this.maxProgress) {
        sliderClass = 'threshhold-two-to-max';
      }

      return sliderClass;
    },
    tooltipFormatter() {
      const value = this.progress >= this.maxProgress ? `+${this.maxProgress}%+` : `${this.progress}%`;
      if (
        this.$props.canvasId === 'postingCompetitionScore'
        || this.$props.canvasId === 'driverInterestScore'
      ) {
        return `${value} from the national average`;
      }
      return `${ordinalSuffixOf(value.toString().replace(/%/g, ''))} percentile`;
    },
    sliderDirection() {
      return this.colorBarDirection === 'left' ? 'ltr' : 'rtl';
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
</style>
