export default {
  deleteCodeChallengeMutation(state) {
    state.codeChallenge = '';
  },

  setAuthenticated(state, payload) {
    localStorage.setItem('tokenAuthenticated', payload);
  },

  setUserData(state, payload) {
    state.userEmail = payload.email_address;
    state.userFullName = payload.full_name;
    state.userSsoId = payload.user_sso_id;
    state.userInfo.roles = payload.roles;
    state.userInfo.chameleonId = payload.chameleon_token;
  },

  setUserRoles(state, payload) {
    state.userInfo.roles = payload.roles;
    state.userEmail = payload.email_address;
    state.userInfo.chameleonId = payload.chameleon_token;
    state.userSsoId = payload.sso_id;
  },
  setRestrictPageAccess(state, payload) {
    state.restrictPageAccess = payload;
  },

  setRestrictedPageAccessType(state, payload) {
    state.restrictedPageAccessType = payload;
  },
};
