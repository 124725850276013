<template>
  <div>
    <v-card
      height="85vh"
      width="75vw"
      v-model="isVisible"
      :md-close-on-esc="false"
      :md-click-outside-to-close="false"
      class="county-modal-dialog overflow-hidden"
      color="white"
      v-click-outside="onClickOutside"
    >
      <v-expand-transition>
        <v-alert
          text
          color="#ffe190"
          type="warning"
          v-model="clickHelperAlert"
          transition="expand-x-transition"
          border="left"
          icon="mdi-alert"
          class="m-2"
        >
          Click the green check mark button at the top of the map to select this location.
        </v-alert>
      </v-expand-transition>
      <div class="area-container mb-2">
        <div class="col-2 flex flex-column no-overflow overflow-hidden">
          <h3 class="text-center">Areas</h3>

          <ul class="area-list">
            <p
              class="text-center"
              v-if="!selectedAreas.size"
            >
              Search to add areas.
            </p>

            <li
              v-for="area in selectedAreasList"
              :key="area.display_name"
              class="v-card md-elevation-1"
            >
              <p class="no-margin">{{area.display_name}}</p>

              <small>{{area.radius}} Mile Radius</small>

              <div class="divider"></div>
              <div class="">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="removeArea(area)"
                      :disabled="isEditingArea"
                      icon
                    >
                      <v-icon
                        color="error"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        class="icon-button"
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="editArea(area)"
                      :disabled="isEditingArea"
                      icon
                    >
                      <v-icon
                        color="grey darken-3"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        class="icon-button"
                      >
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="jumpToArea(area)"
                      :disabled="isEditingArea"
                      icon
                    >
                      <v-icon
                        color="grey darken-3"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-map-search
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Show on Map</span>
                </v-tooltip>
              </div>
              <v-divider></v-divider>
            </li>
          </ul>
        </div>

        <div class="col-10 flex h-100">
          <div class="map-container">
            <!-- MapControl and GeocoderControl inject into the div below -->
            <div id="map-county"
                 :class="{'danger-close-button': searchedArea.isEditing || editingArea.isEditing }">
            </div>
            <div id="top-searchbar">
              <div id="radius-selector">
                <input
                  id="radius-input"
                  type="number"
                  min="10" max="100"
                  v-model.number="radius"
                  aria-label="radius"
                />

                <span style="color: rgba(127,127,127, 0.7);">miles from</span>
              </div>
            </div>
            <div id="top-button-bar" :class="{'hidden': determineCheckMarkButtonHidden }">
              <v-btn
                class="md-icon-button"
                style="color: #70a300" @click="acceptChanges"
              >
                <v-icon class="icon" style="color: rgb(130, 188, 0)">mdi-check</v-icon>
              </v-btn>
            </div>

          </div>
        </div>
      </div>

      <div class="info-container" v-if="!editingArea.isEditing && !searchedArea.isEditing">
        <h3 class="info-heading">{{ getUniqueCountyCount }} Unique Counties Selected</h3>
        <div class="county-count-wrapper" v-for="area in selectedAreasList" :key="area.id">
          <h6>
            {{ area.display_name }} - {{ area.radius }} mile radius:
            <b> {{ area.counties.length }} counties selected</b>
          </h6>
          <div class="country-list">
            <PillButton
              v-for="county in area.counties"
              :key="county.fullName"
              :border="true"
              borderColor="#929292"
              action="removePill"
              @removePill="handleCountyChipRemove(county)"
              :closeButton="false"
              fontSize="12"
              textColor="#44444"
              :fontFamily="'Roboto'"
              borderWidth="1px"
              :height="'32px'"
              :text="county.fullName"
              :paddingRight="'10px'"
              :width="'fit-content'"
            ></PillButton>
          </div>
        </div>
      </div>

      <div class="info-editing-container" v-else>
        <div class="county-count-wrapper">
          <h6>
            {{
            editingArea.name || searchedArea.name
            }} - {{
            editingArea.radius || searchedArea.radius
            }} mile radius:
            <b> {{
              isEditingArea ? editingArea.counties.length : searchedArea.counties.length
              }} counties selected</b>
          </h6>
          <div class="country-list">
            <PillButton
              v-for="county in selectedCounties"
              :key="county.fips"
              :border="true"
              borderColor="#929292"
              fontSize="12"
              textColor="#44444"
              :fontFamily="'Roboto'"
              borderWidth="1px"
              :action="'removePill'"
              :height="'32px'"
              :text="county.fullName"
              :width="'fit-content'"
              @removePill="handleCountyChipRemove(county)"
            ></PillButton>
          </div>
        </div>
      </div>

      <div style="height: 15%;">
        <v-col>
          <v-btn
            color="red lighten-2"
            dark
            class="md-raised float-left"
            @click="$emit('close')"
          >
            Cancel
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            class="md-raised float-right green-button"
            :class="{'grey-button': selectedAreasList.length === 0}"
            @click="closeAndAccept"
            :disabled="selectedAreasList.length === 0"
          >Accept</v-btn>
        </v-col>
      </div>
      <div>
        <v-card
          class="escape-modal"
          :md-active.sync="escapeButton"
          @keydown.esc="closeSecondaryDialog"
          :md-close-on-esc="false"
          :md-click-outside-to-close="false"
        >
          <v-card-title class="dialog-title">Are you sure you want to exit?</v-card-title>
          <v-card-text>
            <div class="dialog-content">Your selected location will be lost if you exit.</div>
          </v-card-text>

          <v-card-actions class="dialog-actions-wrapper">
            <v-btn class="md-raised" @click="$emit('close')" :disabled="isEditingArea"
            >Close</v-btn
            >
            <v-btn
              class="md-raised green-button"
              @click="closeSecondaryDialog"
              :disabled="isEditingArea"
            >
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<script>
import polylabel from 'polylabel';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as turf from '@turf/turf';
import { debounce } from 'lodash/function';
import { uniqBy } from 'lodash';
import { appendChildToParent, debounceLocalMethod } from '../../../helpers/dom-utilities';
import {
  createBuffer,
  createFeatureCollection,
  findLargestArea,
  createFeature,
  createPoint,
  getFeaturesInBuffer,
} from '../../../helpers/geo-utilities';
import { groupBy } from '../../../helpers/array-utilities';
import PillButton from '../PillButton.vue';

export default {
  name: 'CountyMapModal',
  components: { PillButton },
  data() {
    return {
      clickHelperAlert: false,
      clickOutside: false,
      isVisible: true,
      accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
      mapStyle: process.env.VUE_APP_MAPBOX_STYLE,
      defaultZoom: 7,
      defaultCenter: [-112.074036, 33.448376],
      selectedAreas: new Map(),
      mapControl: null,
      editingArea: {},
      searchedArea: {},
      geocoderControl: null,
      radius: 50,
      searchText: '',
      sourceLayerName: process.env.VUE_APP_MAPBOX_SOURCE_NAME_COUNTIES,
      sourceLayerURL: process.env.VUE_APP_MAPBOX_SOURCE_URL_COUNTIES,
      visibleFeatures: [],
      escapeButton: false,
    };
  },
  props: {
    defaultSelectedAreas: {
      type: Map,
      required: false,
      default: () => { },
    },
  },
  mounted() {
    mapboxgl.accessToken = this.accessToken;
    const map = new mapboxgl.Map({
      container: 'map-county',
      style: this.mapStyle,
      center: this.defaultCenter,
      zoom: this.defaultZoom, // starting zoom
    });
    this.mapControl = map;
    this.geocoderControl = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl,
      flyTo: false,
      marker: false,
      countries: 'us',
    });
    map.addControl(appendChildToParent('mapboxgl-ctrl mapboxgl-ctrl-group custom-controller', 'top-searchbar'), 'top-left');
    map.addControl(this.geocoderControl, 'top-left');
    this.geocoderControl.on('result', (e) => {
      this.handleSearch(e);
    });
    this.geocoderControl.on('clear', () => {
      this.cancelChanges();
    });
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');
    map.on('load', () => {
      map.addSource('counties', {
        type: 'vector',
        url: this.sourceLayerURL,
      });
      map.addLayer(
        {
          id: 'counties',
          type: 'fill',
          source: 'counties',
          'source-layer': this.sourceLayerName,
          paint: {
            'fill-opacity': 0,
          },
        },
      );
      map.addLayer({
        id: 'county-lines',
        type: 'line',
        source: 'counties',
        'source-layer': this.sourceLayerName,
        layout: {
          'line-join': 'miter',
          'line-cap': 'round',
        },
        paint: {
          'line-width': 0.1,
          'line-opacity': 0.25,
        },
      });
      map.addSource('county-labels', {
        type: 'geojson',
        data: createFeatureCollection([]),
      });
      map.addSource('search-radius', {
        type: 'geojson',
        data: createFeatureCollection([]),
      });
      // renders the filtered counties as highlighted
      map.addLayer({
        id: 'counties-highlighted',
        type: 'fill',
        source: 'counties',
        'source-layer': this.sourceLayerName,
        paint: {
          'fill-outline-color': 'rgba(112, 163, 0, .5)',
          'fill-color': 'rgba(112, 163, 0, .5)', // #82bc00
          'fill-opacity': 0.65,
        },
        filter: ['in', 'GEOID', ''],
      });
      map.addLayer({
        id: 'search-radius',
        source: 'search-radius',
        type: 'fill',
        paint: {
          'fill-outline-color': 'rgb(125, 169, 185)',
          'fill-color': 'rgb(125, 169, 185)',
          'fill-opacity': 0.25,
        },
      });
    });
    // For initial page load
    map.once('idle', () => {
      map.addLayer({
        id: 'county-labels',
        type: 'symbol',
        source: 'county-labels',
        layout: {
          'text-field': [
            'format',
            ['get', 'description'],
            { 'font-scale': 0.8 },
          ],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-justify': 'center',
        },
      });
      this.setCountyLabels();
      // eslint-disable-next-line max-len
      if (this.defaultSelectedAreas && this.defaultSelectedAreas.entries().next().value && this.defaultSelectedAreas.entries().next().value.length > 0) {
        this.selectedAreas = new Map(this.defaultSelectedAreas);
      }
    });
    map.on('zoomend', () => {
      this.updateVisibleFeatures();
      this.setCountyLabels();
    });
    map.on('click', (e) => {
      if (this.isEditingArea || this.isEditingSearchedArea) {
        this.onCountySelected(e);
      }
    });
  },
  methods: {
    onClickOutside() {
      if (!this.determineCheckMarkButtonHidden) {
        this.clickHelperAlert = true;
      }
    },
    handleCountyChipRemove(county) {
      if (this.editingArea.counties) {
        // eslint-disable-next-line max-len
        this.editingArea.counties = this.editingArea.counties.filter((item) => item.id !== county.id);
      } else {
        // eslint-disable-next-line max-len
        this.searchedArea.counties = this.searchedArea.counties.filter((item) => item.id !== county.id);
      }
    },
    closeModal() {
      this.escapeButton = false;
      this.$emit('close');
    },
    closeSecondaryDialog() {
      this.escapeButton = false;
      debounceLocalMethod(this.focusDialogScreen, 400);
    },
    focusDialogScreen() {
      document.getElementsByClassName('md-dialog-fullscreen')[0].focus();
    },
    handleEscapeButtonPress() {
      if (
      // eslint-disable-next-line no-underscore-dangle, max-len
        this.selectedAreas.size === 0 && (document.activeElement !== this.geocoderControl._inputEl)) {
        this.$emit('close');
        // eslint-disable-next-line no-underscore-dangle
      } else {
        this.escapeButton = true;
      }
    },
    setCountyLabels() {
      if (this.mapControl.getZoom() < 6) {
        // Once the user zooms out enough stop showing the labels
        this.mapControl.getSource('county-labels')
          .setData(createFeatureCollection([]));
        return;
      }
      const counties = [
        ...groupBy(this.mapControl.queryRenderedFeatures(null, { layers: ['county-lines'] }), (c) => c.properties.GEOID).values(),
      ].map((grp) => ({
        ...grp[0],
        center: findLargestArea(grp).coordinates,
      }));
      const data = createFeatureCollection(counties.map(
        (county) => createFeature(
          createPoint(polylabel(county.center, 1)),
          { description: county.properties.NAME },
        ),
      ));
      this.mapControl.getSource('county-labels').setData(data);
    },
    onCountySelected(e) {
      this.updateVisibleFeatures();
      // Set `bbox` as 5px reactangle area around clicked point.
      const bbox = new mapboxgl.Point(
        e.point.x,
        e.point.y,
      );
      // get the selected counties at the clicked point
      const selectedFeatures = this.mapControl.queryRenderedFeatures(bbox, {
        layers: ['counties'],
      });
      const allCountyFIPS = this.selectedCounties.map((c) => c.fips);
      // filter out any counties that are included in another area
      const selectedFeatureFIPS = Number.parseInt(selectedFeatures.find((sf) => sf.source === 'counties').properties.GEOID, 10);
      // eslint-disable-next-line max-len
      const selectedCountyFIPS = structuredClone(this.visibleFeatures.find((f) => f.fips === selectedFeatureFIPS)).fips;
      // determine which counties were added or removed
      // eslint-disable-next-line max-len
      const addedCounties = [selectedCountyFIPS].filter((i) => !allCountyFIPS.includes(i)).map((fips) => this.visibleFeatures.find((f) => f.fips === fips));
      const removedFIPS = allCountyFIPS.filter((fips) => fips === selectedCountyFIPS);
      // set the removed or added counties
      if (this.isEditingArea) {
        this.editingArea.counties = [
          ...this.editingArea.counties.filter((f) => !removedFIPS.includes(f.fips)),
          ...addedCounties,
        ];
      } else {
        this.searchedArea.counties = [
          ...this.searchedArea.counties.filter((f) => !removedFIPS.includes(f.fips)),
          ...addedCounties,
        ];
      }
    },
    updateVisibleFeatures() {
      const featureConverter = (data, coords) => {
        const core = {
          id: data.id,
          fips: +data.properties.GEOID,
          name: data.properties.NAME,
          fullName: `${data.properties.NAMELSAD}, ${data.properties.STUSPS}`,
          properties: {
            ...data.properties,
            GEOID: +data.properties.GEOID,
          },
          geometry: data.geometry,
        };
        if (Array.isArray(coords[0])) {
          return coords[0].map((childCoords) => featureConverter(data, childCoords)).flat();
        }
        return {
          ...core,
          coords,
        };
      };
      this.visibleFeatures = this.mapControl
        .queryRenderedFeatures(null, { layers: ['counties'] })
        .map((data) => featureConverter(data, data.geometry.coordinates))
        .flat();
    },
    removeArea(area) {
      this.$emit('area-removed', area.id);
      this.selectedAreas.delete(area.id);
      this.selectedAreas = new Map(this.selectedAreas);
    },
    async editArea(area) {
      this.setAndFocusInput(area);
      await this.jumpToArea(area);
      this.editingArea = {
        ...area,
        isEditing: true,
        initialSearch: false,
      };
      this.selectedAreas = new Map(this.selectedAreas.set(area.id, {
        ...area,
        isEditing: true,
      }));
      this.radius = area.radius;
    },
    jumpToArea(area) {
      return this.jumpToCoordinates(area.coordinates, area.radius);
    },
    jumpToCoordinates(coordinates, radius) {
      const searchRadius = createBuffer(coordinates, radius);
      return this.jumpToBounds(turf.envelope(searchRadius.geometry).bbox);
    },
    jumpToBounds(bounds) {
      return new Promise((resolve) => {
        this.mapControl.once('moveend', () => resolve());
        this.mapControl.fitBounds(bounds, { padding: 20 });
      });
    },
    async handleSearch(event) {
      this.searchText = event.result.place_name;
      const coords = event.result.center;
      const searchMiles = Math.min(100, Math.max(10, this.radius));
      const buffer = createBuffer(coords, searchMiles);
      await this.jumpToCoordinates(coords, searchMiles);
      // filter out any counties that are included in another area
      const counties = this.getCountiesWithin(buffer);
      if (Object.keys(this.editingArea).length !== 0) {
        const editingAreaId = this.editingArea.id;
        this.editingArea = {
          counties,
          name: event.result.place_name,
          radius: this.radius,
          isEditing: true,
          coordinates: coords,
          id: event.result.id,
        };
        if (editingAreaId && (editingAreaId !== event.result.id)) {
          this.editingArea.previousId = editingAreaId;
        }
      } else {
        this.searchedArea = {
          counties,
          name: event.result.place_name,
          radius: this.radius,
          isEditing: true,
          coordinates: coords,
          id: event.result.id,
        };
      }
      this.updateSearchRadii();
      this.updateHighlightedCounties();
    },
    getCountiesWithin(buffer) {
      return [
        ...groupBy(getFeaturesInBuffer(this.visibleFeatures, buffer), (f) => f.fips).values(),
      ].map((grp) => grp[0]);
    },
    acceptChanges() {
      if (this.isEditingArea) {
        const area = {
          ...this.editingArea,
          radius: this.radius,
          isInitial: false,
          isEditing: false,
          display_name: this.editingArea.name.substring(0, this.editingArea.name.indexOf(', United States')),
        };
        if (this.editingArea.previousId) {
          this.selectedAreas.delete(this.editingArea.previousId);
          delete area.previousId;
        }
        this.selectedAreas = new Map(this.selectedAreas.set(area.id, {
          ...area,
        }));
        this.editingArea = {};
      } else {
        this.selectedAreas = new Map(
          this.selectedAreas.set(
            this.searchedArea.id,
            {
              ...this.searchedArea,
              display_name: this.searchedArea.name.substring(0, this.searchedArea.name.indexOf(', United States')),
              isEditing: false,
              isInitial: false,
            },
          ),
        );
        this.searchedArea = {};
      }
      this.geocoderControl.clear();
      this.searchText = '';
      this.clickHelperAlert = false;
    },
    cancelChanges() {
      this.clearSearchRadii();
      this.updateHighlightedCounties();
      this.searchedArea = {};
      this.editingArea = {};
      this.searchText = '';
      this.clickHelperAlert = false;
    },
    clearSearchRadii() {
      this.mapControl.getSource('search-radius').setData(createFeatureCollection([]));
    },
    updateSearchRadii() {
      let areas;
      let radii;
      if (this.isEditingArea) {
        areas = Array.from(this.selectedAreas.values());
        radii = [...areas.map((a) => createBuffer(a.coordinates, a.radius))];
        radii.push(createBuffer(this.editingArea.coordinates, this.radius));
        this.mapControl.getSource('search-radius').setData(createFeatureCollection(radii));
      } else if (Object.keys(this.searchedArea).length > 0) {
        radii = [createBuffer(this.searchedArea.coordinates, this.searchedArea.radius)];
        this.mapControl.getSource('search-radius').setData(createFeatureCollection(radii));
      }
    },
    updateHighlightedCounties() {
      let counties = [...this.selectedCounties];
      if (this.editingArea.counties !== undefined) {
        counties = [...counties, ...this.editingArea.counties];
      } else if (Object.keys(this.searchedArea).length > 0) {
        counties = [...counties, ...this.searchedArea.counties];
      }
      // the FIPS data is a bit of a mess, so we supply:
      // the original (probably) numeric FIPS number
      // a 5 digit left padded FIPS string
      const fips = counties.map((f) => f.fips);
      const highlightedFIPS = [
        ...fips,
        ...fips.map((f) => f.toString().padStart(5, '0')),
      ];
      this.mapControl.setFilter('counties-highlighted', ['in', 'GEOID', ...highlightedFIPS]);
    },
    closeAndAccept() {
      // eslint-disable-next-line arrow-body-style
      const countyNames = this.selectedAreasList.map((selectedArea) => {
        return {
          name: `${selectedArea.radius} miles from ${selectedArea.display_name.split(',')}`,
          type: 'radiusFilter',
          display_name: selectedArea.display_name,
          fips: selectedArea.counties.map((sa) => ({ fips: sa.fips, name: sa.fullName })),
        };
      });
      this.$emit('accept', { countyNames, originalMapObject: this.selectedAreas });
    },
    async updateRadius() {
      // eslint-disable-next-line max-len
      const coords = this.isEditingArea ? this.editingArea.coordinates : this.searchedArea.coordinates;
      const searchMiles = Math.min(100, Math.max(10, this.radius));
      const buffer = createBuffer(coords, searchMiles);
      await this.jumpToCoordinates(coords, searchMiles);
      const counties = this.getCountiesWithin(buffer);
      if (this.isEditingArea) {
        this.editingArea = {
          ...this.editingArea,
          radius: this.radius,
          counties,
        };
      } else {
        this.searchedArea = {
          ...this.searchedArea,
          radius: this.radius,
          counties,
        };
      }
      this.updateSearchRadii();
    },
    setAndFocusInput(area) {
      // eslint-disable-next-line no-underscore-dangle
      this.geocoderControl._inputEl.value = area.display_name;
      document.getElementsByClassName('mapboxgl-ctrl-geocoder--input')[0].focus();
    },
  },
  computed: {
    determineCheckMarkButtonHidden() {
      // eslint-disable-next-line max-len
      return Object.keys(this.editingArea).length === 0 && Object.keys(this.searchedArea).length === 0;
    },
    getUniqueCountyCount() {
      return uniqBy(this.selectedCounties, 'fullName').length;
    },
    isEditingArea() {
      return this.editingArea?.isEditing;
    },
    markers() {
      let markers = this.selectedAreasList.map((area) => ({
        title: area.name,
        coordinates: area.coordinates,
        visible: !area.isEditing,
      }));
      if (this.isEditingArea) {
        markers.push({
          title: this.editingArea.name,
          coordinates: this.editingArea.coordinates,
          visible: true,
        });
      }
      markers = uniqBy(markers, 'title');
      return markers;
    },
    selectedCounties() {
      const response = [];
      if (this.selectedAreasList.length > 0) {
        response.push(this.selectedAreasList
          .filter((area) => !area.isEditing)
          .map((area) => area.counties)
          .flat());
      }
      if (Object.keys(this.editingArea).length > 0) {
        // eslint-disable-next-line max-len
        response.push(this.editingArea.counties.flat().sort((a, b) => a.name.localeCompare(b.name)));
      }
      if (Object.keys(this.searchedArea).length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        response.push(this.searchedArea.counties.sort((a, b) => a.name.localeCompare(b.name)));
      }
      return response.flat();
    },
    selectedAreasList() {
      return Array.from(this.selectedAreas.values());
    },
    isEditingSearchedArea() {
      return this.searchedArea.isEditing;
    },
  },
  watch: {
    isEditingArea(value) {
      this.mapControl.getCanvas().style.cursor = value ? 'pointer' : 'grab';
      if (value) {
        this.mapControl.addControl(appendChildToParent('mapboxgl-ctrl mapboxgl-ctrl-group custom-controller', 'top-button-bar'), 'top-left');
      }
    },
    isEditingSearchedArea(value) {
      this.mapControl.getCanvas().style.cursor = value ? 'pointer' : 'grab';
      if (value) {
        this.mapControl.addControl(appendChildToParent('mapboxgl-ctrl mapboxgl-ctrl-group custom-controller', 'top-button-bar'), 'top-left');
      }
    },
    selectedAreas() {
      this.updateSearchRadii();
    },
    radius() {
      if (this.radius !== '' && this.radius !== '0' && (this.isEditingArea || this.isEditingSearchedArea)) {
        const debounceUpdateRadius = debounce(this.updateRadius, 1500);
        debounceUpdateRadius();
      }
    },
    defaultSelectedAreas: {
      deep: true,
      handler() {
        // eslint-disable-next-line max-len
        if (this.defaultSelectedAreas && this.defaultSelectedAreas.entries().next().value.length > 0) {
          this.selectedAreas = new Map(this.defaultSelectedAreas);
        }
      },
    },
    selectedCounties() {
      if (Object.keys(this.searchedArea).length > 0 || Object.keys(this.editingArea).length > 0) {
        this.updateHighlightedCounties();
      }
    },
  },
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 100%;
}

.map-container,
.mapboxgl-map {
  height: 100%;
}

.map-container {
  flex: 1;
  position: relative;
}

.area-container {
  height: 55%;
  display: flex;
}

.info-container {
  overflow-y: auto;
  padding: 8px 20px 20px 12px;
  height: 30%;
}

.info-editing-container {
  padding: 8px 20px 20px 12px;
  height: 35%;
  overflow-y: scroll;
}

.county-count-wrapper {
  text-align: left;
  margin-left: 2%;
  padding: 12px 0;
  border-bottom: 2px solid #e6e6e6;
}
.grey-button {
  background-color: grey !important;
}
.county-count-wrapper:first-of-type {
  padding-top: 0;
}
.county-count-wrapper:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}

.county-count-wrapper .country-list {
  padding-top: 2px;
  overflow-y: hidden;
  display: flex;
  flex-wrap: wrap;
}

.info-container .info-heading {
  text-align: left;
  margin-left: 2%;
}

.country-list .v-chip {
  background: rgba(0, 0, 0, 0.12);
  margin-bottom: 4px;
}

#top-button-bar {
  display: flex;
  margin-left: 16px;

  box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 4px;
  width: 52px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

#radius-selector {
  display: flex;
  align-items: center;
  flex: 0 0 auto;

  padding: 8px;
  margin-right: 16px;

  box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 4px;
  height: 40px;
}

#radius-selector input {
  border: none;

  margin-left: 4px;
  margin-right: 4px;

  text-align: center;
  font-size: 14px;
}

#radius-selector input:focus {
  outline: none;
}

#radius-selector input::-webkit-outer-spin-button,
#radius-selector input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

#radius-selector input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.area-list {
  overflow-y: auto;
  height: 100%;
  list-style: none;
  padding-left: 0;
}

.area-card {
  padding-top: 8px;
  border-radius: 2px;
  margin: 4px;
}

.add-area-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
}

.icon-button {
  min-width: 0;
}

/* Helper classes */

.danger {
  color: #dc3545;
}

.no-margin {
  margin: 0;
}

.text-center {
  text-align: center;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.no-overflow {
  overflow: hidden;
}

.action-row button {
  margin: 0 15px;
}

.content-between {
  justify-content: space-between;
}
</style>

<style>

.mapboxgl-canvas {
  height: 100% !important;
}

.hidden {
  display: none !important;
}

.custom-controller{
  background-color: transparent;
  box-shadow: none;
  border:none;
  height:10px;
}

.custom-controller.mapboxgl-ctrl-group:not(:empty){
  box-shadow: none;
  height:10px;
}

.mapboxgl-ctrl-group{
  margin-left: 50%;
}

.mapboxgl-ctrl-top-right {
  display: flex;
}

.mapboxgl-ctrl-top-left {
  display: flex;
  justify-content: center;
  width: 100%;
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl {
  margin-left: 0;
  margin-right: 0;
}

.county-modal-dialog .md-dialog-container {
  min-width: 800px !important;
  height: 75vh;
  width: 75vw;
  overflow-y: auto;
  display: flex;
  justify-content: start;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  transition: .35s cubic-bezier(.4,0,.2,1);
  transition-property: opacity;
  will-change: opacity;
}

.mapboxgl-ctrl-geocoder {
  height: 40px;
}

@media screen and (min-width: 640px) {
  .mapboxgl-ctrl-geocoder--icon-search {
    top: 10px;
  }
}

.mapboxgl-ctrl-geocoder--input {
  height: 40px;
}

.danger-close-button .mapboxgl-ctrl-geocoder--button {
  display: block !important;
}

.danger-close-button .mapboxgl-ctrl-geocoder--button .mapboxgl-ctrl-geocoder--icon-close {
  fill: #dc3545;
}

.escape-modal {
  z-index: 20;
}

.escape-modal + .md-overlay.md-dialog-overlay {
  z-index: 19;
}
.escape-modal .md-dialog-container {
  background: #fff;
  min-width: 20vw !important;
  height: 170px;
}
.escape-modal .dialog-title,
.escape-modal .dialog-content {
  color: #000;
}
.escape-modal .dialog-actions-wrapper {
  justify-content: center;
}
.green-button {
  background-color: #70A300 !important;
}
</style>
