<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <label for="start">Date (From)</label>
      <v-text-field
        id="start"
        :value="date[1]"
        placeholder="MM/DD/YYYY"
        append-icon="mdi-calendar-week"
        readonly
        v-bind="attrs"
        v-on="on"
        @click:append="menu = true"
        class="p-0"
      >
      </v-text-field>
      <label for="end">Date (To)</label>
      <v-text-field
        id="end"
        :value="date[0]"
        placeholder="MM/DD/YYYY"
        append-icon="mdi-calendar-week"
        readonly
        v-bind="attrs"
        v-on="on"
        @click:append="menu = true"
        class="p-0"
      >
      </v-text-field>
    </template>
    <v-card>
      <v-date-picker
        id="picker1"
        ref="picker1"
        v-model="datesModel"
        :events="events"
        :event-color="eventColor"
        :picker-date.sync="pickerPage1"
        multiple
        no-title
        color="#70a300"
        :min="removeTimeFromDate(new Date('01/01/2021'))"
      ></v-date-picker>
      <v-date-picker
        id="picker2"
        ref="picker2"
        v-model="datesModel"
        :events="events"
        :event-color="eventColor"
        :picker-date.sync="pickerPage2"
        multiple
        no-title
        color="#70a300"
        :max="removeTimeFromDate(new Date())"
      >
      </v-date-picker>
      <v-spacer></v-spacer>
      <v-col class="text-right">
        <v-btn
          text
          color="primary"
          @click="menu = false"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="confirmAndCloseDialog"
          :disabled="!valid"
        >
          Apply Dates
        </v-btn>
      </v-col>
    </v-card>

  </v-menu>
</template>
<script>
import {
  formatDate,
  formatToFirstOfMonth,
} from '../../helpers/date-utilities';

export default {
  name: 'PostingDateCalendar',
  props: {
    defaultRange: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      range: this.defaultRange || null,
      date: [],
      menu: false,
      vertical: false,
      events: [],
      eventColor: {},
      dateRange: [],
      dateRangePrevious: [],
      pickerPage1: null,
      pickerPage2: null,
      pickerPage1Adjusted: false,
      pickerPage2Adjusted: false,
      maxDate: String(new Date()),
      minDate: '01/01/2021',
    };
  },
  computed: {
    datesModel: {
      get() {
        if (!this.dateRange.length) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.dateRange = this.getDefaultDates();
          this.render();
        }
        return this.dateRange;
      },
      set(value) {
        if (value.length) {
          if (value.length === 2) {
            this.dateRange = value;
          } else {
            let difference = [];
            if (value.length === 1) {
              difference = this.arrayDifference(this.dateRangePrevious, value);
            } else {
              difference = this.arrayDifference(value, this.dateRange);
            }
            // eslint-disable-next-line max-len
            if (difference.length) {
              this.dateRange = difference;
            }
          }
        }
        this.render();
        this.dateRangePrevious = this.dateRange;
      },
    },
    valid() {
      return this.datesModel.length === 2;
    },
  },
  methods: {
    clearCalendarRange() {
      this.date = [];
    },
    confirmAndCloseDialog() {
      this.$refs.menu.save(this.datesModel);
      const sortedDates = this.datesModel.sort();
      this.date[0] = formatToFirstOfMonth(sortedDates[0], 'MM/YYYY');
      this.date[1] = formatToFirstOfMonth(sortedDates[1], 'MM/YYYY');
      this.$emit('setDateRange', sortedDates);
    },
    render() {
      this.events = this.getDateRange(this.datesModel);
      this.eventColor = this.buildEventColors(this.events);
    },
    addDaysToDate(date, days) {
      const d = new Date(date);
      d.setDate(d.getDate() + days);
      return d;
    },
    removeTimeFromDate(date) {
      return date.toISOString().split('T')[0];
    },
    buildEventColors(range) {
      const colors = {};
      for (let i = 0; i < range.length; i += 1) {
        colors[range[i]] = 'green lighten-4 v-date-picker-table__event';
        if (i === 0) {
          colors[range[i]] += ' v-date-picker-table__event--start';
        }
        if (i === range.length - 1) {
          colors[range[i]] += ' v-date-picker-table__event--end';
        }
      }
      return colors;
    },
    getDateRange(dates) {
      const date1 = new Date(dates[0]);
      const date2 = new Date(dates[dates.length - 1]);
      const cur = new Date(Math.min(date1, date2));
      const range = [];
      while (cur <= Math.max(date1, date2)) {
        range.push(this.removeTimeFromDate(new Date(cur)));
        cur.setDate(cur.getDate() + 1);
      }
      return range;
    },
    getDefaultDates() {
      const today = new Date();
      return [
        this.removeTimeFromDate(today),
        this.removeTimeFromDate(this.addDaysToDate(today, -7)),
      ];
    },
    setDefaultDates() {
      this.dateRange = this.getDefaultDates();
      this.render();
      this.bringDateIntoView();
    },
    clearDates() {
      this.dateRange = [];
    },
    arrayDifference(array1, array2) {
      // eslint-disable-next-line prefer-arrow-callback
      return array1.filter(function (i) {
        return array2.indexOf(i) < 0;
      });
    },
    parsePickerPage(date) {
      const dateParts = date.split('-');
      return {
        year: parseInt(dateParts[0], 10),
        month: parseInt(dateParts[1], 10),
      };
    },
    increasePickerPage(pickerPage) {
      const picker = { ...pickerPage };
      if (picker.month === 12) {
        picker.year += 1;
        picker.month = 1;
      } else {
        picker.month += 1;
      }
      return picker;
    },
    decreasePickerPage(pickerPage) {
      const picker = { ...pickerPage };
      if (picker.month === 1) {
        picker.year -= 1;
        picker.month = 12;
      } else {
        picker.month -= 1;
      }

      return picker;
    },
    pickerPageToString(pickerPage) {
      const picker = { ...pickerPage };
      if (picker.month < 10) {
        picker.month = `0${picker.month.toString()}`;
      } else {
        picker.month = picker.month.toString();
      }
      return `${picker.year.toString()} - ${picker.month}`;
    },
    bringDateIntoView() {
      if (this.dateRange.length > 0) {
        this.pickerPage1 = this.pickerPageToString(this.parsePickerPage(this.dateRange[0]));
      }
    },
  },
  watch: {
    pickerPage1(value) {
      if (!this.pickerPage1Adjusted) {
        this.pickerPage2Adjusted = true;
        // eslint-disable-next-line max-len
        this.pickerPage2 = this.pickerPageToString(this.increasePickerPage(this.parsePickerPage(value)));
      }
      this.pickerPage1Adjusted = false;
    },
    pickerPage2(value) {
      if (!this.pickerPage2Adjusted) {
        this.pickerPage1Adjusted = true;
        // eslint-disable-next-line max-len
        this.pickerPage1 = this.pickerPageToString(this.decreasePickerPage(this.parsePickerPage(value)));
      }
      this.pickerPage2Adjusted = false;
    },
    defaultRange() {
      if (typeof (this.defaultRange.start) === 'string') {
        this.date = [this.defaultRange.start, this.defaultRange.end];
        return;
      }

      this.date = [formatDate(this.defaultRange.start, 'YYYY-MM-DD'), formatDate(this.defaultRange.end, 'YYYY-MM-DD')];
    },
  },
};
</script>
