export default function identifyVisitor(userObject) {
  // Add data to contact us modal
  const parentElement = document.getElementById('parentDiv');
  if (parentElement) {
    const nameField = parentElement.querySelector('input#contact_name');
    const emailField = parentElement.querySelector('input#contact_email');
    if (nameField) {
      parentElement.querySelector('input#contact_name').value = userObject.userEmail;
    }
    if (emailField) {
      parentElement.querySelector('input#contact_email').value = userObject.userEmail;
    }
  } else {
    const contactUsScript = document.getElementById('contact_us_script');
    // Set values for data-username and data-email attributes
    if (contactUsScript) {
      contactUsScript.setAttribute('data-username', userObject.userEmail);
      contactUsScript.setAttribute('data-email', userObject.userEmail);
    }
  }
}
