export default {
  getCompanies(state) {
    return state.companies;
  },
  getHasMoreCompanies(state) {
    return state.hasMoreCompanies;
  },
  getJobPostingsAndUniqueCompaniesData(state) {
    return state.jobPostingsAndUniqueCompaniesData;
  },
  getMedianAdvertisedTimeframeWageData(state) {
    return state.medianAdvertisedTimeframeWageData;
  },
  getInputtedCompanyDistributionStatistics(state) {
    return state.inputtedCompanyDistributionStatistics;
  },
  getInputtedYearlyWageRange(state) {
    return state.inputtedYearlyWageRange;
  },
  getInputtedCompanyDistributionStatisticsLoading(state) {
    return state.inputtedCompanyDistributionStatisticsLoading;
  },
  getInputtedWeeklyWageRange(state) {
    return state.inputtedWeeklyWageRange;
  },
  getGeographicalChartData(state) {
    return state.geographicalChartData;
  },
  getInputtedHourlyWageRange(state) {
    return state.inputtedHourlyWageRange;
  },
  getInputtedCPMWageRange(state) {
    return state.inputtedCPMWageRange;
  },
  getPostingsByCompanyLoading(state) {
    return state.postingsByCompanyLoading;
  },
  getPostingsByCompanyPresence(state) {
    return state.postingsByCompanyPresence;
  },
  getCompanyName(state) {
    return state.inputtedCompanyName;
  },
  getHometimeBenefitData(state) {
    return state.hometimeBenefitData;
  },
  getHometimeBenefitDataLoading(state) {
    return state.hometimeBenefitDataLoading;
  },
  getHometimeBenefitDataPresence(state) {
    return state.hometimeBenefitDataPresence;
  },
  getBonusData(state) {
    return state.bonusData;
  },
  getBonusDataLoading(state) {
    return state.bonusDataLoading;
  },
  getBonusDataPresence(state) {
    return state.bonusDataPresence;
  },
  getPostingsByCompany(state) {
    return state.postingsByCompany;
  },
  getSelectedDistributionWageRanges(state) {
    return state.selectedDistributionWageRanges;
  },
  getMinimumLoadingTimeAchieved(state) {
    return state.minimumLoadingTimeAchieved;
  },
  getJobPostingsMapLoading(state) {
    return state.jobPostingsMapLoading;
  },
  getGeographicalData(state) {
    return state.geographicalData;
  },
  getSelectedDistributionWageRangesLoading(state) {
    return state.selectedDistributionWageRangesLoading;
  },
  getSelectedDistributionWageRangesUsed(state) {
    return state.selectedDistributionWageRangesUsed;
  },
  getAdvertisedPayData(state) {
    return state.advertisedPayData;
  },
  getAdvertisedWageRanges(state) {
    return state.advertisedWageRanges;
  },
  getAdvertisedWageRangesLoading(state) {
    return state.advertisedWageRangesLoading;
  },
  getAdvertisedSalaryDistributionLoading(state) {
    return state.advertisedSalaryDistributionLoading;
  },
  getAdvertisedSalaryDistribution(state) {
    return state.advertisedSalaryDistribution;
  },
  getWageSumForInputtedData(state) {
    return state.advertisedSalaryDistribution.wage_sum;
  },
  getAdvertisedSalaryDistributionPresence(state) {
    return state.advertisedSalaryDistributionPresence;
  },
  getAdvertisedWageRangePresence(state) {
    return state.advertisedWageRangePresence;
  },
  getBannerJobPostingsAndUniqueCompaniesLoading(state) {
    return state.fetchBannerJobPostingsAndDistinctCompaniesDataLoading;
  },
  getBannerMedianAdvertisedWageLoading(state) {
    return state.fetchBannerMedianAdvertisedTimeframeWageLoading;
  },
  getUserChartDisplayState(state) {
    return state.showUserWageChart;
  },
  getHometimePostingBenefitsDetailsData(state) {
    return state.hometimePostingBenefitsDetailsData;
  },
  getHometimePostingBenefitsDetailsDataLoading(state) {
    return state.hometimePostingBenefitsDetailsDataLoading;
  },
};
