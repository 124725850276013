<template>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="dates"
      transition="scale-transition"
      @input="handleClose"
      :max-width="300"
      content-class="trends-month-range-picker"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="monthsText"
          label="Date Range"
          readonly
          v-bind="attrs"
          v-on="on"
          append-icon="arrow_drop_down"
        ></v-text-field>
      </template>
      <div v-if="menu" v-click-outside="cancelAndCloseDialog">
        <v-list class="timeframe-options-dropdown box">
          <v-list-item
            style="border-bottom: none !important"
            :class="{ selectedOption: selectedOption === 'prior 3' ? true : false }"
            @click.native="selectOption('prior 3')"
          >
            <v-list-item-title>Prior 3 months</v-list-item-title>
          </v-list-item>
          <v-list-item
            style="border-bottom: none !important"
            :class="{ selectedOption: selectedOption === 'prior 6' ? true : false }"
            @click.native="selectOption('prior 6')"
          >
            <v-list-item-title>Prior 6 months</v-list-item-title>
          </v-list-item>

          <v-list-item
            style="border-bottom: none !important"
            :class="{ selectedOption: selectedOption === 'prior 12' ? true : false }"
            @click.native="selectOption('prior 12')"
          >
            <v-list-item-title>Prior 12 months</v-list-item-title>
          </v-list-item>
          <v-list-item @click.native="selectedOption = null" style="border-bottom: none !important">
            <v-list-item-content class="custom-date-option">
              <v-list-item-title>Custom</v-list-item-title>
              <div class="custom-date-input-wrapper">
                <div :class="{ formdate: 'formdate', invalid: invalidFormat1 }">
                  <input
                    v-model="startingMonth"
                    class="formdate-input formdate-input-month"
                    type="number"
                    placeholder="mm"
                    @blur="setStartingMonthFilterSelected"
                    aria-labelledby="From Month Input"
                  />
                  <span class="formdate-divider">/</span>
                  <input
                    v-model="startingYear"
                    class="formdate-input formdate-input-year"
                    type="number"
                    placeholder="yyyy"
                    @blur="setStartingYearFilterSelected"
                    aria-labelledby="From Year Input"
                  />
                </div>
                <span class="dash" />
                <div
                  :class="{
                    formdate: 'formdate',
                    invalid: invalidFormat2,
                    disable: startingMonth !== '' && startingYear !== '' ? false : true,
                  }"
                >
                  <input
                    v-model="endingMonth"
                    class="formdate-input formdate-input-month"
                    type="number"
                    placeholder="mm"
                    @blur="setEndingMonthFilterSelected"
                    :disabled="startingMonth !== '' && startingYear !== '' ? false : true"
                    aria-labelledby="To Month Input"
                  />
                  <span class="formdate-divider">/</span>
                  <input
                    v-model="endingYear"
                    class="formdate-input formdate-input-year"
                    type="number"
                    placeholder="yyyy"
                    @blur="setEndingYearFilterSelected"
                    :disabled="
                      startingMonth !== '' && startingYear !== '' && endingMonth !== ''
                        ? false
                        : true
                    "
                    aria-labelledby="To Year Input"
                  />
                </div>
              </div>
              <p v-if="invalidationMessageMonth !== ''" class="invalidation-message">
                {{ invalidationMessageMonth }}
              </p>
              <p v-if="invalidationMessageYear !== ''" class="invalidation-message">
                {{ invalidationMessageYear }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-date-picker
          v-model="dates"
          scrollable
          range
          no-title
          type="month"
          color="primary"
          :max="removeTimeFromDate(new Date())"
          @change="updateCustomFields"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="cancelAndCloseDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="confirmAndCloseDialog"
            :disabled="isButtonDisabled"
            elevation="0"
            :class="{
              shake: clickOutside,
            }"
          >
            Apply Dates
          </v-btn>
        </v-date-picker>
      </div>

    </v-menu>
</template>

<script>
import {
  formatDate,
  getMonthFromDate,
  getYearFromDate,
  formatToFirstOfMonth,
  getFormattedDateFromYearMonth,
} from '../../helpers/date-utilities';

export default {
  name: 'MonthRangeCalendar',
  data() {
    return {
      clickOutside: false,
      startingYear: '',
      endingYear: '',
      invalidFormat1: false,
      invalidFormat2: false,
      invalidationMessageYear: '',
      invalidationMessageMonth: '',
      dates: [formatDate(new Date(), 'YYYY/MM', { subtractYears: 1 }), formatDate(new Date(), 'YYYY/MM')],
      picker: [`${formatDate(new Date(), 'MM/YYYY', { subtractYears: 1 })} - ${formatDate(new Date(), 'MM/YYYY')}`],
      startingMonth: '',
      endingMonth: '',
      months: [],
      menu: false,
      monthsText: '',
      selectedOption: null,
      yearOptions: [],
      errors: false,
      priorOptions: ['prior 3, prior 6, prior 12'],
      previousRanOption: null,
    };
  },
  mounted() {
    this.monthsText = this.picker;
    this.updateCustomFields();
  },
  methods: {
    updateCustomFields() {
      this.dates.sort();
      this.startingMonth = this.dates[0].slice(-2);
      this.startingYear = this.dates[0].slice(0, 4);
      this.endingMonth = this.dates[1].slice(-2);
      this.endingYear = this.dates[1].slice(0, 4);
    },
    setStartingYearFilterSelected(value, fromWhere) {
      if (fromWhere === 'fromPicker') {
        this.startingYear = value;
      } else if (!this.invalidFormat1) {
        if (this.startingYear !== '') this.$emit('startingYearSelected', this.startingYear);
      }
    },
    setEndingYearFilterSelected(value, fromWhere) {
      if (fromWhere === 'fromPicker') {
        this.endingYear = value;
      } else if (!this.invalidFormat2) {
        if (this.endingYear !== '') this.$emit('endingYearSelected', this.endingYear);
      }
    },
    setStartingMonthFilterSelected(value, fromWhere) {
      if (fromWhere === 'fromPicker') {
        this.startingMonth = value;
      } else if (!this.invalidFormat1) {
        if (this.startingMonth !== '') this.$emit('startingMonthSelected', this.startingMonth);
      }
    },
    setEndingMonthFilterSelected(value, fromWhere) {
      if (fromWhere === 'fromPicker') {
        this.endingMonth = value;
      } else if (!this.invalidFormat2) {
        if (this.endingMonth !== '') this.$emit('endingMonthSelected', this.endingMonth);
      }
    },
    handleClose() {
      if (!this.menu) {
        this.cancelAndCloseDialog();
      }
    },
    selectOption(value) {
      const endingMonthDate = formatDate(new Date(), 'YYYY/MM/DD', { subtractMonths: 1 });
      let startMonthDate = '';
      this.selectedOption = value;
      if (value === 'prior 3') {
        startMonthDate = formatDate(new Date(), 'YYYY/MM/DD', { subtractMonths: 3 });
      } else if (value === 'prior 6') {
        startMonthDate = formatDate(new Date(), 'YYYY/MM/DD', { subtractMonths: 6 });
      } else if (value === 'prior 12') {
        startMonthDate = formatDate(new Date(), 'YYYY/MM/DD', { subtractMonths: 12 });
      }
      const startingYear = `${getYearFromDate(startMonthDate)}`;
      const startMonth = getMonthFromDate(startMonthDate);
      const startMonthWithPrefix = startMonth < 10 ? `0${startMonth}` : `${startMonth}`;
      const endingYear = `${getYearFromDate(endingMonthDate)}`;
      const endMonth = getMonthFromDate(endingMonthDate);
      const endMonthWithPrefix = endMonth < 10 ? `0${endMonth}` : `${endMonth}`;

      this.dates[0] = getFormattedDateFromYearMonth(startingYear, startMonth, 'YYYY-MM');
      this.dates[1] = getFormattedDateFromYearMonth(endingYear, endMonth, 'YYYY-MM');
      this.startingYear = startingYear;
      this.startingMonth = startMonthWithPrefix;
      this.endingYear = endingYear;
      this.endingMonth = endMonthWithPrefix;
    },
    cancelAndCloseDialog() {
      if (this.previousRanOption !== this.selectedOption) {
        this.selectedOption = this.previousRanOption;
      }
      this.menu = false;
      const dates = this.monthsText.split(' - ');
      this.startingMonth = dates[0].slice(0, 2);
      this.startingYear = dates[0].slice(3, 8);
      this.endingMonth = dates[1].slice(0, 2);
      this.endingYear = dates[1].slice(3, 8);
    },
    confirmAndCloseDialog() {
      this.$refs.menu.save(this.dates);
      const arr = [];
      const sortedMonths = this.dates.sort();
      const startDate = getFormattedDateFromYearMonth(sortedMonths[0].split('-')[0], sortedMonths[0].split('-')[1], 'MM/YYYY');
      const endDate = getFormattedDateFromYearMonth(sortedMonths[1].split('-')[0], sortedMonths[1].split('-')[1], 'MM/YYYY');

      this.monthsText = `${startDate} - ${endDate}`;

      sortedMonths.forEach((value) => {
        // Backend accepts: YYYY-MM-DD format
        const dateStr = formatToFirstOfMonth(value, 'YYYY-MM-DD');
        arr.push(`${dateStr}`);
      });

      this.$emit('setDateRange', arr);
      this.menu = false;
      this.previousRanOption = this.selectedOption;
      this.$emit('filter-search');
    },
    removeTimeFromDate(date) {
      return date.toISOString().split('T')[0];
    },
    updateDateRange(startingDate, endingDate) {
      // This function is called from outside to set values
      this.startingMonth = startingDate.slice(5, 7);
      this.startingYear = startingDate.slice(0, 4);
      this.endingMonth = endingDate.slice(5, 7);
      this.endingYear = endingDate.slice(0, 4);
      this.monthsText = `${this.startingMonth}/${this.startingYear} - ${this.endingMonth}/${this.endingYear}`;
    },
  },
  computed: {
    // eslint-disable-next-line
    isButtonDisabled() {
      // eslint-disable-next-line
      this.errors = false;
      if (this.dates.length !== 2 || this.invalidFormat1 === true || this.invalidFormat2 === true) {
        // eslint-disable-next-line
        this.errors = true;
        return true;
      }
    },
  },
  watch: {
    startingMonth(val) {
      let value = val;
      if (value === '') {
        this.startingMonth = '';
        this.invalidFormat1 = true;
        this.invalidationMessageMonth = 'Please enter a valid month';
      } else {
        value = value.substr(0, 2);
        const monthPattern = /0[1-9]|1[0-2]/;
        if (monthPattern.test(value)) {
          this.startingMonth = value;
          this.invalidFormat1 = false;
          this.invalidationMessageMonth = '';
          this.dates[0] = getFormattedDateFromYearMonth(this.startingYear, this.startingMonth, 'YYYY-MM');
        } else {
          this.invalidFormat1 = true;
          this.invalidationMessageMonth = 'Please enter a valid month';
        }
      }
    },
    startingYear(val) {
      let value = val;
      if (value === '') {
        this.startingYear = '';
        this.invalidFormat1 = true;
        this.invalidationMessageYear = '';
      } else {
        value = parseInt(`${value}`.substr(0, 4), 10);
        const totalYears = this.yearOptions.length;
        if (
          value >= parseInt(this.yearOptions[0], 10)
          && value <= parseInt(this.yearOptions[totalYears - 1], 10)
        ) {
          this.startingYear = `${value}`;
          this.invalidFormat1 = false;
          this.invalidationMessageYear = '';
          this.startingYear = `${value}`;
          this.dates[0] = getFormattedDateFromYearMonth(this.startingYear, this.startingMonth, 'YYYY-MM');
        } else {
          this.invalidFormat1 = true;
          this.invalidationMessageYear = `Please enter a valid year from ${
            this.yearOptions[0]
          } to ${this.yearOptions[totalYears - 1]}`;
        }
      }
    },
    endingMonth(val) {
      let value = val;
      if (value === '') {
        this.endingMonth = '';
        this.invalidFormat2 = true;
        this.invalidationMessageMonth = 'Please enter a valid month';
      } else {
        value = value.substr(0, 2);
        const monthPattern = /0[1-9]|1[0-2]/;
        if (monthPattern.test(value)) {
          let startDate;
          let endDate;

          if (this.endingYear !== '') {
            endDate = new Date(parseInt(this.endingYear, 10), parseInt(value, 10) - 1);
            startDate = new Date(
              parseInt(this.startingYear, 10),
              parseInt(this.startingMonth, 10) - 1,
            );
          }
          if (endDate <= startDate) {
            this.invalidFormat2 = true;
            this.invalidationMessageYear = `The end date should be after the start date`;
            this.invalidationMessageMonth = '';
          } else {
            this.endingMonth = value;
            this.invalidFormat2 = false;
            this.invalidationMessageMonth = '';
            this.invalidationMessageYear = '';
            this.dates[1] = getFormattedDateFromYearMonth(this.endingYear, this.endingMonth, 'YYYY-MM');
          }
        } else {
          this.invalidFormat2 = true;
          this.invalidationMessageMonth = 'Please enter a valid month';
        }
      }
    },
    endingYear(val) {
      let value = val;
      if (value === '') {
        this.endingYear = '';
        this.invalidFormat2 = true;
        this.invalidationMessageYear = '';
      } else {
        value = parseInt(`${value}`.substr(0, 4), 10);
        const totalYears = this.yearOptions.length;
        const endDate = new Date(parseInt(value, 10), parseInt(this.endingMonth, 10) - 1);
        const startDate = new Date(
          parseInt(this.startingYear, 10),
          parseInt(this.startingMonth, 10) - 1,
        );

        if (
          value >= parseInt(this.yearOptions[0], 10)
          && value <= parseInt(this.yearOptions[totalYears - 1], 10)
        ) {
          if (endDate <= startDate) {
            this.invalidFormat2 = true;
            this.invalidationMessageYear = `The end data should be after the start date`;
          } else {
            this.endingYear = `${value}`;
            this.invalidFormat2 = false;
            this.invalidationMessageYear = '';
            this.dates[1] = getFormattedDateFromYearMonth(this.endingYear, this.endingMonth, 'YYYY-MM');
          }
        } else {
          this.invalidFormat2 = true;
          this.invalidationMessageYear = `Please enter a valid year from ${
            this.yearOptions[0]
          } to ${this.yearOptions[totalYears - 1]}`;
        }
      }
    },
  },
  created() {
    const year = new Date().getFullYear();
    this.yearOptions = Array.from({ length: year - 2018 }, (value, index) => 2019 + index);
  },
};
</script>
