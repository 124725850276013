export default {
  setMostRecentMonthTrends(state, payload) {
    state.mostRecentMonthTrends = payload;
  },

  setMostRecentMonthTrendsLoading(state, payload) {
    state.mostRecentMonthTrendsLoading = payload;
  },
  setCPLData(state, payload) {
    state.cplData = {
      previousYearCPL: payload.result.previous_year_cpl,
      selectedDatesCPL: payload.result.selected_dates_cpl,
      endDate: payload.result.end_date,
      months: payload.result.months,
      tooltipLabels: payload.result.tooltip_labels,
      loading: false,
      spendAmount: payload.cpl_chart_banner_result[0].spend_amount,
      campaignAmount: payload.cpl_chart_banner_result[0].campaign_count,
      selectedYearSpendAmount: payload.result.selected_dates_cpl_spend_amount,
      selectedYearCampaignCount: payload.result.selected_dates_cpl_campaign_count,
      previous_year_spend_amounts: payload.result.previous_year_spend_amounts,
      emptyData: payload.empty_data,
      cplBigNumber: payload.cpl_chart_banner_result[0]['Selected Dates CPL'] === null ? '-' : payload.cpl_chart_banner_result[0]['Selected Dates CPL'].toFixed(2),
      yoYChange: payload.cpl_chart_banner_result[0]['CPL YoY Change'] === null
        || payload.cpl_chart_banner_result[0]['CPL YoY Change'] === undefined ? '-'
        : payload.cpl_chart_banner_result[0]['CPL YoY Change'],
      yoYPercentChange: payload.cpl_chart_banner_result[0]['CPL YoY Percent Change'] === null
        || payload.cpl_chart_banner_result[0]['CPL YoY Percent Change'] === undefined ? '-'
        : payload.cpl_chart_banner_result[0]['CPL YoY Percent Change'],
    };
    state.cplChartKey += 1;
  },
  setCPHData(state, payload) {
    state.cphData = {
      previousYearCPH: payload.result.previous_year_cph,
      selectedDatesCPH: payload.result.selected_dates_cph,
      endDate: payload.result.end_date,
      months: payload.result.months,
      spendAmount: payload.cph_chart_banner_result[0].spend_amount,
      campaignAmount: payload.cph_chart_banner_result[0].campaign_count,
      tooltipLabels: payload.result.tooltip_labels,
      selectedYearSpendAmount: payload.result.selected_dates_cph_spend_amount,
      selectedYearCampaignCount: payload.result.selected_dates_cph_campaign_count,
      previous_year_spend_amounts: payload.result.previous_year_spend_amounts,
      loading: false,
      emptyData: payload.empty_data,
      cphBannerData: payload.cph_chart_banner_result[0],
      yoYChange: payload.cph_chart_banner_result[0].yoy_cph_change == null
        || payload.cph_chart_banner_result[0].yoy_cph_change === undefined ? '-'
        : payload.cph_chart_banner_result[0].yoy_cph_change,
    };
    state.cphChartKey += 1;
  },

  setLTHData(state, payload) {
    state.lthData = {
      months: payload.result.months,
      tooltipLabels: payload.result.tooltip_labels,
      selected_dates_hire_rate: payload.result.selected_dates_hire_rate,
      previous_year_hire_rate: payload.result.previous_year_hire_rate,
      selected_dates_hire_ratio: payload.result.selected_dates_hire_ratio,
      previous_year_hire_ratio: payload.result.previous_year_hire_ratio,
      spendAmount: payload.lth_chart_banner_result.spend_amount,
      campaignAmount: payload.lth_chart_banner_result.campaign_count,
      selectedYearSpendAmount: payload.result.selected_dates_lth_spend_amount,
      selectedYearCampaignCount: payload.result.selected_dates_lth_campaign_count,
      previous_year_spend_amounts: payload.result.previous_year_spend_amounts,
      lthBigNumber: payload.lth_chart_banner_result['Selected Dates Hire Ratio'] === null ? '-' : payload.lth_chart_banner_result['Selected Dates Hire Ratio'],
      yoYChange: payload.lth_chart_banner_result['Hire Ratio YoY Percent Change'] === null ? '-' : parseFloat(payload.lth_chart_banner_result['Hire Ratio YoY Percent Change']).toFixed(2),
      loading: false,
      emptyData: payload.empty_data,
    };
    state.lthChartKey += 1;
  },

  setCPMData(state, payload) {
    state.cpmData = payload;
    state.cpmChartKey += 1;
    state.cpmDataChartLoading = false;
  },
};
