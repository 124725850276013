<template>
  <div class="sidebar">
    <v-col class="location-maps-siderbar-filters">
      <v-row class="ml-0">
        <div class="text-h6">Filters</div>
      </v-row>

      <v-row class="d-flex flex-column mx-0 mb-6 locations-selector-maps">
        <label for="region-select" class="text-subtitle-1 text-left">Locations</label>
        <p class="text-subtitle-2 text-left mb-2">Selected Locations</p>
        <div v-if="selectedStateChips.length > 0" class="mb-2">
          <span v-for="(stateChip, index) in selectedStateChips" :key="stateChip.id">
            <v-chip
              v-if="index < 1"
              color="primary mr-1"
            >
              {{ stateChip.name }}
            </v-chip>
            <v-chip
              v-if="index == 1"
              color="primary"
            >
              +{{ selectedStateChips.length - 1 }} others
            </v-chip>
          </span>
        </div>
        <div class="mb-2" v-else>
          No Locatons Selected
        </div>
        <v-autocomplete
          ref="autocomplete"
          label="Search Locations"
          :items="[]"
          :search-input.sync="search"
          @blur="handleBlur"
          @focus="handleFocus"
          @update:search-input="onInput"
          variant="solo-filled"
          return-object
          chips
          hide-details
        >
          <template v-slot:no-data>
            <v-list>
              <v-list-item-title class="m-2 pl-2">
                <v-btn
                        @click="clearAllSelections"
                        color="primary"
                        text small
                >
                  Reset Selection
                </v-btn>
              </v-list-item-title>
              <v-list-item-title class="scrollable-list">
                <v-treeview
                        v-model="selectedStates"
                        :items="formattedLocationOptions"
                        :search="autoCompleteSearch"
                        @input="handleInput"
                        open-all
                        dense
                        selectable
                        selected-color="primary"
                />
              </v-list-item-title>
            </v-list>
          </template>
        </v-autocomplete>

        <template v-slot:no-data>
          <v-list>
            <v-list-item-title class="m-2 pl-2">
              <v-btn
                @click="clearAllSelections"
                color="primary"
                text small
              >
                Reset Selection
              </v-btn>
            </v-list-item-title>
            <v-list-item-title class="scrollable-list">
              <v-treeview
                v-model="selectedStates"
                :items="formattedLocationOptions"
                :search="autoCompleteSearch"
                @input="handleInput"
                open-all
                dense
                selectable
              />
            </v-list-item-title>
          </v-list>
        </template>
      </v-row>

      <v-row class="d-flex flex-column mx-0 my-6 map-filter-counter">
        <div class="d-flex justify-space-between align-center mb-2">
          <div class="text-left text-subtitle-2">Lead Count (past 12 months)</div>
          <v-icon
            v-if="getSelectedMinLeadCountFilterValue > 0"
            class="ml-2"
            @click="resetFilter('lead_count')"
          >
            mdi-close
          </v-icon>
        </div>
        <v-slider
          ref="lead-count-slider-slider"
          :value="getSelectedMinLeadCountFilterValue"
          @input="updateSelectedLeadCountValue"
          color="primary lighten-3"
          track-color="primary"
          thumb-color="primary"
          min="0"
          :max="getMaxLeadCountFilterValue"
          strict
          hide-details
        >
          <template v-slot:prepend>
            <v-text-field
              :value="getSelectedMinLeadCountFilterValue"
              class="mt-0 pt-0"
              type="number"
              @input="updateSelectedLeadCountValue"
              hint="Min. value"
              filled
              single-line
              persistent-hint
              dense
              solo
              background-color="white"
              elevation="0"
              flat
              outlined
            ></v-text-field>
          </template>
        </v-slider>
      </v-row>

      <v-row class="d-flex flex-column mx-0 my-6 map-filter-counter">
        <div class="d-flex justify-space-between align-center mb-2">
          <div class="text-left text-subtitle-2">Hire Count (past 12 months)</div>
          <v-icon
            v-if="getSelectedMinHireCountFilterValue > 0"
            class="ml-2"
            @click="resetFilter('hire_count')"
          >
            mdi-close
          </v-icon>
        </div>
        <v-slider
          ref="lead-count-slider-slider"
          :value="getSelectedMinHireCountFilterValue"
          @input="updateSelectedHireCountValue"
          color="primary lighten-3"
          track-color="primary"
          thumb-color="primary"
          min="0"
          :max="getMaxHireCountFilterValue"
          strict
          hide-details
        >
          <template v-slot:prepend>
            <v-text-field
              :value="getSelectedMinHireCountFilterValue"
              class="mt-0 pt-0"
              type="number"
              @input="updateSelectedHireCountValue"
              hint="Min. value"
              outlined
              single-line
              background-color="white"
              persistent-hint
              filled
              dense
              solo
              flat
            ></v-text-field>
          </template>
        </v-slider>
      </v-row>

      <v-row class="d-flex flex-column mx-0 my-6 map-filter-counter">
        <div class="d-flex justify-space-between align-center mb-2">
          <div class="text-left text-subtitle-2">Trucking Employment Count</div>
          <v-icon
            v-if="getSelectedMinEmploymentCountFilterValue > 0"
            class="ml-2"
            @click="resetFilter('employment_count')"
          >
            mdi-close
          </v-icon>
        </div>
        <v-slider
          ref="lead-count-slider-slider"
          :value="getSelectedMinEmploymentCountFilterValue"
          @input="updateSelectedEmploymentCountValue"
          color="primary lighten-3"
          track-color="primary"
          thumb-color="primary"
          min="0"
          :max="getMaxEmploymentCountFilterValue"
          strict
          hide-details
        >
          <template v-slot:prepend>
            <v-text-field
              :value="getSelectedMinEmploymentCountFilterValue"
              class="mt-0 pt-0"
              type="number"
              @input="updateSelectedEmploymentCountValue"
              hint="Min. value"
              outlined
              single-line
              background-color="white"
              persistent-hint
              filled
              dense
              solo
              flat
            ></v-text-field>
          </template>
        </v-slider>
      </v-row>
      <v-row class="d-flex flex-column mx-0 mt-6">
        <div class="text-left text-subtitle-2">Labor Force Size Percentile</div>
        <div class="d-flex flex-row">
          <v-col class="text-left pl-1">
            0
          </v-col>
          <v-col class="text-right pr-0">
            100
          </v-col>
        </div>
        <label
          id="labor-force-size-percentile-slider-label-max"
          style="display:none"
        >
          Labor Force Size Percentile Range Slider Min
        </label>
        <label
          id="labor-force-size-percentile-slider-label-min"
          style="display:none"
        >
          Labor Force Size Percentile Range Slider Max
        </label>
        <v-range-slider
          ref="labor-force-size-percentile-slider"
          v-model="range"
          :value="getLaborForcePercentileFilter"
          @input="updateSelectedLaborForcePercentileValue"
          :thumb-label="thumbLabel"
          @mousedown="changeThumbLabel"
          @mouseup="changeThumbLabel"
          :max="100"
          :min="0"
          strict
        />
      </v-row>
    </v-col>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'MapsSideNavBar',
  props: {
    formattedLocationOptions: { type: Array, required: true },
    mapStateChips: { type: Object, required: true },
    defaultSelectedStates: { type: Array, required: true },
  },
  data() {
    return {
      thumbLabel: false,
      search: null,
      autoCompleteSearch: null,
      selectedStateChips: [],
      menuOpen: false,
      selectedStates: [
        'IL', 'IN', 'MI', 'OH', 'WI', 'IA', 'KS', 'MN', 'MO', 'NE',
        'ND', 'SD', 'DE', 'DC', 'MD', 'VA', 'WV', 'NJ', 'NY', 'PA',
        'CT', 'ME', 'MA', 'NH', 'RI', 'VT', 'AR', 'LA', 'OK', 'TX',
        'AL', 'KY', 'MS', 'TN', 'FL', 'GA', 'NC', 'SC', 'AZ', 'CO',
        'ID', 'MT', 'NV', 'NM', 'UT', 'WY', 'CA', 'OR', 'WA',
      ],
      range: [0, 100],
    };
  },
  computed: {
    ...mapGetters('maps', [
      'getLaborForcePercentileFilter',
      'getMaxLeadCountFilterValue',
      'getMaxHireCountFilterValue',
      'getMaxEmploymentCountFilterValue',
      'getSelectedMinLeadCountFilterValue',
      'getSelectedMinHireCountFilterValue',
      'getSelectedMinEmploymentCountFilterValue',
      'getMapFilters',
    ]),
  },
  async mounted() {
    await this.$store.dispatch('locations/fetchStateRegionSubregionOptions');

    // Fetch max values for map filters
    await this.$store.dispatch('maps/getFilterUpperLimits');
    // Set filter max values for msa as it is default location type
    this.$store.commit('maps/setMaxLeadCountFilterValue', this.getMapFilters.msaLeadCountValue);
    this.$store.commit('maps/setMaxHireCountFilterValue', this.getMapFilters.msaHireCountValue);
    this.$store.commit('maps/setMaxEmploymentCountFilterValue', this.getMapFilters.msaEmploymentCountValue);

    // Show default state chips
    this.handleInput(this.defaultSelectedStates);
    if (document.getElementById('labor-force-size-percentile-slider-label-max')) {
      // eslint-disable-next-line max-len, dot-notation
      document.getElementById('labor-force-size-percentile-slider-label-max').setAttribute('for', `input-max-${this.$refs['labor-force-size-percentile-slider']['_uid']}`);
      // eslint-disable-next-line max-len, dot-notation
      document.getElementById('labor-force-size-percentile-slider-label-min').setAttribute('for', `input-min-${this.$refs['labor-force-size-percentile-slider']['_uid']}`);
    }
  },
  methods: {
    changeThumbLabel() {
      this.thumbLabel = !this.thumbLabel;
    },
    clearAllSelections() {
      this.selectedStates = this.defaultSelectedStates;
      this.handleInput(this.defaultSelectedStates);
    },
    onInput(val) {
      this.autoCompleteSearch = val;

      this.$nextTick(() => {
        this.$refs.autocomplete.isMenuActive = true;
      });
    },
    handleBlur() {
      this.$nextTick(() => {
        if (this.search) {
          this.$refs.autocomplete.isMenuActive = true;
        }

        // Reset treeview list with reset of input
        // This is ugly but necessary as input's value and 'search' attribute are not same
        if (this.$refs.autocomplete.$el.children[0].children[0].children[0].children[1].children[0].value === '') {
          this.autoCompleteSearch = null;
        }
      });
    },
    handleFocus() {
      // Reset treeview list with reset of input
      // This is ugly but necessary as input's value and 'search' attribute are not same
      if (this.$refs.autocomplete.$el.children[0].children[0].children[0].children[1].children[0].value === '') {
        this.autoCompleteSearch = null;
      }
    },
    // eslint-disable-next-line
    handleInput: _.debounce(function(items) {
      const stateList = [];

      items.forEach((key) => {
        stateList.push({
          id: key,
          name: this.mapStateChips[key],
        });
      });

      this.selectedStateChips = stateList;
      this.$emit('update:selectedStates', items);
    }),
    updateSelectedLaborForcePercentileValue(newValue) {
      this.$store.commit('maps/setLaborForcePercentileFilter', newValue);
    },
    updateSelectedLeadCountValue(newValue) {
      this.$store.commit('maps/setSelectedMinLeadCountFilterValue', newValue);
    },
    updateSelectedHireCountValue(newValue) {
      this.$store.commit('maps/setSelectedMinHireCountFilterValue', newValue);
    },
    updateSelectedEmploymentCountValue(newValue) {
      this.$store.commit('maps/setSelectedMinEmploymentCountFilterValue', newValue);
    },
    resetFilter(selectedFilter) {
      // Removes focus from clicked close icon
      document.activeElement.blur();

      if (selectedFilter === 'lead_count') {
        this.$store.commit('maps/setSelectedMinLeadCountFilterValue', 0);
      } else if (selectedFilter === 'hire_count') {
        this.$store.commit('maps/setSelectedMinHireCountFilterValue', 0);
      } else {
        this.$store.commit('maps/setSelectedMinEmploymentCountFilterValue', 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .scrollable-list {
    overflow-y: auto;
  }
</style>
<!-- eslint-disable  max-len -->
