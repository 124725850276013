/* ============
 * Trends Module
 * ============
 */

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// initial state
const state = () => ({
  mostRecentMonthTrends: {},
  mostRecentMonthTrendsLoading: true,
  driverValues: ['All', 'Company Driver', 'Owner Operator', 'Team', 'Multiple and/or Other Driver Types'],
  haulTypeValues: ['All', 'Dry Van', 'Flatbed', 'Refrigerated', 'Tanker', 'Hazmat', 'Multiple and/or Other Haul Types'],
  routeTypeValues: ['All', 'Over the Road', 'Local', 'Dedicated', 'Multiple and/or Other Route Types'],
  endorsementRequiredValues: ['All', 'Hazmat', 'Tanker', 'Doubles/Triples', 'None'],
  cplData: {
    loading: true,
  },
  cphData: {
    loading: true,
  },
  lthData: {
    loading: true,
  },
  cpmData: [],
  cpmDataChartLoading: true,
  advertisedPayData: {
    loading: true,
  },
  cplChartKey: 0,
  cphChartKey: 0,
  lthChartKey: 0,
  cpmChartKey: 0,
  monthNames: [
    { month: 'January', abbreviation: 'Jan', value: '01' },
    { month: 'February', abbreviation: 'Feb', value: '02' },
    { month: 'March', abbreviation: 'Mar', value: '03' },
    { month: 'April', abbreviation: 'Apr', value: '04' },
    { month: 'May', abbreviation: 'May', value: '05' },
    { month: 'June', abbreviation: 'Jun', value: '06' },
    { month: 'July', abbreviation: 'Jul', value: '07' },
    { month: 'August', abbreviation: 'Aug', value: '08' },
    { month: 'September', abbreviation: 'Sep', value: '09' },
    { month: 'October', abbreviation: 'Oct', value: '10' },
    { month: 'November', abbreviation: 'Nov', value: '11' },
    { month: 'December', abbreviation: 'Dec', value: '12' },
  ],
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
