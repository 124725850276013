import ErrorService from '../../../helpers/ErrorHandling';

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
const HEADERS = {
  'Content-Type': 'application/json',
};

export default {
  async fetchLastMonthsData({ commit }) {
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/job_postings/last_months_data`, {
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
    });
    const data = await response.json();

    if (ErrorService.handleResponse(response, data)) {
      const formattedData = data.result;

      // Handle empty result
      if (Object.keys(formattedData).length < 1) {
        formattedData.job_postings_and_unique_companies = [];
        formattedData.median_advertised_wage = [];
        formattedData.advertised_salary_distribution = { advertised_salary_data: [] };
        formattedData.advertised_wage_ranges = [];
        formattedData.postings_by_company = [];
        formattedData.bonus_information = [];
        formattedData.hometime_information = [];
        formattedData.geographical_map_data = [];
        formattedData.geographical_bar_chart = [];
      }

      // eslint-disable-next-line max-len
      await commit('setTitleJobsAndCompaniesData', formattedData.job_postings_and_unique_companies);
      await commit('setTitleMedianAdvertisedTimeframeWageData', formattedData.median_advertised_wage);
      await commit('setAdvertisedSalaryDistribution', formattedData.advertised_salary_distribution);
      await commit('setAdvertisedWageRanges', formattedData.advertised_wage_ranges);
      await commit('setAdvertisedSalaryDistributionLoading', false);
      await commit('setAdvertisedWageRangesLoading', false);
      await commit('setPostingsByCompany', formattedData.postings_by_company);
      await commit('setPostingsByCompanyLoading', false);
      await commit('setBonusData', formattedData.bonus_information);
      await commit('setBonusDataLoading', false);
      await commit('setHometimeBenefitData', formattedData.hometime_information);
      await commit('setHometimeBenefitDataLoading', false);
      await commit('setGeographicalData', formattedData.geographical_map_data);
      await commit('setGeographicalChartData', formattedData.geographical_bar_chart);
      await commit('setJobPostingsMapLoading', false);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },

  async fetchThreeMonthData({ commit }) {
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/job_postings/three_months_data`, {
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
    });
    const data = await response.json();

    if (ErrorService.handleResponse(response, data)) {
      const formattedData = data.result;

      // Handle empty result
      if (Object.keys(formattedData).length < 1) {
        formattedData.job_postings_and_unique_companies = [];
        formattedData.median_advertised_wage = [];
        formattedData.advertised_salary_distribution = { advertised_salary_data: [] };
        formattedData.advertised_wage_ranges = [];
        formattedData.postings_by_company = [];
        formattedData.bonus_information = [];
        formattedData.hometime_information = [];
        formattedData.geographical_map_data = [];
        formattedData.geographical_bar_chart = [];
      }

      // eslint-disable-next-line max-len
      await commit('setTitleJobsAndCompaniesData', formattedData.job_postings_and_unique_companies);
      await commit('setTitleMedianAdvertisedTimeframeWageData', formattedData.median_advertised_wage);
      await commit('setAdvertisedSalaryDistribution', formattedData.advertised_salary_distribution);
      await commit('setAdvertisedWageRanges', formattedData.advertised_wage_ranges);
      await commit('setAdvertisedSalaryDistributionLoading', false);
      await commit('setAdvertisedWageRangesLoading', false);
      await commit('setPostingsByCompany', formattedData.postings_by_company);
      await commit('setPostingsByCompanyLoading', false);
      await commit('setBonusData', formattedData.bonus_information);
      await commit('setBonusDataLoading', false);
      await commit('setHometimeBenefitData', formattedData.hometime_information);
      await commit('setHometimeBenefitDataLoading', false);
      await commit('setGeographicalData', formattedData.geographical_map_data);
      await commit('setGeographicalChartData', formattedData.geographical_bar_chart);
      await commit('setJobPostingsMapLoading', false);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },
  async searchAdvertisedSalaryDistribution({ commit }, payload) {
    const body = JSON.stringify(payload);
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/job_postings/advertised_salary_distribution`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
      body,
    });
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      const advertisedPayData = data.result;

      await commit('setAdvertisedSalaryDistribution', advertisedPayData);
      await commit('setAdvertisedSalaryDistributionLoading', false);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },
  async searchAdvertisedWageRanges({ commit }, payload) {
    const body = JSON.stringify(payload);
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/job_postings/advertised_wage_ranges`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
      body,
    });
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      const advertisedWageRanges = data.result;

      await commit('setAdvertisedWageRanges', advertisedWageRanges);
      await commit('setAdvertisedWageRangesLoading', false);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },
  async fetchCompanies({ commit }, payload) {
    // Encode the query parameter
    const encodedParam = encodeURIComponent(payload.query);
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/searches/search_by_carrier?search=${encodedParam}&page=${payload.page}`, {
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
    });
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setCompanies', data.result);
      await commit('setHasMoreCompanies', data.has_more);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },
  async fetchBannerJobPostingsAndDistinctCompaniesData({ commit }, payload) {
    const body = JSON.stringify(payload);
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/job_postings/get_job_postings_and_unique_companies`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
      body,
    });

    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setTitleJobsAndCompaniesData', data.result);
      await commit('fetchBannerJobPostingsAndDistinctCompaniesDataLoading', false);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },
  async fetchBannerMedianAdvertisedTimeframeWage({ commit }, payload) {
    const body = JSON.stringify(payload);
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/job_postings/get_median_advertised_wage`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
      body,
    });

    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setTitleMedianAdvertisedTimeframeWageData', data.result);
      await commit('fetchBannerMedianAdvertisedTimeframeWageLoading', false);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },
  async fetchInputtedCompanyDistributionStatistics({ commit }, payload) {
    await commit('setInputtedCompanyDistributionStatisticsLoading', true);
    const body = JSON.stringify(payload);
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/job_postings/get_company_distribution_data`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
      body,
    });

    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setInputtedCompanyDistributionStatistics', data.result);
      await commit('setInputtedCompanyDistributionStatisticsLoading', false);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },
  async fetchSelectedDistributionWageRages({ commit }, payload) {
    const body = JSON.stringify(payload);
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/job_postings/get_selected_distribution_wage_ranges`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
      body,
    });

    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      setTimeout(() => {
        commit('setSelectedDistributionWageRangesLoading', false);
      }, 1000);
      await commit('setSelectedDistributionWageRangesUsed', true);
      await commit('setSelectedDistributionWageRanges', data.result);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },
  async fetchPostingsByCompany({ commit }, payload) {
    const body = JSON.stringify(payload);
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/job_postings/get_postings_by_company`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
      body,
    });

    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setPostingsByCompany', data.result);
      await commit('setPostingsByCompanyLoading', false);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },
  async fetchBonusInformation({ commit }, payload) {
    const body = JSON.stringify(payload);
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/job_postings/get_bonus_information`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
      body,
    });

    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setBonusData', data.result);
      await commit('setBonusDataLoading', false);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },
  async fetchHometimeBenefitData({ commit }, payload) {
    const body = JSON.stringify(payload);
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/job_postings/get_number_of_postings_benefits`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
      body,
    });

    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setHometimeBenefitData', data.result);
      await commit('setHometimeBenefitDataLoading', false);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },
  async fetchHometimePostingBenefitsDetailsData({ commit }, payload) {
    const body = JSON.stringify(payload);

    const response = await fetch(`${BACKEND_URL}/strategize_frontend/job_postings/get_hometime_posting_benefits_details`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
      body,
    });

    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setHometimePostingBenefitsDetailsData', data.result);
      await commit('setHometimePostingBenefitsDetailsDataLoading', false);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },
  async fetchGeographicalData({ commit }, payload) {
    const body = JSON.stringify(payload);

    const response = await fetch(`${BACKEND_URL}/strategize_frontend/job_postings/get_geographical_data`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
      body,
    });

    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setGeographicalData', data.result);
      await commit('setGeographicalChartData', data.geographical_bar_chart);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
      await commit('setJobPostingsMapLoading', false);
    }
  },
};
