import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// Hard coded until we fetch it from DB.
const mapFilters = {
  msaLeadCountValue: 60000,
  msaHireCountValue: 6000,
  msaEmploymentCountValue: 70000,
};

const state = () => ({
  mapsData: [],
  mapsDataLoading: false,
  laborForcePercentileFilter: [0, 100],
  maxLeadCountFilterValue: mapFilters.msaLeadCountValue,
  maxHireCountFilterValue: mapFilters.msaHireCountValue,
  maxEmploymentCountFilterValue: mapFilters.msaEmploymentCountValue,
  selectedMinLeadCountFilterValue: 0,
  selectedMinHireCountFilterValue: 0,
  selectedMinEmploymentCountFilterValue: 0,
  mapFilters,
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
