<template>
  <div class="strategize-spinner" v-show="loading">
    <div class="strategize-clip" v-bind:style="spinnerStyle">
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: '#5dc596',
    },
    size: {
      type: String,
      default: '35px',
    },
    radius: {
      type: String,
      default: '100%',
    },
    borderWidth: {
      type: String,
      default: '8px',
    },
  },
  computed: {
    spinnerStyle() {
      return {
        height: this.size,
        width: this.size,
        borderWidth: this.borderWidth,
        borderStyle: 'solid',
        borderColor: `${this.color} ${this.color} transparent`,
        borderRadius: this.radius,
        background: 'transparent',
      };
    },
  },
};
</script>
