<template>
  <v-app>
    <div class="locations-grid">
      <div class="location-filter-wrapper">
        <div class="locations-filters">
          <div class="location-selected-select filter-style">
            <LocationListings
              label="Location"
              searchFieldId="locations_location_search"
              hint="Type at least 3 characters to search"
              :initialLocation="selectLocation"
              @selected="(val) => searchLocation(val)"
            />
          </div>
          <div
            v-if="!locationBreakdownLoading && hasBreakdownData"
            class="overallRecruitingScoreWrapper"
          >
            <OverallRecruitingScore :score="`${recruitingDifficultyScore}`" />
          </div>
          <div
            class="locations-filters-buttons-area"
            v-if="!locationBreakdownLoading && hasBreakdownData"
          >
            <span class="switch-label mr-4">Show Map</span>
            <v-switch
              small
              :ripple="false"
              :class="[
                {'switch-on': locationMapVisible, 'switch-off': !locationMapVisible},
                'locations-page-map-switch'
              ]"
              v-model="locationMapVisible"
              inset
            ></v-switch>
          </div>
        </div>
      </div>

      <div v-if="isLoading">
        <LoadingSpinner
          class="absolute-center"
          :loading="true"
          :color="'#70A300'"
          :size="'200px'"
          :borderWidth="'14px'"
        />
      </div>

      <div class="absolute-center" v-if="!hasBreakdownData && !isLoading">
        <h6>It appears we don't have any data for this location.</h6>
      </div>

      <div class="main-content" v-if="hasBreakdownData">
        <LocationMap
          :selectedLocation="locationSelected"
          v-if="isLocationMapVisible && notLocationType"
        />
        <div class="accordion-parent">
          <Accordion>
            <template v-slot:title>
              <h1 class="accordion-title">Location Overview</h1>
              <span class="accordion-summary" v-if="sectionHelperTextStateIsActive">{{
                locationOverview.summaryText
              }}</span>
            </template>

            <div class="wrapped-accordion-summary" v-if="sectionHelperTextStateIsActive">
              <span>{{ locationOverview.summaryText }}</span>
            </div>

            <div class="stat-row five-columns">
              <div id="estimated-cost-per-lead">
                <StatPointGraph
                  :value="locationOverview.estimatedCPL"
                  :percentValue="locationOverview.estimatedCplPercentile"
                  :title="'Estimated Cost per Lead'"
                  :tooltip="statSources.estimatedCPL"
                  :popoverContent="popovers.estimatedCPL"
                  chartId="estimatedcostPerLead"
                  colorBarDirection="right"
                  :colorScaleReversed="false"
                  :minRange="0"
                  :maxRange="100"
                  :thrashHoldOne="33"
                  :thrashHoldTwo="67"
                  :barHeight="30"
                  :pointWidth="14"
                  :format="'money'"
                  :locationCode="locationCode"
                  :locationType="locationType"
                />
                <div class="median-header-container">
                  <p class="median-breakdown-header text-right">Median Value</p>
                </div>

                <div class="median-breakdown">
                  <div class="region row mb-1">
                    <div class="col-sm-8">
                      <p class="region-title">Region</p>
                      <v-tooltip right v-if="showLocationImagesOnMedianDataSection">
                        <template v-slot:activator="{ on, attrs }">
                          <p v-bind="attrs" v-on="on">
                            {{ location.region }}
                          </p>
                        </template>
                        <div v-html="regionTooltipContent"></div>
                      </v-tooltip>
                    </div>
                    <div class="col-sm-4 d-flex flex-row-reverse">
                      <p class="align-self-end" v-if="medianData">
                        <b>{{ medianData.estimatedCPL.region }}</b>
                      </p>
                      <p class="align-self-end" v-else>
                        <b>-</b>
                      </p>
                    </div>
                  </div>

                  <div class="region row mb-1 mt-0">
                    <div class="col-sm-8">
                      <p class="region-title">Sub-Region</p>
                      <v-tooltip right v-if="showLocationImagesOnMedianDataSection">
                        <template v-slot:activator="{ on, attrs }">
                          <p v-bind="attrs" v-on="on">
                            {{ location.subregion }}
                          </p>
                        </template>
                        <div v-html="subRegionTooltipContent"></div>
                      </v-tooltip>
                    </div>
                    <div class="col-sm-4 d-flex flex-row-reverse">
                      <p class="align-self-end" v-if="medianData">
                        <b>{{ medianData.estimatedCPL.subregion }}</b>
                      </p>
                      <p class="align-self-end" v-else>
                        <b>-</b>
                      </p>
                    </div>
                  </div>

                  <div class="region row mb-1 mt-0">
                    <div class="col-sm-8">
                      <p>Similar Sized</p>
                      <p>
                        {{ determineLocationType }}
                        <span
                          ><TooltipIcon :tip="similarSizedTip" :tooltipTitle="similarSizedTitle"
                        /></span>
                      </p>
                    </div>
                    <div class="col-sm-4 d-flex flex-row-reverse">
                      <p class="align-self-end" v-if="medianData">
                        <b>{{ medianData.estimatedCPL.similiar }}</b>
                      </p>
                      <p class="align-self-end" v-else>
                        <b>-</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div id="lead-to-hire-ratio">
                <StatPointGraph
                  :value="locationOverview.lthRatio"
                  :percentValue="locationOverview.leadToHireRatio12MoPercentile"
                  :title="'Lead-to-Hire Ratio'"
                  subTitle="Randall Reilly, past 12 months"
                  :tooltip="statSources.lthRatio"
                  :popoverContent="popovers.lthRatio"
                  chartId="leadToHireRatio"
                  colorBarDirection="right"
                  :colorScaleReversed="false"
                  :minRange="0"
                  :maxRange="100"
                  :thrashHoldOne="33"
                  :thrashHoldTwo="67"
                  :barHeight="30"
                  :pointWidth="14"
                  :format="'ratio'"
                  :locationCode="locationCode"
                  :locationType="locationType"
                />

                <div class="median-header-container">
                  <p class="median-breakdown-header text-right">Median Value</p>
                </div>

                <div class="median-breakdown">
                  <div class="region row mb-1">
                    <div class="col-sm-8">
                      <p class="region-title">Region</p>
                      <v-tooltip right v-if="showLocationImagesOnMedianDataSection">
                        <template v-slot:activator="{ on, attrs }">
                          <p v-bind="attrs" v-on="on">
                            {{ location.region }}
                          </p>
                        </template>
                        <div v-html="regionTooltipContent"></div>
                      </v-tooltip>
                    </div>
                    <div class="col-sm-4 d-flex flex-row-reverse">
                      <p class="align-self-end" v-if="medianData">
                        <b>{{ medianData.lthRatio.region }}</b>
                      </p>
                      <p class="align-self-end" v-else>
                        <b>-</b>
                      </p>
                    </div>
                  </div>

                  <div class="region row mb-1 mt-0">
                    <div class="col-sm-8">
                      <p class="region-title">Sub-Region</p>
                      <v-tooltip right v-if="showLocationImagesOnMedianDataSection">
                        <template v-slot:activator="{ on, attrs }">
                          <p v-bind="attrs" v-on="on">
                            {{ location.subregion }}
                          </p>
                        </template>
                        <div v-html="subRegionTooltipContent"></div>
                      </v-tooltip>
                    </div>
                    <div class="col-sm-4 d-flex flex-row-reverse">
                      <p class="align-self-end" v-if="medianData">
                        <b>{{ medianData.lthRatio.subregion }}</b>
                      </p>
                      <p class="align-self-end" v-else>
                        <b>-</b>
                      </p>
                    </div>
                  </div>

                  <div class="region row mb-1 mt-0">
                    <div class="col-sm-8">
                      <p>Similiar Sized</p>
                      <p>
                        {{ determineLocationType }}
                        <span
                          ><TooltipIcon :tip="similarSizedTip" :tooltipTitle="similarSizedTitle"
                        /></span>
                      </p>
                    </div>
                    <div class="col-sm-4 d-flex flex-row-reverse">
                      <p class="align-self-end" v-if="medianData">
                        <b>{{ medianData.lthRatio.similiar }}</b>
                      </p>
                      <p class="align-self-end" v-else>
                        <b>-</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div id="estimated-cost-per-hire">
                <StatPointGraph
                  :value="locationOverview.estimatedCPH"
                  :percentValue="locationOverview.estCphPercentile"
                  :title="'Estimated Cost per Hire'"
                  :tooltip="statSources.estimatedCPH"
                  :popoverContent="popovers.estimatedCPH"
                  chartId="estimatedCostPerHire"
                  colorBarDirection="right"
                  :colorScaleReversed="false"
                  :minRange="0"
                  :maxRange="100"
                  :thrashHoldOne="33"
                  :thrashHoldTwo="67"
                  :barHeight="30"
                  :pointWidth="14"
                  :format="'money'"
                  :locationCode="locationCode"
                  :locationType="locationType"
                />

                <div class="median-header-container">
                  <p class="median-breakdown-header text-right">Median Value</p>
                </div>

                <div class="median-breakdown">
                  <div class="region row mb-1">
                    <div class="col-sm-8">
                      <p class="region-title">Region</p>
                      <v-tooltip right v-if="showLocationImagesOnMedianDataSection">
                        <template v-slot:activator="{ on, attrs }">
                          <p v-bind="attrs" v-on="on">
                            {{ location.region }}
                          </p>
                        </template>
                        <div v-html="regionTooltipContent"></div>
                      </v-tooltip>
                    </div>
                    <div class="col-sm-4 d-flex flex-row-reverse">
                      <p class="align-self-end" v-if="medianData">
                        <b>{{ medianData.estimatedCPH.region }}</b>
                      </p>
                      <p class="align-self-end" v-else>
                        <b>-</b>
                      </p>
                    </div>
                  </div>

                  <div class="region row mb-1 mt-0">
                    <div class="col-sm-8">
                      <p class="region-title">Sub-Region</p>
                      <v-tooltip right v-if="showLocationImagesOnMedianDataSection">
                        <template v-slot:activator="{ on, attrs }">
                          <p v-bind="attrs" v-on="on">
                            {{ location.subregion }}
                          </p>
                        </template>
                        <div v-html="subRegionTooltipContent"></div>
                      </v-tooltip>
                    </div>
                    <div class="col-sm-4 d-flex flex-row-reverse">
                      <p class="align-self-end" v-if="medianData">
                        <b>{{ medianData.estimatedCPH.subregion }}</b>
                      </p>
                      <p class="align-self-end" v-else>
                        <b>-</b>
                      </p>
                    </div>
                  </div>

                  <div class="region row mb-1 mt-0">
                    <div class="col-sm-8">
                      <p>Similiar Sized</p>
                      <p>
                        Metro Areas
                        <span
                          ><TooltipIcon :tip="similarSizedTip" :tooltipTitle="similarSizedTitle"
                        /></span>
                      </p>
                    </div>
                    <div class="col-sm-4 d-flex flex-row-reverse">
                      <p class="align-self-end" v-if="medianData">
                        <b>{{ medianData.estimatedCPH.region }}</b>
                      </p>
                      <p class="align-self-end" v-else>
                        <b>-</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div id="posting-competition">
                <StatPointGraph
                  :value="locationOverview.competitionScore"
                  :percentValue="locationOverview.postingCompetitionScoreFinal"
                  :title="'Posting Competition'"
                  subTitle="Compared to the National Average"
                  :tooltip="statSources.competitionScore"
                  :popoverContent="popovers.postingCompetitionScore"
                  chartId="postingCompetitionScore"
                  colorBarDirection="left"
                  :colorScaleReversed="true"
                  :minRange="-100"
                  :maxRange="100"
                  :thrashHoldOne="-33"
                  :thrashHoldTwo="33"
                  :barHeight="30"
                  :locationCode="locationCode"
                  :locationType="locationType"
                  :pointWidth="14"
                />

                <div class="median-header-container">
                  <p class="median-breakdown-header text-right">Median Value</p>
                </div>

                <div class="median-breakdown">
                  <div class="region row mb-1">
                    <div class="col-sm-8">
                      <p class="region-title">Region</p>
                      <v-tooltip right v-if="showLocationImagesOnMedianDataSection">
                        <template v-slot:activator="{ on, attrs }">
                          <p v-bind="attrs" v-on="on">
                            {{ location.region }}
                          </p>
                        </template>
                        <div v-html="regionTooltipContent"></div>
                      </v-tooltip>
                    </div>
                    <div class="col-sm-4 d-flex flex-row-reverse">
                      <p class="align-self-end" v-if="medianData">
                        <b>{{ medianData.competitionScore.region }}</b>
                      </p>
                      <p class="align-self-end" v-else>
                        <b>-</b>
                      </p>
                    </div>
                  </div>

                  <div class="region row mb-1 mt-0">
                    <div class="col-sm-8">
                      <p class="region-title">Sub-Region</p>
                      <v-tooltip right v-if="showLocationImagesOnMedianDataSection">
                        <template v-slot:activator="{ on, attrs }">
                          <p v-bind="attrs" v-on="on">
                            {{ location.subregion }}
                          </p>
                        </template>
                        <div v-html="subRegionTooltipContent"></div>
                      </v-tooltip>
                    </div>
                    <div class="col-sm-4 d-flex flex-row-reverse">
                      <p class="align-self-end" v-if="medianData">
                        <b>{{ medianData.competitionScore.subregion }}</b>
                      </p>
                      <p class="align-self-end" v-else>
                        <b>-</b>
                      </p>
                    </div>
                  </div>

                  <div class="region row mb-1 mt-0">
                    <div class="col-sm-8">
                      <p>Similiar Sized</p>
                      <p>
                        {{ determineLocationType }}
                        <span
                          ><TooltipIcon :tip="similarSizedTip" :tooltipTitle="similarSizedTitle"
                        /></span>
                      </p>
                    </div>
                    <div class="col-sm-4 d-flex flex-row-reverse">
                      <p class="align-self-end" v-if="medianData">
                        <b>{{ medianData.competitionScore.similiar }}</b>
                      </p>
                      <p class="align-self-end" v-else>
                        <b>-</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div id="driver-interest">
                <StatPointGraph
                  :value="locationOverview.driverInterestScore"
                  :percentValue="locationOverview.driverInterestScoreFinal"
                  :title="'Driver Interest'"
                  subTitle="Compared to the National Average"
                  :tooltip="statSources.driverInterestScore"
                  :popoverContent="popovers.driverInterestScore"
                  chartId="driverInterestScore"
                  colorBarDirection="left"
                  :colorScaleReversed="false"
                  :minRange="-100"
                  :maxRange="100"
                  :thrashHoldOne="-33"
                  :thrashHoldTwo="33"
                  :barHeight="30"
                  :pointWidth="14"
                  :locationCode="locationCode"
                  :locationType="locationType"
                  :tooltipPosition="'left'"
                />

                <div class="median-header-container">
                  <p class="median-breakdown-header text-right">Median Value</p>
                </div>

                <div class="median-breakdown">
                  <div class="region row mb-1">
                    <div class="col-sm-8">
                      <p class="region-title">Region</p>
                      <v-tooltip right v-if="showLocationImagesOnMedianDataSection">
                        <template v-slot:activator="{ on, attrs }">
                          <p v-bind="attrs" v-on="on">
                            {{ location.region }}
                          </p>
                        </template>
                        <div v-html="regionTooltipContent"></div>
                      </v-tooltip>
                    </div>
                    <div class="col-sm-4 d-flex flex-row-reverse">
                      <p class="align-self-end" v-if="medianData">
                        <b>{{ medianData.driverInterestScore.region }}</b>
                      </p>
                      <p class="align-self-end" v-else>
                        <b>-</b>
                      </p>
                    </div>
                  </div>

                  <div class="region row mb-1 mt-0">
                    <div class="col-sm-8">
                      <p class="region-title">Sub-Region</p>
                      <v-tooltip right v-if="showLocationImagesOnMedianDataSection">
                        <template v-slot:activator="{ on, attrs }">
                          <p v-bind="attrs" v-on="on">
                            {{ location.subregion }}
                          </p>
                        </template>
                        <div v-html="subRegionTooltipContent"></div>
                      </v-tooltip>
                    </div>
                    <div class="col-sm-4 d-flex flex-row-reverse">
                      <p class="align-self-end" v-if="medianData">
                        <b>{{ medianData.driverInterestScore.subregion }}</b>
                      </p>
                      <p class="align-self-end" v-else>
                        <b>-</b>
                      </p>
                    </div>
                  </div>

                  <div class="region row mb-1 mt-0">
                    <div class="col-sm-8">
                      <p>Similiar Sized</p>
                      <p>
                        {{ determineLocationType }}
                        <span
                          ><TooltipIcon :tip="similarSizedTip" :tooltipTitle="similarSizedTitle"
                        /></span>
                      </p>
                    </div>
                    <div class="col-sm-4 d-flex flex-row-reverse">
                      <p class="align-self-end" v-if="medianData">
                        <b>{{ medianData.driverInterestScore.similiar }}</b>
                      </p>
                      <p class="align-self-end" v-else>
                        <b>-</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion>
          <hr />
          <Accordion>
            <template v-slot:title>
              <h1 class="accordion-title">Driver Population Stats</h1>
              <span class="accordion-summary" v-if="sectionHelperTextStateIsActive">{{
                driverPopulation.summaryText
              }}</span>
            </template>

            <div class="wrapped-accordion-summary" v-if="sectionHelperTextStateIsActive">
              <span>{{ driverPopulation.summaryText }}</span>
            </div>

            <div class="stat-row five-columns">
              <StatPointGraph
                id="unique-respondents"
                :value="driverPopulation.uniqueRespondents"
                :percentValue="driverPopulation.uniqueRespondents12MoPercentile"
                :title="'Unique Respondents'"
                subTitle="Randall Reilly, past 12 months"
                :tooltip="statSources.uniqueRespondents"
                chartId="driverPopulationUniqueRespondents"
                colorBarDirection="left"
                :colorScaleReversed="false"
                :minRange="0"
                :maxRange="100"
                :thrashHoldOne="33"
                :thrashHoldTwo="67"
                :barHeight="30"
                :popoverContent="popovers.randallReillyUniqueRespondentsPastYear"
                :locationCode="locationCode"
                :locationType="locationType"
                :pointWidth="14"
              />

              <StatPointGraph
                id="unique-driver-contacts"
                :value="driverPopulation.driverContacts"
                :percentValue="driverPopulation.audienceCountAlltimePercentile"
                :title="'Unique Driver Contacts'"
                subTitle="Randall Reilly, past 5 years"
                :tooltip="statSources.driverContacts"
                chartId="driverPopulationDriverContacts"
                colorBarDirection="left"
                :colorScaleReversed="false"
                :minRange="0"
                :maxRange="100"
                :thrashHoldOne="33"
                :thrashHoldTwo="67"
                :barHeight="30"
                :popoverContent="popovers.randallReillyDriverContactsPastFiveYr"
                :locationCode="locationCode"
                :locationType="locationType"
                :pointWidth="14"
              />

              <StatPointGraph
                id="trucking-employment-count"
                :value="driverPopulation.truckingEmploymentCount"
                :percentValue="driverPopulation.truckingEmploymentCountPercentile"
                :title="'Trucking Employment Count'"
                :tooltip="statSources.truckingEmploymentCount"
                chartId="driverPopulationTruckingEmploymentCount"
                colorBarDirection="left"
                :colorScaleReversed="false"
                :minRange="0"
                :maxRange="100"
                :thrashHoldOne="33"
                :thrashHoldTwo="67"
                :barHeight="30"
                :popoverContent="popovers.truckingEmploymentCount"
                :locationCode="locationCode"
                :locationType="locationType"
                :pointWidth="14"
              />

              <StatPointGraph
                id="labor-force-size"
                :value="driverPopulation.laborForceSize"
                :percentValue="driverPopulation.laborForceSizePercentile"
                :title="'Labor Force Size'"
                :tooltip="statSources.laborForceSize"
                chartId="driverPopulationLaborForceSize"
                colorBarDirection="left"
                :colorScaleReversed="false"
                :minRange="0"
                :maxRange="100"
                :thrashHoldOne="33"
                :thrashHoldTwo="67"
                :barHeight="30"
                :popoverContent="popovers.laborForceSize"
                :locationCode="locationCode"
                :locationType="locationType"
                :pointWidth="14"
              />

              <StatPointGraph
                id="trucking-employment-to-labor-force-ratio"
                :value="driverPopulation.tlfRatio"
                :percentValue="driverPopulation.laborforceEmpRatioPercentile"
                :title="'Trucking Employment to Labor Force Ratio'"
                :tooltip="statSources.tlfRatio"
                chartId="driverPopulationTlfRatio"
                colorBarDirection="right"
                :colorScaleReversed="false"
                :minRange="0"
                :maxRange="100"
                :thrashHoldOne="33"
                :thrashHoldTwo="67"
                :barHeight="30"
                :popoverContent="popovers.truckingEmploymentToLaborForceRatio"
                :locationCode="locationCode"
                :locationType="locationType"
                :pointWidth="14"
                :tooltipPosition="'left'"
              />
            </div>
          </Accordion>
          <hr />
          <Accordion>
            <template v-slot:title>
              <h1 class="accordion-title">Competition Stats</h1>
              <span class="accordion-summary" v-if="sectionHelperTextStateIsActive">{{
                competitionStats.summaryText
              }}</span>
            </template>

            <div class="wrapped-accordion-summary" v-if="sectionHelperTextStateIsActive">
              <span>{{ competitionStats.summaryText }}</span>
            </div>

            <div class="stat-row four-columns">
              <StatPointGraph
                id="active-job-postings"
                :value="competitionStats.activeJobPostings"
                :percentValue="competitionStats.activeJobPostingsPercentile"
                :title="'Active Job Postings'"
                :tooltip="statSources.activeJobPostings"
                chartId="competitionStatsActiveJobPostings"
                colorBarDirection="left"
                :colorScaleReversed="true"
                :minRange="0"
                :maxRange="100"
                :thrashHoldOne="33"
                :thrashHoldTwo="67"
                :barHeight="30"
                :popoverContent="popovers.activeJobPostings"
                :locationCode="locationCode"
                :locationType="locationType"
                :pointWidth="14"
              />

              <StatPointGraph
                id="companies-with-a-job-posting"
                :value="competitionStats.companiesWithJobPostings"
                :percentValue="competitionStats.activeJobPostingCompaniesPercentile"
                :title="'Companies with a Job Posting'"
                subTitle="Past 3 months"
                :tooltip="statSources.companiesWithJobPostings"
                chartId="competitionStatsCompaniesWithJobPostings"
                colorBarDirection="left"
                :colorScaleReversed="true"
                :minRange="0"
                :maxRange="100"
                :thrashHoldOne="33"
                :thrashHoldTwo="67"
                :barHeight="30"
                :popoverContent="popovers.companiesWithAJobPostingPastThreeMo"
                :locationCode="locationCode"
                :locationType="locationType"
                :pointWidth="14"
              />

              <StatPointGraph
                id="trucking-companies-domiciled-in-area"
                :value="competitionStats.truckingEmployerCount"
                :percentValue="competitionStats.employerCountTruckingPercentile"
                :title="'Trucking Companies Domiciled in Area'"
                :tooltip="statSources.truckingEmployerCount"
                chartId="competitionStatsTruckingEmployerCount"
                colorBarDirection="left"
                :colorScaleReversed="true"
                :minRange="0"
                :maxRange="100"
                :thrashHoldOne="33"
                :thrashHoldTwo="67"
                :barHeight="30"
                :popoverContent="popovers.truckingCompaniesDomiciledInArea"
                :locationCode="locationCode"
                :locationType="locationType"
                :pointWidth="14"
              />

              <StatPointGraph
                id="overall-unemployment-rate"
                :value="competitionStats.unemploymentRate"
                :percentValue="competitionStats.unemploymentRatePercentile"
                :title="'Overall Unemployment Rate'"
                :tooltip="statSources.unemploymentRate"
                :format="'percentage'"
                chartId="competitionStatsUnemploymentRate"
                colorBarDirection="left"
                :colorScaleReversed="false"
                :minRange="0"
                :maxRange="100"
                :thrashHoldOne="33"
                :thrashHoldTwo="67"
                :barHeight="30"
                :popoverContent="popovers.overallUnemploymentRate"
                :locationCode="locationCode"
                :locationType="locationType"
                :pointWidth="14"
                :tooltipPosition="'left'"
              />
            </div>
          </Accordion>
          <hr />
          <Accordion>
            <template v-slot:title>
              <h1 class="accordion-title">Driver Market Stats</h1>
              <span class="accordion-summary" v-if="sectionHelperTextStateIsActive">{{
                driverMarket.summaryText
              }}</span>
            </template>

            <div class="wrapped-accordion-summary" v-if="sectionHelperTextStateIsActive">
              <span>{{ driverMarket.summaryText }}</span>
            </div>

            <div class="stat-row four-columns">
              <StatPointGraph
                id="attributed-hires"
                :value="driverMarket.totalHires"
                :percentValue="driverMarket.hireCount12MoPercentile"
                :title="'Attributed Hires'"
                subTitle="Randall Reilly, past 12 months"
                :tooltip="statSources.totalHires"
                chartId="driverMarketTotalHires"
                colorBarDirection="left"
                :colorScaleReversed="false"
                :minRange="0"
                :maxRange="100"
                :thrashHoldOne="33"
                :thrashHoldTwo="67"
                :barHeight="30"
                :popoverContent="popovers.randallReillyAttributedHires"
                popoverTitle="Randall Reilly Attributed Hires (past 12 months)"
                :locationCode="locationCode"
                :locationType="locationType"
                :pointWidth="14"
              />

              <StatPointGraph
                id="aggregated-lead-count"
                :value="driverMarket.leadCount"
                :percentValue="driverMarket.leadCount12MoPercentile"
                :title="'Aggregated Lead Count'"
                subTitle="Randall Reilly, past 12 months"
                :tooltip="statSources.leadCount"
                chartId="driverMarketLeadCount"
                colorBarDirection="left"
                :colorScaleReversed="false"
                :minRange="0"
                :maxRange="100"
                :thrashHoldOne="33"
                :thrashHoldTwo="67"
                :barHeight="30"
                :popoverContent="popovers.randallReillyLeadCountPastYear"
                popoverTitle="Randall Reilly Lead Count (past 12 months)"
                :locationCode="locationCode"
                :locationType="locationType"
                :pointWidth="14"
              />

              <StatPointGraph
                id="median-advertised-salary-from-job-postings"
                :value="driverMarket.medianAdvertisedSalary"
                :percentValue="driverMarket.medianAdvertisedSalaryPercentile"
                :title="'Median Advertised Salary from Job Postings'"
                :tooltip="statSources.medianAdvertisedSalary"
                :format="'money'"
                chartId="driverMarketMedianAdvertisedSalary"
                colorBarDirection="left"
                :colorScaleReversed="true"
                :minRange="0"
                :maxRange="100"
                :thrashHoldOne="33"
                :thrashHoldTwo="67"
                :barHeight="30"
                :popoverContent="popovers.medianAdvertisedSalaryFromJobPostings"
                popoverTitle="Median Advertised Salary from Job Postings"
                :locationCode="locationCode"
                :locationType="locationType"
                :pointWidth="14"
              />

              <StatPointGraph
                id="average-reported-salary"
                :value="driverMarket.averageReportedSalary"
                :percentValue="driverMarket.averageReportedSalaryPercentile"
                :title="'Average Reported Salary'"
                :tooltip="statSources.averageReportedSalary"
                :format="'money'"
                chartId="driverMarketAverageReportedSalary"
                colorBarDirection="left"
                :colorScaleReversed="true"
                :minRange="0"
                :maxRange="100"
                :thrashHoldOne="33"
                :thrashHoldTwo="67"
                :barHeight="30"
                :popoverContent="popovers.averageReportedSalary"
                popoverTitle="Average Reported Salary"
                :locationCode="locationCode"
                :locationType="locationType"
                :pointWidth="14"
                :tooltipPosition="'left'"
              />
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import StatPointGraph from '../Market/StatPointGraph.vue';
import Accordion from '../Market/Accordion.vue';
import OverallRecruitingScore from './OverallRecruitingScore.vue';
import LoadingSpinner from '../StyledComponents/LoadingSpinner.vue';
import LocationListings from '../StyledComponents/LocationListings.vue';
import TooltipIcon from '../Market/TooltipIcon.vue';
import LocationMap from './LocationMap.vue';

export default {
  name: 'Locations',
  components: {
    LocationListings,
    Accordion,
    StatPointGraph,
    OverallRecruitingScore,
    LoadingSpinner,
    TooltipIcon,
    LocationMap,
  },
  data() {
    return {
      locationType: '',
      locationCode: '',
      locationMapData: {},
      locationSelected: {},
      selectLocation: '',
      locationBreakdownLoading: false,
      pageValue: {
        link: {
          title: 'locations',
          linkId: 'Locations',
          mobileID: 'mobile-nav-locations',
          section: 'driver',
        },
      },
      similarSizedTip:
        'Similar-sized locations are those with a labor force size within ±12 percentiles of the selected location.',
      statSources: {
        estimatedCPL: [
          '<p class="extra-info">Lead costs are compiled from Randall Reilly CDL driver campaigns through major advertising channels. Data is given by designated market area (DMA), so metropolitan statistical areas (MSAs) in the same DMA will show the same estimated CPL.</p>',
          '<p class="source"><i><b>Source:</b> Aggregation of thousands of individual CDL driver recruiting campaigns.</i></p>',
        ],
        lthRatio: '<p class="source"><i><b>Source:</b> Aggregation of thousands of individual CDL driver recruiting campaigns ran by Randall Reilly.</i></p>',
        estimatedCPH: [
          '<p class="extra-info">Cost-per-hire estimates are calculated by multiplying the estimated cost per lead by the lead-to-hire ratio.</p>',
          '<p class="source"><i><b>Source:</b> Aggregation of thousands of individual CDL driver recruiting campaigns.</i></p>',
        ],
        competitionScore: [
          '<p class="extra-info">Posting competition scores are calculated by first finding the ratio of truck driver job postings to the number of people employed in truck transportation within a given area. This ratio is then compared to the nationwide ratio.</p>',
          `<p class="source"><i><b>Sources:
            </b> Programmatically gathered job postings from across the Internet combined with the Bureau of Labor Statistics' Quarterly Census of Employment and Wages (QCEW) dataset.</i></p>`,
        ],
        driverInterestScore: [
          `<p class="extra-info">
            Driver Interest Scores are calculated by finding the ratio of Randall Reilly's truck driver digital marketing campaign respondents to the number of
            people employed in truck transportation within a geographic area.
            This ratio is then compared to the national ratio with adjustments made based on Randall Reilly's advertising footprint in an area.</p>`,
          `<p class="source"><i><b>Sources:</b>
            Aggregation of thousands of individual CDL driver recruiting campaigns cross-referenced with the Bureau of Labor Statistics' Quarterly Census of Employment
            and Wages (QCEW) dataset.</i></p>`,
        ],
        uniqueRespondents: [
          '<p class="extra-info">The count of unique respondents is found by deduplicating lead counts based on the phone number associated with the lead.</p>',
          '<p class="source"><i><b>Source:</b> Aggregation of thousands of individual CDL driver recruiting campaigns run by Randall Reilly.</i></p>',
        ],
        driverContacts: [
          '<p class="extra-info">The count of driver contacts is found by deduplicating lead counts based on the phone number associated with the lead.</p>',
          '<p class="source"><i><b>Source:</b> Aggregation of thousands of individual CDL driver recruiting campaigns run by Randall Reilly.</i></p>',
        ],
        truckingEmploymentCount: `<p class="source"><i><b>Source:</b> The Bureau of Labor Statistics' Quarterly Census of Employment and Wages (QCEW) dataset.</i></p>`,
        laborForceSize: `<p class="source"><i><b>Source:</b> The Bureau of Labor Statistics' Local Area Unemployment (LAU) dataset.</i></p>`,
        tlfRatio: [
          '<p class="extra-info">The trucking employment to labor force ratio is the number of truck transportation employees compared to the overall labor force in a given geographic area.</p>',
          `<p class="source"><i><b>Sources:</b> The Bureau of Labor Statistics' Quarterly Census of Employment and Wages (QCEW) and Local Area Unemployment (LAU) datasets.</i></p>`,
        ],
        activeJobPostings:
          '<p class="source"><i><b>Source:</b> Programmatically gathered job postings from across the Internet.</i></p>',
        companiesWithJobPostings:
          '<p class="source"><i><b>Source:</b> Programmatically gathered job postings from across the Internet.</i></p>',
        truckingEmployerCount: `<p class="source"><i><b>Source:</b>The Bureau of Labor Statistics' Quarterly Census of Employment and Wages (QCEW) dataset.</i></p>`,
        unemploymentRate: `<p class="source"><i><b>Source:</b>The Bureau of Labor Statistics' Local Area Unemployment (LAU) dataset.</i></p>`,
        totalHires:
          '<p class="source"><i><b>Source:</b> Aggregation of thousands of individual CDL driver recruiting campaigns run by Randall Reilly.</i></p>',
        leadCount: [
          '<p class="extra-info">Lead counts are determined by summing the number of unique leads by client and month.</p>',
          '<p class="source"><i><b>Source:</b> Aggregation of thousands of individual CDL driver recruiting campaigns run by Randall Reilly.</i></p>',
        ],
        medianAdvertisedSalary:
          '<p class="source"><i><b>Source:</b> Programmatically gathered job postings from across the Internet.</i></p>',
        averageReportedSalary: `<p class="source"><i><b>Source:</b> The Bureau of Labor Statistics' Quarterly Census of Employment and Wages (QCEW) dataset.</i></p>`,
      },
      sectionHelperTextState: false,
      locationMapVisible: false,
    };
  },
  async created() {
    let data = sessionStorage.getItem('lastLocationSearched');
    data = JSON.parse(data);
    await this.initialLoad(data);
    this.locationMapVisible = true;
  },
  mounted() {
    const data = JSON.stringify(this.pageValue.link);
    sessionStorage.setItem('lastPageViewed', data);
    this.$store.dispatch('global/addPageLinkHighlightAndNavLinkBorder', this.pageValue);
  },
  watch: {
    selectLocation(location) {
      if (location !== null) {
        this.searchLocation(location);
      }
    },
  },
  computed: {
    regionTooltipContent() {
      return `
        <p class='region-title'>${this.location.region} Region</p>
        <div class='map-image-wrapper'>
          <img
            alt='help icon'
            src='${this.getMapImage(this.location.region)}'
          />
        </div>
      `;
    },
    subRegionTooltipContent() {
      return `
        <p class='region-title'>${this.location.subregion} Sub-Region</p>
        <div class='map-image-wrapper'>
          <img
            alt='help icon'
            src='${this.getMapImage(this.location.subregion)}'
          />
        </div>
      `;
    },
    similarSizedTitle() {
      return `Similar Sized ${this.determineLocationType}`;
    },
    determineLocationType() {
      switch (this.locationType) {
        case 'state':
          return 'States';
        case 'county':
          return 'Counties';
        case 'msa':
          return 'Metro Areas';
        default:
          return '';
      }
    },
    location() {
      return this.$store.state.locations.locationBreakdown;
    },
    locationOverview() {
      return this.location.locationOverview;
    },
    isLocationMapVisible() {
      return this.locationMapVisible;
    },
    driverPopulation() {
      return this.location.driverPopulation;
    },
    competitionStats() {
      return this.location.competitionStats;
    },
    driverMarket() {
      return this.location.driverMarket;
    },
    recruitingDifficultyScore() {
      return this.location.recruitingDifficultyScore;
    },
    medianData() {
      return this.location.medianData;
    },
    popovers() {
      return this.location.popovers;
    },
    ...mapGetters({
      isLoading: 'locations/isLoading',
    }),
    hasBreakdownData() {
      return !this.isLoading && !!this.location;
    },
    sectionHelperTextStateIsActive() {
      return this.sectionHelperTextState;
    },
    showLocationImagesOnMedianDataSection() {
      switch (this.locationType) {
        case 'nationwide':
          return false;
        case 'region':
          return false;
        case 'subregion':
          return false;
        default:
          return true;
      }
    },
    notLocationType() {
      switch (this.locationType) {
        case 'nationwide':
          return false;
        case 'region':
          return false;
        case 'subregion':
          return false;
        default:
          return true;
      }
    },
  },
  methods: {
    async initialLoad(data) {
      // grab last search
      const test = data !== null;
      if (test) {
        // IF there was a last search, search for the last searched results
        this.selectLocation = data;
      } else {
        // else grab first item in locations and search for it
        const location = {
          location_name: 'Alabama (AL)',
          location_type: 'state',
          location_code: '01',
          location_code_geoid: '01',
        };
        this.selectLocation = location;
      }
    },
    async searchLocation(data) {
      this.locationType = data.location_type;
      this.locationSelected = data;
      this.selectLocation = data;
      sessionStorage.setItem('lastLocationSearched', JSON.stringify(data));
      await this.$store.dispatch('locations/getLocationBreakdown', {
        location_code: data.location_code,
      });
    },
    getMapImage(area) {
      try {
        // eslint-disable-next-line
        return require(`../../assets/maps/${area}.png`);
      } catch (e) {
        return '';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.locations-filters-buttons-area {
  flex-direction: row;
  display: flex;
  align-items: center;

  .switch-label {
    font-size: 1.1rem;
  }
}
.locations-grid {
  height: 100%;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}
.main-content {
  width: 90%;
  margin-top: 3%;
}
.accordion-parent {
  margin-top: 32px;
}
.location-filter-wrapper {
  width: 100%;
  min-height: 150px;
  position: sticky;
  position: -webkit-sticky; // For safari
  top: 65px;
  z-index: 9999;
  background: #f9f9f9;
  display: flex;
  align-items: center;
}
.locations-filters {
  display: flex;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.location-selected-select {
  flex: 1;
}
.overallRecruitingScoreWrapper {
  flex: 1;
  min-width: 400px;
  margin: 1rem auto;
}
.filter-style {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
}
.location-selected-select::v-deep .md-field.md-has-value .md-menu input {
  font-size: 22px !important;
  font-weight: bold !important;
}
.locations-search-button {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 100px;
}
.locations-filter-request-company-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 100px;
}
.locations-autocomplete-divider {
  border-bottom: 1px black solid;
  margin-right: 20px;
  margin-top: 10px;
}
.locations-autocomplete-header {
  font-weight: 500;
  font-size: 16px;
}
.locations-autocomplete-subheader {
  font-weight: 400;
  font-size: 12px;
  font-style: italic;
}
.stat-row {
  padding-bottom: 1.5rem;
  display: grid;
  grid-template-rows: auto;
  column-gap: 5px;
}
.stat-row > * {
  margin: 16px 5px;
  border: 1px solid #efefef;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.1);
}
.stat-row.five-columns {
  grid-template-columns: repeat(5, 1fr);
}
.stat-row.four-columns {
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 1250px) {
  .stat-row.five-columns {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
  }
  .stat-row.four-columns {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
  }
}
@media (max-width: 1150px) {
  .locations-filters-buttons-area {
    margin: 0.2rem 0;
  }
  .stat-row.five-columns {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 950px) {
  .stat-row.five-columns > *,
  .stat-row.four-columns > * {
    flex-basis: 50%;
  }
}
@media (max-width: 865px) {
  .location-selected-select {
    margin: 1rem auto;
  }
  .locations-filters-buttons-area {
    flex: 1;
  }
  .locations-filters {
    flex-direction: column;
  }
  .location-selected-select {
    width: 100%;
  }
  .badge-margins {
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 650px) {
  .stat-row.five-columns > *,
  .stat-row.four-columns > * {
    flex-basis: 100%;
  }
}
@media (max-width: 466px) {
  .overallRecruitingScoreWrapper {
    min-width: auto;
  }
  .score-container {
    flex-direction: column;
  }
}
.median-breakdown {
  text-align: left;
  margin: 0 10% 10% 10%;
  display: flex;
  flex-direction: column;
}
.median-breakdown-header {
  padding-left: 16px;
  font-size: 12px;
  font-weight: 500;
  color: #666666;
}
.median-breakdown .median-value {
  font-size: 16px;
  word-break: break-word;
  margin: 6px 0 6px 16px;
}
.median-breakdown .region {
  font-size: 16px;
}
.median-header-container {
  margin: 0 10% 0% 10%;
  p {
    margin-bottom: 0 !important;
  }
}
.median-breakdown {
  .region {
    p {
      margin-bottom: 0 !important;
      font-weight: normal !important;
      color: #666666 !important;
    }
    .region-title {
      font-size: 13px;
      text-align: left;
    }
  }
}
.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-left: 28px;
}
.accordion-title {
  text-align: left;
  min-width: 255px;
  font-size: 1.5rem !important;
  text-shadow: none;
  color: #666666;
  margin-top: 3%;
}
.accordion-summary {
  font-size: 14px;
  text-align: justify;
  font-style: italic;
  margin-left: 5%;
  max-width: 700px;
}
.wrapped-accordion-summary {
  margin-left: 32px;
  margin-right: 32px;
  font-size: 14px;
  font-style: italic;
  text-align: justify;
}
@media (max-width: 800px) {
  .main-content {
    margin-top: 15%;
  }
}
@media (min-width: 1026px) {
  .wrapped-accordion-summary {
    display: none !important;
  }
}
@media (max-width: 1025px) {
  .accordion-summary {
    display: none !important;
  }
}
.v-item--span {
  font-size: 22px !important;
}
.badge-font {
  font-size: 1rem;
  font-weight: 700;
}
.badge-color {
  color: #abd037;
  background: #666666;
}
.badge-margins {
  margin-bottom: 6rem;
}
hr {
  overflow: visible;
  height: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
