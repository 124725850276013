<template>
  <div class="score-container">
    <div class="score-title-wrapper">
      <div class="score-title">
        <span
          title="Overall Recruiting Competition Score"
          >Recruiting Difficulty Score</span
        >
        <TooltipIcon>
          Scores are presented on a sliding scale. Locations with scores of 1 are considered
          to be easier to hire in, relative to other locations. Conversely, locations with
          scores of 10 are considered to be more difficult to hire in.
          <br/>
          <br/>
          <i>
            The Competition Score is calculated by a weighted formula that accounts for
            estimated lead costs, hire rates, posting competition, and driver interest in a
             given area.
          </i>
        </TooltipIcon>
      </div>
      <div class="score-info">Out of 10</div>
    </div>
    <div class="score-bar">
      <score-progress-circle
        :score="roundOffScore"
        id="recruiting-difficulty-score-value"
      />
    </div>
  </div>
</template>

<script>
import ScoreProgressCircle from '../Market/ScoreProgressCircle.vue';
import TooltipIcon from '../Market/TooltipIcon.vue';

export default {
  name: 'OverallRecruitingScore',
  components: {
    TooltipIcon,
    ScoreProgressCircle,
  },
  props: {
    score: String,
  },
  data() {
    return {
      gradient: {
        radial: false,
        colors: [
          {
            color: '#70A300',
            offset: '0',
            opacity: '1',
          },
          {
            color: '#1B87C0',
            offset: '50',
            opacity: '1',
          },
          {
            color: '#BC2333',
            offset: '100',
            opacity: '1',
          },
        ],
      },
    };
  },
  computed: {
    roundOffScore() {
      return Math.round(parseInt(this.score, 10), 0);
    },
  },
};
</script>

<style scoped>
.score-container {
  text-align: start;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.score-title-wrapper {
  margin-left: 1.5rem;
}

.score-title {
  font-size: 1.2rem;
  line-height: 1.2;
  vertical-align: middle;
  color: #000;
  font-weight: normal;
}

.score-info {
  font-size: 12px;
  color: #666666;
}

.score-bar {
  width: 100px;
  height: 100px;
}

.score-bar::v-deep .legend-custom-style {
  font-weight: bold;
  color: #1B87C0;
  font-size: 3.5rem;
  text-align: center;
}

.source-info {
  font-size: 18px !important;
}

.score-graph {
  margin-top: 8px;
}

.locations-custom-popover {
  text-align: justify;
  -webkit-box-shadow: 0px 1px 20px 4px rgba(59, 59, 59, 0.2);
  -moz-box-shadow: 0px 1px 20px 4px rgba(59, 59, 59, 0.2);
  box-shadow: 0px 1px 20px 4px rgba(59, 59, 59, 0.2);
  background: #eeeeee;
  transform: translate3d(619px, 395px, 0px);
}
</style>
