<template>
  <v-menu
    offset-y
    class="rounded-0"
    absolute
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-badge
          v-if="notificationBadge > 0"
          :content="notificationBadge"
          color="red"
          overlap
          class="notification-badge"
        >
          <v-icon size="18px" color="#cfcfcf" aria-label="Notification Icon">mdi-bell</v-icon>
        </v-badge>
        <v-icon v-else size="18px" color="#cfcfcf" aria-label="Notification Icon">mdi-bell</v-icon>
      </div>

    </template>
    <v-card width="600px" class="notifications-menu">
      <v-list color="grey darken-1" dark>
        <v-list-item class="user-notifications-content">
          <v-container>
            <v-row>
              <v-col>
                <h5 class="notifications-list-header-text">NOTIFICATIONS</h5>
              </v-col>
              <v-col class="text-right">
                <v-btn id="read-all" plain @click.prevent="readAll()">
                  Read All
                </v-btn>
              </v-col>
            </v-row>
            <div class="notification-line" />
            <v-row class="mt-0">
              <v-col>
                <div v-if="notifications.length > 0">
                  <div
                    v-for="notification in notifications"
                    :key="notification.id"
                    style="height: auto"
                  >
                    <div class="notification-item-grid">
                      <v-row style="display: flex;" class="mt-0">
                        <v-col class="text-subtitle-1">{{ notification.title }}</v-col>
                        <div class="unread-notification-area">
                          <div
                            v-if="!notification.read"
                            class="unread-notification"
                            @click="markAsRead(notification.id)"
                          />
                        </div>
                      </v-row>
                      <v-row class="mb-0">
                        <h6
                          v-if="!notification.readMoreActivated"
                          class="notification-message"
                        >
                          {{ notification.text.slice(0, 100) }}
                          <a
                            v-if="notification.text.length >= 100"
                            href="#"
                            @click.stop.prevent="toggleReadMore(notification.id)"
                            class="read-more-link"
                          ><em>Read More...</em>
                          </a>
                        </h6>
                      </v-row>
                      <div
                        v-if="notification.readMoreActivated"
                        class="notification-message"
                      >
                        {{ notification.text }}
                        <a
                          href="#"
                          @click.stop.prevent="toggleReadMore(notification.id)"
                          class="read-more-link"
                        >Read Less...
                        </a>
                      </div>
                      <div class="notification-bottom-border" />
                    </div>
                  </div>
                </div>
                <div v-else class="zero-notifications">
                  You are all caught up &#127881;
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'Notifications-FullSize',
  data() {
    return {
      page: 1,
      open: null,
    };
  },
  computed: {
    notifications() {
      return this.$store.state.notifications.userNotifications;
    },
    notificationBadge() {
      return this.$store.state.notifications.userTotal;
    },
    notificationLastPage() {
      return this.$store.state.notifications.last_page;
    },
    notificationsLoading() {
      return this.$store.state.notifications.loading;
    },
    silence_notifications() {
      return this.$store.state.notifications.silence_notifications;
    },
    noOverflow() {
      return this.notifications.length <= 4;
    },
  },
  methods: {
    markAsRead(id) {
      this.$store.dispatch('notifications/markAsRead', id);
    },
    openNotifications() {
      this.$store.commit('notifications/updateModalOpen', true);
    },
    readAll() {
      this.$store.dispatch('notifications/readAllNotifications');
    },
    loadNotifications() {
      if (!this.silence_notifications) {
        if (this.page === 1 || !this.open) {
          this.$store.dispatch('notifications/loadNotifications');
        }
      }
    },
    infiniteScroll() {
      const listGroup = document.querySelector('#list-group');
      const lastA = listGroup.querySelector('a > :last-child');
      const lastAOffset = lastA.offsetTop + lastA.clientHeight - 400;
      const notificationsContainer = document.getElementById('list-group');
      const containerToTop = notificationsContainer.scrollTop;
      if (
        !this.notificationLastPage
        && !this.notificationsLoading
        && containerToTop > lastAOffset
      ) {
        this.page += 1;
        this.$store.dispatch('notifications/loadNotifications', this.page);
      }
    },
    toggleReadMore(id) {
      this.$store.dispatch('notifications/toggleReadMore', id);
    },
  },
};
</script>

<style scoped>
.v-menu__content {
  top: 7% !important;
}
.notifications-menu {
  height: 75vh;
  overflow-y: scroll;
  background-color: #424242 !important;
  color: white;
}
.notification-item-grid {
  padding: 0rem 1rem;
  align-items: self-start;
}
.notification-line {
  border-bottom: 1px #efefef solid;
}
.notifications-list-header-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgb(255, 255, 255);
  padding-left: 20px;
}
.icon-area {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.notifications-icon {
  color: #70a300 !important;
}
.notification-title {
  font-weight: 400;
}
.notification-message {
  white-space: pre-wrap;
  margin-bottom: 0.7rem;
  text-align: justify;
}
.read-more-link {
  color: #fff !important;
  font-weight: 400 !important;
}
.unread-notification-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}
.unread-notification {
  width: 20px;
  height: 20px;
  background: #70a300;
  border-radius: 50%;
  cursor: pointer;
}
.unread-notification:hover {
  transform: scale(1.15);
  background: rgb(128, 177, 28);
}
.notification-bottom-border {
  border-bottom: 0.5px gray solid;
}
.zero-notifications {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #424242;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background-color: #333;
}
@media (max-width: 767px) {
  .list-group-item {
    color: white;
    background: #435c6e;
  }
  #icon {
    display: none;
  }
  #icon-text {
    visibility: visible;
    margin: 0px;
  }
}
@media (min-width: 768px) {
  #icon {
    visibility: visible;
  }
  #icon-text {
    display: none;
  }
}
</style>
