/* ============
 * Global Module
 * ============
 */

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// initial state
const state = () => ({
  driverOrMechanicData: 'Driver',
  showModal: false,
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
