export default {
  setCompanies(state, companies) {
    state.companies = companies;
  },
  setHasMoreCompanies(state, hasMoreCompanies) {
    state.hasMoreCompanies = hasMoreCompanies;
  },
  resetCompanies(state, companies) {
    state.companies = companies;
  },
  setTitleJobsAndCompaniesData(state, jobPostingsAndUniqueCompanies) {
    state.jobPostingsAndUniqueCompaniesData = jobPostingsAndUniqueCompanies;
  },
  setTitleMedianAdvertisedTimeframeWageData(state, medianAdvertisedTimeframeWage) {
    state.medianAdvertisedTimeframeWageData = medianAdvertisedTimeframeWage;
  },
  setInputtedCompanyDistributionStatistics(state, inputtedCompanyDistributionStatistics) {
    state.inputtedCompanyDistributionStatistics = inputtedCompanyDistributionStatistics;
  },
  // eslint-disable-next-line max-len
  setInputtedCompanyDistributionStatisticsLoading(state, inputtedCompanyDistributionStatisticsLoading) {
    // eslint-disable-next-line max-len
    state.inputtedCompanyDistributionStatisticsLoading = inputtedCompanyDistributionStatisticsLoading;
  },
  setJobPostingsMapLoading(state, jobPostingsMapLoading) {
    state.jobPostingsMapLoading = jobPostingsMapLoading;
  },
  setGeographicalData(state, geographicalData) {
    state.geographicalData = geographicalData;
  },
  setGeographicalChartData(state, geographicalChartData) {
    state.geographicalChartData = geographicalChartData;
  },
  setInputtedYearlyWageRange(state, inputtedYearlyWageRange) {
    state.inputtedYearlyWageRange = inputtedYearlyWageRange;
  },
  setInputtedWeeklyWageRange(state, inputtedWeeklyWageRange) {
    state.inputtedWeeklyWageRange = inputtedWeeklyWageRange;
  },
  setInputtedHourlyWageRange(state, inputtedHourlyWageRange) {
    state.inputtedHourlyWageRange = inputtedHourlyWageRange;
  },
  setInputtedCPMWageRange(state, inputtedCPMWageRange) {
    state.inputtedCPMWageRange = inputtedCPMWageRange;
  },
  setCompanyName(state, inputtedCompanyName) {
    state.inputtedCompanyName = inputtedCompanyName;
  },
  setMinimumLoadingTimeAchieved(state, minimumLoadingTimeAchieved) {
    state.minimumLoadingTimeAchieved = minimumLoadingTimeAchieved;
  },
  setBonusData(state, bonusData) {
    state.bonusDataPresence = bonusData.length > 0;
    state.bonusData = bonusData;
  },
  setHometimeBenefitData(state, hometimeBenefitData) {
    state.hometimeBenefitDataPresence = hometimeBenefitData.length > 0;
    state.hometimeBenefitData = hometimeBenefitData;
  },
  setHometimeBenefitDataLoading(state, hometimeBenefitDataLoading) {
    state.hometimeBenefitDataLoading = hometimeBenefitDataLoading;
  },
  setBonusDataLoading(state, bonusDataLoading) {
    state.bonusDataLoading = bonusDataLoading;
  },
  setSelectedDistributionWageRangesUsed(state, selectedDistributionWageRangesUsed) {
    state.selectedDistributionWageRangesUsed = selectedDistributionWageRangesUsed;
  },
  setSelectedDistributionWageRangesLoading(state, selectedDistributionWageRangesLoading) {
    state.selectedDistributionWageRangesLoading = selectedDistributionWageRangesLoading;
  },
  setPostingsByCompany(state, postingsByCompany) {
    state.postingsByCompanyPresence = postingsByCompany.length > 0;
    state.postingsByCompany = postingsByCompany;
  },
  setPostingsByCompanyLoading(state, postingsByCompanyLoading) {
    state.postingsByCompanyLoading = postingsByCompanyLoading;
  },
  setSelectedDistributionWageRanges(state, selectedDistributionWageRanges) {
    state.selectedDistributionWageRanges = selectedDistributionWageRanges;
  },
  setAdvertisedSalaryDistribution(state, payload) {
    state.advertisedSalaryDistribution = payload;

    if (payload.advertised_salary_data && payload.advertised_salary_data.length !== 0) {
      state.advertisedSalaryDistributionPresence = true;
    } else {
      state.advertisedSalaryDistributionPresence = false;
    }
  },

  setAdvertisedWageRanges(state, payload) {
    state.advertisedWageRanges = payload;

    if (payload.length !== 0) {
      state.advertisedWageRangePresence = true;
    } else {
      state.advertisedWageRangePresence = false;
    }

    state.AdvertisedWageRangesLoading = false;
  },

  setAdvertisedWageRangesLoading(state, payload) {
    state.advertisedWageRangesLoading = payload;
  },

  setAdvertisedSalaryDistributionLoading(state, payload) {
    state.advertisedSalaryDistributionLoading = payload;
  },

  fetchBannerJobPostingsAndDistinctCompaniesDataLoading(state, payload) {
    state.fetchBannerJobPostingsAndDistinctCompaniesDataLoading = payload;
  },

  fetchBannerMedianAdvertisedTimeframeWageLoading(state, payload) {
    state.fetchBannerMedianAdvertisedTimeframeWageLoading = payload;
  },

  setUserWageChartDisplayState(state, payload) {
    state.showUserWageChart = payload;
  },

  setHometimePostingBenefitsDetailsData(state, hometimePostingBenefitsDetailsData) {
    state.hometimePostingBenefitsDetailsData = hometimePostingBenefitsDetailsData;
  },

  setHometimePostingBenefitsDetailsDataLoading(state, hometimePostingBenefitsDetailsDataLoading) {
    state.hometimePostingBenefitsDetailsDataLoading = hometimePostingBenefitsDetailsDataLoading;
  },
};
