<template>
  <div class="not-found-grid">
    <v-icon
      id="err-icon" class="error-icon center"
    >
      mdi-alert-circle-outline
    </v-icon>
    <h1 class="not-found-header center">404 Not Found!</h1>
    <p
      class="not-found-subtext center"
    >
      We couldn't find that URL. Click this button below to go back
    </p>
    <SButton
      class="not-found-button center"
      :action="'goBack'"
      :height="'80'"
      :text="'Back'"
      :width="'300'"
      :disableButton="false"
      @goBack="goBack"
    />
  </div>
</template>
<script>
import SButton from '../StyledComponents/Button.vue';

export default {
  name: 'NotFound',
  components: {
    SButton,
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  body{
    background: #ffffff;
    text-align: center;
    font-family: 'Droid Sans', sans-serif;
  }
  h1{
    margin-top: 30px;
    color: #70A300;
    text-shadow: 0px 2px 0px rgba(222, 222, 222, 1);
  }
  .not-found-grid {
    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-template-rows: 20% 10% 10% 5% 5% 55%;
    height: 1000px;
  }
  .error-icon {
    grid-column: 2;
    grid-row: 2;
    color: #70A300 !important;
    font-size: 150px !important;
    text-shadow: 0px 3px 0px rgb(222, 222, 222);
    -webkit-transition: 0.5s;
  }
  .error-icon:hover {
    -webkit-transform: scale(1.1);
    color: #76ac02;
    text-shadow: 0px 3px 0px #76ac02;
  }
  .not-found-header {
    grid-column: 2;
    grid-row: 3;
  }
  .not-found-subtext {
    grid-column: 2;
    grid-row: 4;
  }
  .not-found-button {
    grid-column: 2;
    grid-row: 5;
  }
  p{
    color: rgb(160, 161, 162);
    font-weight: bold;
  }
</style>
