// Utility Methods
const getCurrentPage = (payload, currentPage) => {
  if (payload === undefined || payload === null) {
    return currentPage;
  }

  return payload;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getCurrentPage,
};
