<template>
  <div>
    <div class="area-container">
      <div class="col-12">
        <div class="map-container">
          <div id="map" class="m-0"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { mapGetters } from 'vuex';

export default {
  name: 'JobPostingsMap',
  props: {
    clusterMode: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
      mapStyle: process.env.VUE_APP_MAPBOX_STYLE,
      mapbox: null,
      defaultZoom: 1,
      defaultCenter: [-90.4601, 32.3492],
      sourceLayerName: process.env.VUE_APP_MAPBOX_SOURCE_NAME_COUNTIES,
      sourceLayerURL: process.env.VUE_APP_MAPBOX_SOURCE_URL_COUNTIES,
      tilsetURL: 'mapbox://randallreilly-strategize.dwdqkeik',
      tilesetName: 'uscities-auy900',
      mapType: {
        state: {
          layerName: process.env.VUE_APP_MAPBOX_SOURCE_NAME_STATES,
          layerURL: process.env.VUE_APP_MAPBOX_SOURCE_URL_STATES,
        },
        msa: {
          layerName: process.env.VUE_APP_MAPBOX_SOURCE_NAME_MSA,
          layerURL: process.env.VUE_APP_MAPBOX_SOURCE_URL_MSA,
        },
        county: {
          layerName: process.env.VUE_APP_MAPBOX_SOURCE_NAME_COUNTIES,
          layerURL: process.env.VUE_APP_MAPBOX_SOURCE_URL_COUNTIES,
        },
      },
    };
  },
  async mounted() {
    mapboxgl.accessToken = this.accessToken;
    const map = new mapboxgl.Map({
      container: 'map',
      style: this.mapStyle,
      center: this.defaultCenter,
      zoom: this.defaultZoom, // starting zoom
    });
    this.mapbox = map;

    map.on('load', () => {
      map.addSource('mapboxGeoJson', {
        type: 'geojson',
        data: this.geographicalData,
      });
      map.addLayer(
        {
          id: 'population',
          type: 'circle',
          source: 'mapboxGeoJson',
          paint: {
            'circle-radius': {
              property: 'number_of_postings',
              type: 'exponential',
              stops: [
                [0, 2],
                [5, 3],
                [10, 4],
                [20, 5],
                [30, 6],
                [40, 7],
                [50, 8],
                [75, 9],
                [100, 10],
                [250, 12],
                [500, 14],
                [1000, 20],
              ],
            },
            // Color circles by mapboxGeoJson, using a `match` expression.
            'circle-color': 'rgba(112, 163, 0, .5)',
          },
        },
      );
    });
    this.updateBoundsBasedOnVisibleFeatures(this.geographicalData);
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });
    map.on('mouseenter', 'population', (e) => {
      // Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = 'pointer';

      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();
      const numberOfPostings = e.features[0].properties.number_of_postings;
      // eslint-disable-next-line max-len
      const cityAndStateName = `${e.features[0].properties.city_state_name}`;
      // eslint-disable-next-line max-len
      const popupHTML = `<strong>${cityAndStateName}</strong><p>Count of postings: ${numberOfPostings}</p>`;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      popup.setLngLat(coordinates).setHTML(popupHTML).addTo(map);
    });

    map.on('mouseleave', 'population', () => {
      map.getCanvas().style.cursor = '';
      popup.remove();
    });
    map.addControl(new mapboxgl.FullscreenControl());
    this.updateBoundsBasedOnVisibleFeatures(this.geographicalData);
  },
  watch: {
    geographicalData(newData) {
      this.mapbox.getSource('job_postings').setData(newData);
      this.updateBoundsBasedOnVisibleFeatures(newData);
    },
    clusterMode(enable) {
      if (this.mapbox.getLayer('clusters')) this.mapbox.removeLayer('clusters');
      if (this.mapbox.getLayer('cluster-count')) this.mapbox.removeLayer('cluster-count');
      if (this.mapbox.getLayer('unclustered-point')) this.mapbox.removeLayer('unclustered-point');
      if (this.mapbox.getSource('job_postings')) this.mapbox.removeSource('job_postings');

      // Add source with new clustering setting
      this.mapbox.addSource('job_postings', {
        type: 'geojson',
        data: this.geographicalData,
        cluster: enable,
        clusterMaxZoom: enable ? 14 : 0,
        clusterRadius: enable ? 50 : 0,
        clusterProperties: { // New in Mapbox GL JS v0.53.0 onward
          sumPostings: ['+', ['get', 'number_of_postings']],
        },
      });

      // Add layers back
      if (enable) {
        this.mapbox.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'job_postings',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'step',
              ['get', 'sumPostings'],
              '#51bbd6',
              100,
              '#f1f075',
              750,
              '#f28cb1',
            ],
            'circle-radius': [
              'step',
              ['get', 'sumPostings'],
              20,
              100,
              30,
              750,
              40,
            ],
          },
        });

        this.mapbox.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'job_postings',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{sumPostings}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
          },
        });
      }

      this.mapbox.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'job_postings',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#11b4da',
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      geographicalData: 'jobPostings/getGeographicalData',
    }),
  },
  methods: {
    updateBoundsBasedOnVisibleFeatures(dataPoints) {
      const bounds = new mapboxgl.LngLatBounds();
      // eslint-disable-next-line arrow-parens
      dataPoints.features.forEach((feature) => {
        if (feature.geometry.type === 'Point') {
          bounds.extend(feature.geometry.coordinates);
        } else if (feature.geometry.type === 'MultiPoint') {
          feature.geometry.coordinates.forEach((coord) => {
            bounds.extend(coord);
          });
        }
      });

      this.mapbox.fitBounds(bounds, {
        padding: 20,
        maxZoom: 15,
        duration: 2000, // Duration of the animation in milliseconds
      });
    },
  },
};
</script>

<style scoped>
  #map {
    width: 100%;
    height: 100%;
  }
  .map-container, .mapboxgl-map {
    height: 100%;
    min-height: 350px;
  }
  .map-container {
    flex: 1;
    position: relative;
  }
  .area-container {
    height: calc(100% - 20vh);
    display: flex;
  }
</style>
