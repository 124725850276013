export function formatDate(date, format, options = {}) {
  const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Arithmetic operations on date
  if (options.subtractYears) {
    date.setFullYear(date.getFullYear() - options.subtractYears);
  }
  if (options.subtractMonths) {
    date.setMonth(date.getMonth() - options.subtractMonths);
  }
  if (options.subtractDays) {
    date.setDate(date.getDate() - options.subtractDays);
  }
  // Set date options
  if (options.startOfMonth) {
    date.setDate(1);
  }

  const day = date.getDate();
  // JavaScript months are zero-indexed.
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return (
    format
      .replace(/YYYY/g, year)
      .replace(/MM/g, month.toString().padStart(2, '0'))
      .replace(/DD/g, day.toString().padStart(2, '0'))
      .replace(/MMM/g, monthsShort[month - 1])
  );
}

export function getFormattedDateFromYearMonth(year, month, format = 'YYYY/MM') {
  const parsedYear = parseInt(year, 10);
  const parsedMonth = parseInt(month, 10);

  const date = new Date(parsedYear, parsedMonth - 1, 1);
  return formatDate(date, format);
}

export function formatToFirstOfMonth(dateString, format = 'YYYY/MM/DD') {
  const separator = dateString.includes('/') ? '/' : '-';
  const [year, month] = dateString.split(separator);

  return getFormattedDateFromYearMonth(year, month, format);
}

export function getMonthFromDate(dateString, padded = false) {
  const date = new Date(dateString);
  // Convert from zero-indexed to one-indexed
  const month = date.getMonth() + 1;

  return padded ? month.toString().padStart(2, '0') : month;
}

export function getYearFromDate(dateString) {
  const date = new Date(dateString);

  return date.getFullYear();
}

export function convertDateFormat(dateString, currentFormat, targetFormat) {
  const separator = currentFormat.includes('-') ? '-' : '/';
  const parts = dateString.split(separator);

  const formatMap = {
    YYYY: parts[0],
    MM: parts[1],
    DD: parts[2],
  };

  const targetSeparator = targetFormat.includes('/') ? '/' : '-';
  const targetParts = targetFormat.split(targetSeparator);

  const formattedDate = targetParts.reduce((date, part) => {
    const value = formatMap[part];

    if (value) {
      return date ? `${date}${targetSeparator}${value}` : value;
    }

    return date;
  }, '');

  return formattedDate;
}
