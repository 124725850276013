<template>
  <div class="callback-page">
    <LoadingSpinner
      class="callback-loading-spinner"
      :loading="true"
      :color="'#70A300'"
      :size="'200px'"
      :borderWidth="'14px'"
    />
  </div>
</template>

<script>
import LoadingSpinner from './StyledComponents/LoadingSpinner.vue';

export default {
  name: 'Callback',
  components: {
    LoadingSpinner,
  },
};
</script>
<style scoped>
  .callback-page {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
  .callback-loading-spinner {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }
</style>
