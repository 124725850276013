import ErrorService from '../../../helpers/ErrorHandling';

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;

const HEADERS = {
  'Content-Type': 'application/json',
};

export default {
  async getMapsData({ commit }, payload) {
    const body = JSON.stringify(payload);
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/maps/search`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
      body,
    });
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setMapsData', data.result);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },
  async getFilterUpperLimits({ commit }) {
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/maps/get_filters_upper_limits`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
    });

    const data = await response.json();

    if (ErrorService.handleResponse(response, data)) {
      await commit('setMapsFiltersUpperLimits', data.result);
      await commit('auth/setAuthenticated', data.authenticated, { root: true });
    }
  },
};
