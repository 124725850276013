export default {
  getMapsData(state) {
    return state.mapsData;
  },
  getMapsDataLoading(state) {
    return state.mapsDataLoading;
  },
  getLaborForcePercentileFilter(state) {
    return state.laborForcePercentileFilter;
  },
  getMaxLeadCountFilterValue(state) {
    return state.maxLeadCountFilterValue;
  },
  getSelectedMinLeadCountFilterValue(state) {
    return state.selectedMinLeadCountFilterValue;
  },
  getMaxHireCountFilterValue(state) {
    return state.maxHireCountFilterValue;
  },
  getSelectedMinHireCountFilterValue(state) {
    return state.selectedMinHireCountFilterValue;
  },
  getMaxEmploymentCountFilterValue(state) {
    return state.maxEmploymentCountFilterValue;
  },
  getSelectedMinEmploymentCountFilterValue(state) {
    return state.selectedMinEmploymentCountFilterValue;
  },
  getMapFilters(state) {
    return state.mapFilters;
  },
};
