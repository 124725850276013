<template>
  <div>
    <v-menu
      bottom
      left
      max-width=225
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          right
          class="float-right pa-4 mt-5 mr-3 see-details-button"
          small
          color="primary"
          elevation="0"
        ><v-icon class="mr-1" small>
          mdi-file-document-multiple</v-icon> See Details
        </v-btn>
      </template>
      <v-list v-if="hometimeDataLoading" class="loading-spinner-container">
        <LoadingSpinner
          class="loading-spinner"
          :loading="hometimeDataLoading"
          :color="'#70A300'"
          :size="'100px'"
        />
      </v-list>
      <v-list v-else>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              Select a timeframe to see more hometime details
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="(timeframe, index) in hometimeMenuItems" :key="index">
          <v-list-item-title
            class="text-wrap"
            @click.stop="hometimeDetails(timeframe)"
          >
            {{ timeframe }} Hometime Details
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="ml-3">
            Details for Job Postings Offering {{ hometimeTableTitle }} Hometime
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-menu
              :close-on-content-click="true"
              v-model="downloadMenu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  Download
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(type, index) in downloadTypes"
                  :key="index"
                >
                  <v-list-item-title @click.stop="exportData(type)">{{ type }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              icon
              dark
              @click.stop="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-data-table
          class="elevation-1 sticky-headers"
          :headers="headers"
          fixed-header
          height="calc(100vh - 128px)"
          :items="hometimeData"
          :items-per-page="50"
          :footer-props="{
            'items-per-page-options': [50, 100, 200, 300, 400, 500]
          }"
          @update:page="handlePageChange"
        ></v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import excelParser from '../../helpers/excel-parser';
import LoadingSpinner from '../StyledComponents/LoadingSpinner.vue';

export default {
  name: 'HometimeDetailsMenu',
  components: {
    LoadingSpinner,
  },
  props: {
    msaCodes: { type: Array },
    stateCodes: { type: Array },
    countyCodes: { type: Array },
    regionCodes: { type: Array },
    subRegionCodes: { type: Array },
    formattedStartDate: { type: Date },
    formattedEndDate: { type: Date },
    jobTypeFilters: { type: Object },
    companySearched: { type: Array },
    advertisedWageRangesButtonActive: { type: String },
  },
  data() {
    return {
      downloadMenu: false,
      hometimeTableTitle: '',
      hometimeMenuItems: ['Daily', 'Weekly', 'Biweekly'],
      downloadTypes: ['csv', 'xlsx'],
      dialog: false,
      headers: [
        {
          text: 'Company Name',
          align: 'start',
          sortable: false,
          value: 'Company',
        },
        { text: 'Posting Count', value: 'Posting Count' },
        { text: 'Daily Hometime', value: 'Daily Hometime' },
        { text: 'Weekly Hometime', value: 'Weekly Hometime' },
        { text: 'Biweekly Hometime', value: 'Biweekly Hometime' },
        { text: 'Hourly Wage Min', value: 'Hourly Wage Min' },
        { text: 'Hourly Wage Max', value: 'Hourly Wage Max' },
        { text: 'Weekly Wage Min', value: 'Weekly Wage Min' },
        { text: 'Weekly Wage Max', value: 'Weekly Wage Max' },
        { text: 'Yearly Wage Min', value: 'Yearly Wage Min' },
        { text: 'Yearly Wage Max', value: 'Yearly Wage Max' },
        { text: 'CPM Min', value: 'CPM Min' },
        { text: 'CPM Max', value: 'CPM Max' },
        { text: 'Guaranteed Pay Amount', value: 'Guaranteed Pay Amount' },
        { text: 'Guaranteed Pay Timeframe', value: 'Guaranteed Pay Timeframe' },
        { text: 'Sign-on Bonus Amount', value: 'Sign-on Bonus Amount' },
        { text: 'Referral Bonus Amount', value: 'Referral Bonus Amount' },
        { text: 'No-touch Freight', value: 'No-touch Freight' },
        { text: 'Pet Policy', value: 'Pet Policy' },
        { text: 'Rider Policy', value: 'Rider Policy' },
        { text: 'Experience Required', value: 'Experience Required' },
        { text: 'Experience Timeframe', value: 'Experience Timeframe' },
      ],
    };
  },
  computed: {
    ...mapGetters('jobPostings', {
      hometimeData: 'getHometimePostingBenefitsDetailsData',
      hometimeDataLoading: 'getHometimePostingBenefitsDetailsDataLoading',
    }),
  },
  methods: {
    handlePageChange() {
      const tableWrapper = document.querySelector('.v-dialog--fullscreen');
      tableWrapper.scrollTop = 0;
    },
    async hometimeDetails(timeframe) {
      this.hometimeTableTitle = timeframe;
      const filters = {
        msa_codes: this.msaCodes,
        state_codes: this.stateCodes,
        county_codes: this.countyCodes,
        region_codes: this.regionCodes,
        sub_region_codes: this.subRegionCodes,
        posting_date_from: this.formattedStartDate,
        posting_date_to: this.formattedEndDate,
        job_type_filters: this.jobTypeFilters,
        company_names: this.companySearched,
        high_low_type: this.advertisedWageRangesButtonActive,
        timeframe,
      };

      this.$store.commit('jobPostings/setHometimePostingBenefitsDetailsDataLoading', true);
      await this.$store.dispatch('jobPostings/fetchHometimePostingBenefitsDetailsData', filters);
      this.dialog = true;
    },
    exportData(exportType) {
      this.downloadMenu = false;
      excelParser().exportDataFromJSON(this.hometimeData, `${this.hometimeTableTitle}HometimeDetails`, 'Strategize HometimeDetails', exportType);
    },
  },
};
</script>

<style scoped lang="scss">
.v-list-item__title {
  cursor: pointer;
}
.loading-spinner-container {
  height: 200px;
  width: 180px;
  padding-top: 25%;
}
.see-details-button {
  font-weight: 700 !important;
  font-size: 14px !important;
}
</style>
<style>
.sticky-headers .v-data-table-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}
</style>
