<template>
  <div>
    <canvas id="costPerLead"></canvas>
  </div>
</template>

<script>
import { Chart } from 'chart.js';

export default {
  name: 'CostPerLead',
  props: {
    data: { type: Object, required: true },
    showPreviousYear: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    currentMonthInQuery: false,
    endDateMonth: 0,
    chart: null,
  }),
  watch: {
    showPreviousYear() {
      this.chart.data.datasets[1].hidden = !this.showPreviousYear;
      this.chart.update();
    },
  },
  async mounted() {
    const { months } = this.$props.data;
    const lastMonthResult = months[months.length - 1];
    this.currentMonthInQuery = await this.$store.dispatch(
      'trends/currentMonthInResult',
      lastMonthResult,
    );
    // eslint-disable-next-line no-new
    this.chart = new Chart(
      document.getElementById('costPerLead'),
      {
        type: 'line',
        options: this.options,
        data: this.chartData,
      },
    );
    this.chart.data.datasets[1].hidden = !this.showPreviousYear;
    this.chart.update();
  },
  computed: {
    chartData() {
      return {
        labels: this.$props.data.months,
        spanGaps: false,
        datasets: [
          // Selected Months Data
          {
            fill: false,
            borderColor: '#70A300',
            borderWidth: 5,
            pointBorderWidth: 2,
            currentYearCPL: this.$props.data.selectedDatesCPL,
            previousYearCPL: this.$props.data.previousYearCPL,
            spendAmount: this.$props.data.selectedYearSpendAmount,
            campaignCount: this.$props.data.selectedYearCampaignCount,
            data: this.$props.data.selectedDatesCPL,
            lineTension: 0,
            pointRadius: (context) => {
              const spendAmount = context.dataset.spendAmount[context.dataIndex];
              const campaignCount = context.dataset.campaignCount[context.dataIndex];
              // eslint-disable-next-line max-len
              if ((campaignCount < 8 && campaignCount >= 3) || (spendAmount < 50000 && spendAmount >= 20000)) {
                return 6;
              }
              return 3;
            },
            pointStyle: (context) => {
              const spendAmount = context.dataset.spendAmount[context.dataIndex];
              const campaignCount = context.dataset.campaignCount[context.dataIndex];
              // eslint-disable-next-line max-len
              if ((campaignCount < 8 && campaignCount >= 3) || (spendAmount < 50000 && spendAmount >= 20000)) {
                return 'triangle';
              }
            },
            pointBorderColor: (context) => {
              const spendAmount = context.dataset.spendAmount[context.dataIndex];
              const campaignCount = context.dataset.campaignCount[context.dataIndex];
              // eslint-disable-next-line max-len
              if ((campaignCount < 8 && campaignCount >= 3) || (spendAmount < 50000 && spendAmount >= 20000)) {
                return 'rgba(198, 83, 1, 1)';
              }
              return '#70A300';
            },
            pointBackgroundColor: (context) => {
              const spendAmount = context.dataset.spendAmount[context.dataIndex];
              const campaignCount = context.dataset.campaignCount[context.dataIndex];
              // eslint-disable-next-line max-len
              if ((campaignCount < 8 && campaignCount >= 3) || (spendAmount < 50000 && spendAmount >= 20000)) {
                return 'rgba(198, 83, 1, 1)';
              }
              return '#70A300';
            },
          },
          // Previous Year Data
          {
            fill: false,
            borderColor: '#C4C4C3',
            borderWidth: 5,
            pointBorderWidth: 2,
            currentYearCPL: this.$props.data.selectedDatesCPL,
            previousYearCPL: this.$props.data.previousYearCPL,
            data: this.$props.data.previousYearCPL,
            previousYearSpendAmounts: this.$props.data.previous_year_spend_amounts,
            lineTension: 0,
            // pointStyle: function(context) {
            //   const spendAmount = context.dataset.previousYearSpendAmounts[context.dataIndex];
            //   if (spendAmount < 50000 && spendAmount >= 20000) {
            //     return 'triangle';
            //   }
            // },
            // pointBorderColor: function(context) {
            //   const spendAmount = context.dataset.previousYearSpendAmounts[context.dataIndex];
            //   if (spendAmount < 50000 && spendAmount >= 20000) {
            //     return 'rgba(198, 83, 1, 1)';
            //   }
            // },
            // pointBackgroundColor: function(context) {
            //   const spendAmount = context.dataset.previousYearSpendAmounts[context.dataIndex];
            //   if (spendAmount < 50000 && spendAmount >= 20000) {
            //     return 'rgba(198, 83, 1, 1)';
            //   }
            // },
          },
        ],
      };
    },
    options() {
      return {
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
          titleFont: {
            size: 16,
          },
          bodyFont: {
            size: 16,
          },
          footerFont: {
            size: 16,
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            enabled: true,
            position: 'nearest',
            callbacks: {
              title() {
                return '';
              }, // Don't show title of tooltip
              label: (data) => {
                const { dataIndex, label, chart } = data;
                const dataset = chart.data.datasets[data.datasetIndex];
                if (data.datasetIndex === 0) {
                  return `${label} $${(Math.round((parseFloat(dataset.data[dataIndex]) + Number.EPSILON) * 100) / 100).toFixed(2)}`;
                }
                if (!this.chart.data.datasets[1].hidden) {
                  return `Previous Year $${(Math.round((parseFloat(dataset.previousYearCPL[dataIndex]) + Number.EPSILON) * 100) / 100).toFixed(2)}`;
                }
              },
              labelColor: (data) => {
                const { datasetIndex } = data;
                if (datasetIndex === 0) {
                  return {
                    backgroundColor: '#70A300',
                    borderWidth: 0,
                  };
                }
                return {
                  backgroundColor: '#C4C4C3',
                  borderWidth: 0,
                };
              },
              footer: (tooltipItems) => {
                // eslint-disable-next-line max-len
                if (!tooltipItems[0].chart.data.datasets[1].hidden && tooltipItems[1] && tooltipItems[0]) {
                  const diff = parseFloat(tooltipItems[0].raw) - parseFloat(tooltipItems[1].raw);
                  return `Difference: $${Math.round((Math.abs(diff) + Number.EPSILON) * 100) / 100}`;
                }
              },
            },
          },
        },
        scales: {
          y:
            {
              ticks: {
                display: true,
                callback: (value) => `$${value.toLocaleString('en-US')}`,
                min: 0,
                step: 10,
              },
              grid: {
                display: false,
              },
              beginAtZero: true,
            },
          x:
            {
              ticks: {
                max: 60,
                display: true,
              },
              grid: {
                display: false,
              },
            },
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        title: {
          display: false,
          text: 'CPL by Month',
        },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
  },
  destroyed() {
    this.$destroy();
  },
  methods: {
    determineWarning(dataObject) {
      // eslint-disable-next-line max-len
      return (dataObject.campaignAmount < 8 && dataObject.campaignAmount >= 3) || (dataObject.spendAmount < 50000 && dataObject.spendAmount >= 20000);
    },
    determineNoData(dataObject) {
      // eslint-disable-next-line max-len
      return dataObject.campaignAmount < 3 || dataObject.spendAmount < 20000 || !dataObject.campaignAmount || !dataObject.spendAmount;
    },
  },
};
</script>

<style></style>
