<template>
  <div>
    <v-card class="no-data"
    v-if="!advertisedSalaryDistributionPresence && selectedDistributions.length === 0"
    width="100%"
    elevation="2">
      <NoData
        class="competition-page-no-data"
        error-message="No Data to Display"
        error-sub-message="Please adjust your filter selections to get results."
      />
      <img alt="blank pay rate chart icon"
        src="../../../assets/Histogram-background-viz.svg"
        class="no-data-image"
      />
    </v-card>
    <div v-else>
      <canvas id="overlay" width="400" height="400"
        style="position:absolute;pointer-events:none;">
      </canvas>
      <canvas id="histogram" width="400" height="400"></canvas>
    </div>
    </div>
</template>
<script>

import { Chart } from 'chart.js';
import { mapGetters } from 'vuex';
import NoData from '../StyledComponents/NoData.vue';

export default {
  name: 'PayDistribution',
  components: {
    NoData,
  },
  props: {
    distribution: {
      type: Array,
      default: () => [],
      required: true,
    },
    labels: {
      type: Array,
      default: () => [],
      required: true,
    },
    timeFrame: {
      type: String,
      default: 'Yearly',
      required: true,
    },
  },
  data: () => ({
    chart: null,
    selectedDistributions: [],
  }),
  watch: {
    distribution() {
      const dataXAxis = this.$props.distribution.map((data) => data.percentage);
      this.chart.data.labels = this.$props.labels;
      dataXAxis.forEach((data) => {
        this.chart.data.datasets[0].data.push(data);
      }, this);
      this.resetBarColors();
      this.chart.update();
      this.addInCompanyWages();
    },
    '$store.state.jobPostings.inputtedYearlyWageRange': function () {
      this.addInCompanyWages();
    },
    '$store.state.jobPostings.inputtedWeeklyWageRange': function () {
      this.addInCompanyWages();
    },
    '$store.state.jobPostings.inputtedHourlyWageRange': function () {
      this.addInCompanyWages();
    },
    '$store.state.jobPostings.inputtedCPMWageRange': function () {
      this.addInCompanyWages();
    },
  },
  computed: {
    ...mapGetters('jobPostings', {
      companyName: 'getCompanyName',
      yearlyWageRange: 'getInputtedYearlyWageRange',
      weeklyWageRange: 'getInputtedWeeklyWageRange',
      hourlyWageRange: 'getInputtedHourlyWageRange',
      cpmWageRange: 'getInputtedCPMWageRange',
      advertisedSalaryDistributionPresence: 'getAdvertisedSalaryDistributionPresence',
      selectedDistributionWageRangesUsed: 'getSelectedDistributionWageRangesUsed',
    }),
  },
  mounted() {
    if (!this.advertisedSalaryDistributionPresence && this.selectedDistributions.length === 0) {
      return;
    }
    const dataXAxis = this.$props.distribution.map((data) => data.percentage);
    const ctx = document.getElementById('histogram').getContext('2d');
    const options = {
      maintainAspectRatio: false,
      responsive: true,
      onResize(chartInstance, newSize) {
        const overlay = document.getElementById('overlay');
        if (chartInstance) {
          if (overlay) {
            overlay.width = newSize.width;
            overlay.height = newSize.height;
          }
        }
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: {
          min: 0,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            autoSkip: false,
            stepSize: 1,
            callback(value, index) {
              return Object.values(this.chart.data.labels[index]);
            },
          },
        },
        yAxes: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            autoSkip: true,
            callback: (value) => `${value}%`,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: this.externalTooltipHandler,
        },
      },
    };
    // eslint-disable-next-line no-undef
    const chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.$props.labels,
        datasets: [{
          axis: 'x',
          data: dataXAxis,
          backgroundColor: Array(this.$props.labels.length).fill('#929292'),
          borderColor: Array(this.$props.labels.length).fill('#929292'),
          borderRadius: 2,
          borderWidth: 2,
          minBarLength: 10,
        }],
      },
      options,
    });
    const canvas = document.getElementById('histogram');
    this.chart = chart;
    this.addInCompanyWages();
    const overlay = document.getElementById('overlay');
    let startIndex = 0;
    if (overlay) {
      overlay.width = chart.chartArea.right;
      overlay.height = chart.chartArea.height;
    }
    const selectionContext = overlay.getContext('2d');
    let selectionRect = {
      w: 0,
      startX: 0,
      startY: 0,
    };
    let drag = false;
    canvas.addEventListener('pointerdown', (evt) => {
      const points = chart.getElementsAtEventForMode(evt, 'index', {
        intersect: false,
      });
      startIndex = points[0].index;
      selectionRect.startX = evt.offsetX;
      selectionRect.startY = chart.chartArea.top;
      drag = true;
    });
    canvas.addEventListener('pointermove', (evt) => {
      const rect = canvas.getBoundingClientRect();
      if (drag) {
        selectionContext.fillStyle = '#70a300';
        selectionRect.w = (evt.clientX - rect.left) - selectionRect.startX;
        selectionContext.globalAlpha = 0.5;
        selectionContext.clearRect(0, 0, canvas.width, canvas.height);
        // eslint-disable-next-line max-len
        selectionContext.fillRect(selectionRect.startX, selectionRect.startY, selectionRect.w, chart.chartArea.bottom - chart.chartArea.top);
      } else {
        selectionContext.clearRect(0, 0, canvas.width, canvas.height);
        drag = false;
      }
    });
    canvas.addEventListener('pointerup', (evt) => {
      const backgroundColors = chart.data.datasets[0].backgroundColor;
      const borderColors = chart.data.datasets[0].borderColor;
      let endIndex = chart.getElementsAtEventForMode(evt, 'index', {
        intersect: false,
      })[0].index;
      const intersectedEnd = chart.getElementsAtEventForMode(evt, 'index', {
        intersect: true,
      });
      selectionContext.clearRect(0, 0, canvas.width, canvas.height);
      let greenIndex = -1;
      if (backgroundColors.includes('#79A300')) {
        greenIndex = chart.data.datasets[0].backgroundColor.indexOf('#79A300');
      }
      if (backgroundColors.includes('#79A30035')) {
        greenIndex = chart.data.datasets[0].backgroundColor.indexOf('#79A30035');
      }
      // if they clicked and dragged from left to right
      if (startIndex < endIndex) {
        this.setAllBarsToLightGrey(backgroundColors, borderColors);
        endIndex += 1;
        // set all colors to light grey and start from there
        this.formatSelectionRange(startIndex, endIndex, '#929292', chart.data.datasets[0]);
        if (greenIndex !== -1) { // we know that there's green somewhere
        // eslint-disable-next-line max-len
          if (startIndex <= greenIndex && greenIndex <= endIndex) { // we know it's in the selected area
            chart.data.datasets[0].backgroundColor[greenIndex] = '#79A300';
            chart.data.datasets[0].borderColor[greenIndex] = '#79A300';
          } else { // we know it's outside the selected area
            chart.data.datasets[0].backgroundColor[greenIndex] = '#79A30035';
            chart.data.datasets[0].borderColor[greenIndex] = '#79A30035';
          }
        }
        this.buildQueryForSelectedDistribution(startIndex, endIndex - 1);
      } else if (endIndex < startIndex) { // drag right to left
        this.setAllBarsToLightGrey(backgroundColors, borderColors);
        startIndex += 1;
        this.formatSelectionRange(endIndex, startIndex, '#929292', chart.data.datasets[0]);
        if (greenIndex !== -1) { // we know that there's green somewhere
        // eslint-disable-next-line max-len
          if (startIndex >= greenIndex && greenIndex >= endIndex) { // we know it's in the selected area
            chart.data.datasets[0].backgroundColor[greenIndex] = '#79A300';
            chart.data.datasets[0].borderColor[greenIndex] = '#79A300';
          } else { // we know it's outside the selected area
            chart.data.datasets[0].backgroundColor[greenIndex] = '#79A30035';
            chart.data.datasets[0].borderColor[greenIndex] = '#79A30035';
          }
        }
        this.buildQueryForSelectedDistribution(endIndex, startIndex - 1);
      }
      // if they clicked on a specific bar
      if (intersectedEnd.length && startIndex === intersectedEnd[0].index) {
        this.setAllBarsToLightGrey(backgroundColors, borderColors);
        if (greenIndex !== -1) {
          if (greenIndex === intersectedEnd[0].index) {
            chart.data.datasets[0].backgroundColor[greenIndex] = '#79A300';
            chart.data.datasets[0].borderColor[greenIndex] = '#79A300';
          } else {
            chart.data.datasets[0].backgroundColor[intersectedEnd[0].index] = '#929292';
            chart.data.datasets[0].borderColor[intersectedEnd[0].index] = '#929292';
            chart.data.datasets[0].backgroundColor[greenIndex] = '#79A30035';
            chart.data.datasets[0].borderColor[greenIndex] = '#79A30035';
          }
        } else {
          chart.data.datasets[0].backgroundColor[intersectedEnd[0].index] = '#929292';
          chart.data.datasets[0].borderColor[intersectedEnd[0].index] = '#929292';
        }
        this.buildQueryForSelectedDistribution(startIndex, endIndex);
      }
      // if they clicked outside the chart
      if (startIndex === endIndex && intersectedEnd.length === 0) {
        if (this.selectedDistributionWageRangesUsed) {
          backgroundColors.splice(0, backgroundColors.length, ...Array(backgroundColors.length).fill('#929292'));
          borderColors.splice(0, borderColors.length, ...Array(borderColors.length).fill('#929292'));
          if (greenIndex !== -1) {
            chart.data.datasets[0].backgroundColor[greenIndex] = '#79A300';
            chart.data.datasets[0].borderColor[greenIndex] = '#79A300';
          }
          this.$store.commit('jobPostings/setSelectedDistributionWageRangesLoading', true);
          setTimeout(() => {
            this.$store.commit('jobPostings/setSelectedDistributionWageRangesLoading', false);
          }, 1000);
          this.$store.commit('jobPostings/setSelectedDistributionWageRangesUsed', false);
          this.$store.commit('jobPostings/setSelectedDistributionWageRanges', []);
        }
      }
      chart.update();
      drag = false;
      startIndex = 0;
      selectionRect = {
        w: 0,
        startX: 0,
        startY: 0,
      };
    });
  },
  methods: {
    async buildQueryForSelectedDistribution(startIndex, endIndex) {
      let selectedDistributionEnd;
      let selectedDistributionStart;
      if (startIndex === endIndex) {
        const singleSelection = this.distribution[startIndex];
        selectedDistributionStart = singleSelection.timeframe_mid_low;
        selectedDistributionEnd = singleSelection.timeframe_mid_high;
      } else {
        selectedDistributionStart = this.distribution[startIndex].timeframe_mid_low;
        selectedDistributionEnd = this.distribution[endIndex].timeframe_mid_high;
      }
      /* eslint-enable */
      this.$emit('selectedDistribution', {
        // eslint-disable-next-line max-len
        selected_distribution_start: selectedDistributionStart,
        selected_distribution_end: selectedDistributionEnd,
      });
    },
    setAllBarsToLightGrey(backgroundColors, borderColors) {
      backgroundColors.splice(0, backgroundColors.length, ...Array(backgroundColors.length).fill('#92929235'));
      borderColors.splice(0, borderColors.length, ...Array(borderColors.length).fill('#92929235'));
    },
    formatSelectionRange(startIndex, endIndex, color, chartArray) {
      // eslint-disable-next-line max-len
      chartArray.backgroundColor.splice(startIndex, endIndex - startIndex, ...Array(endIndex - startIndex).fill(color));
      // eslint-disable-next-line max-len
      chartArray.borderColor.splice(startIndex, endIndex - startIndex, ...Array(endIndex - startIndex).fill(color));
    },
    externalTooltipHandler(context) {
      // Tooltip Element
      const { chart, tooltip } = context;
      const tooltipEl = this.getOrCreateTooltip(chart);
      tooltip.yAlign = 'bottom';
      tooltip.xAlign = 'right';
      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }
      // Set Text
      if (tooltip.body) {
        // Data for creating tooltip
        const index = tooltip.dataPoints[0].dataIndex;
        const tooltipLabel = Object.keys(chart.data.labels[index])[0];
        const percentageValue = `${this.roundPercentages(tooltip.dataPoints[0].formattedValue)}%`;
        const postingsCountValue = this.formatNumbers(this.$props.distribution[index].count);
        const timeFrame = this.$props.timeFrame.toLowerCase();
        // Container for tooltip structure
        const tooltipContainer = document.createElement('div');
        // Create label for the tooltip
        const label = document.createElement('p');
        label.innerHTML = `${tooltipLabel}`;
        label.style.color = 'white';
        label.style.textAlign = 'left';
        label.style.marginBottom = '0';
        label.style.fontWeight = '900';
        // Horizontal divider to add after tooltip title
        const hr = document.createElement('hr');
        hr.style.border = '0.5px solid white';
        hr.style.marginTop = '0.2rem';
        hr.style.marginBottom = '0.4rem';
        // Count of postings
        const countPostings = document.createElement('p');
        countPostings.innerHTML = `<b style='font-weigh: 900;'>${postingsCountValue} postings</b> have a ${timeFrame} advertised wage in this range.`;
        countPostings.style.color = 'white';
        countPostings.style.textAlign = 'left';
        countPostings.style.fontWeight = '400';
        // Percentage of postings
        const percentage = document.createElement('p');
        percentage.innerHTML = `<b style='font-weigh: 900;'>${percentageValue}</b> of ${timeFrame} advertised wages are in this range.`;
        percentage.style.color = 'white';
        percentage.style.textAlign = 'left';
        percentage.style.fontWeight = '400';
        // Append the tags to tooltip body
        tooltipContainer.appendChild(label);
        tooltipContainer.appendChild(hr);
        tooltipContainer.appendChild(countPostings);
        tooltipContainer.appendChild(percentage);
        // Replace old container with new one
        if (tooltipEl.childElementCount > 0) {
          tooltipEl.replaceChild(tooltipContainer, tooltipEl.childNodes[0]);
        } else {
          tooltipEl.appendChild(tooltipContainer);
        }
      }
      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = `${positionX + tooltip.caretX + 110}px`;
      tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
      tooltipEl.style.width = '200px';
      tooltipEl.style.zIndex = '3';
    },
    getOrCreateTooltip(chart) {
      let tooltipEl = chart.canvas.parentNode.querySelector('div');
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(12, 12, 12, 1)';
        tooltipEl.style.borderRadius = '5px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        // todo come back to this
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';
        chart.canvas.parentNode.appendChild(tooltipEl);
      }
      return tooltipEl;
    },
    addInCompanyWages() {
      // eslint-disable-next-line max-len
      // we check to make sure we have the correct data for the currently selected time frame (they put in CPM but it's a yearly graph)
      if (this.timeFrame === 'CPM') {
        if (this.cpmWageRange.completed) {
          this.updateBarColor(this.cpmWageRange);
          this.chart.update();
        } else {
          this.resetBarColors();
          this.chart.update();
        }
      } else {
        const foundWageData = this.$store.getters[`jobPostings/getInputted${this.timeFrame}WageRange`];
        if (foundWageData.completed) {
          this.updateBarColor(foundWageData);
          this.chart.update();
        } else {
          this.resetBarColors();
          this.chart.update();
        }
      }
    },
    updateBarColor(foundWagesForSelectedTimeFrame) {
      // grabs all the data for the chart
      const bars = this.chart.config.data.datasets[0];
      // converts the wage to an average
      // eslint-disable-next-line max-len
      const averageWage = (Number.parseFloat(foundWagesForSelectedTimeFrame.min) + Number.parseFloat(foundWagesForSelectedTimeFrame.max)) / 2;
      // eslint-disable-next-line max-len
      // finds the index of the distribution where the average is less than the timeframe_mid (we fall into that bucket)
      const index = this.$props.distribution.findIndex((dis) => dis.timeframe_mid > averageWage);
      // change the color of the background for that single bar
      this.resetBarColors();
      if (index === -1) {
        bars.backgroundColor[bars.backgroundColor.length - 1] = '#79A300';
      } else {
        bars.backgroundColor[index] = '#79A300';
        bars.borderColor[index] = '#79A300';
      }
    },
    resetBarColors() {
      this.chart.config.data.datasets[0].backgroundColor = Array(this.$props.labels.length).fill('#929292');
      this.chart.config.data.datasets[0].borderColor = Array(this.$props.labels.length).fill('#929292');
    },
    // 623000 -> 623,000, for tooltip
    formatNumbers(value) {
      return value.toLocaleString('en-US', { minimumFractionDigits: 0 });
    },
    // 12.56 -> 13, for tooltip
    roundPercentages(value) {
      return Math.round(value);
    },
  },
};
</script>
<style scoped>
.no-data-image {
  opacity: 20%;
}
.advertised-salary-distribution {
  z-index: 2;
}
.advertised-wage-ranges-by-company {
  z-index: 1;
}
.competition-page-no-data {
  position: absolute;
  height: 100%;
  width: 100%
}
.no-data {
  min-height: 500px;
}
</style>
