<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="notifications"
      :items-per-page="5"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      class="elevation-1"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pr-0 pl-0">
          <h4>Recipients</h4>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    Email
                  </th>
                  <th class="text-center">
                    Read
                  </th>
                  <th class="text-center">
                    Read At
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="recipient in item.recipients" :key="recipient.id">
                  <td>{{ recipient.email }}</td>
                  <td>{{ recipient.read }}</td>
                  <td>{{ recipient.read_at }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'NotificationTable',
  props: {
    notifications: {
      type: Array,
    },
  },
  data() {
    return {
      singleExpand: true,
      expanded: [],
      headers: [
        {
          text: 'Title',
          align: 'start',
          sortable: true,
          value: 'title',
        },
        {
          text: 'Text',
          align: 'start',
          sortable: true,
          value: 'text',
        },
        {
          text: 'Created At',
          align: 'start',
          sortable: true,
          value: 'created_at',
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
