<template>
  <div>
    <v-btn
      v-if="disableButton"
      class="green-button disabled"
      :style="`height:${height}; width: ${width}; background-color: #c2df98;`"
      disabled
    >
      {{ text }}
    </v-btn>
    <v-btn
      v-else
      class="green-button"
      :style="`height:${height}; width: ${width}; background-color: ${color ? color : '#70A300'};`"
      @click="doAction"
    >
      {{ text }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    action: { type: String, required: false },
    height: { type: String, required: true },
    text: { type: String, required: false },
    width: { type: String, required: true },
    disableButton: { type: Boolean, required: true },
    color: { type: String, required: false },
  },
  methods: {
    doAction() {
      this.$emit(`${this.$props.action}`);
    },
  },
};
</script>
