var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pill-button",style:(`height: ${_vm.height}; width: ${_vm.width};
  background-color: ${_vm.backgroundColor} !important;
  color: ${_vm.textColor};
  font-size: ${_vm.fontSize};
  font-family: ${_vm.fontFamily};
  border-style: ${_vm.border ? 'solid' : ''};
  border-color: ${_vm.borderColor};
  border-width: ${_vm.borderWidth};
  padding-right: ${_vm.paddingRight};
  `)},[_vm._v(" "+_vm._s(_vm.text)+" "),(_vm.closeButton)?_c('v-icon',{staticClass:"close-icon ml-2",nativeOn:{"click":function($event){return _vm.doAction.apply(null, arguments)}}},[_vm._v("close")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }