<template>
  <div>
    <div style="display:flex">
      <div style="display: flex;flex-direction:column;cursor: pointer;" class="rr-clippy"
        @click="kickOffStoreLastMonthsData"
      >
        <img src="../../assets//RRClippy.png" width="200" height="200" alt="rr-clippy">
        <button>Store Last Months Data</button>
      </div>
      <div style="display: flex;flex-direction:column;cursor: pointer;" class="rr-clippy"
        @click="kickOffCreateMarketLocationData"
      >
        <img src="../../assets//rrclippycrying.png" width="200" height="200" alt="rr-clippy-crying">
        <button>Create Market Location Data</button>
      </div>
    </div>
    <v-row class="admin-add-notification-button ml-0 mt-2 mb-2 d-flex justify-end mr-2">
      <AddNotificationModal
        :showModal="addNotificationActive"
        :clearForm="clearNotificationForm"
        @closeModal="closeModal"
      />
      <SButton
        class="add-notification-button"
        :action="'addNotification'"
        :height="'40'"
        :text="'Add Notification'"
        :width="'40'"
        :disableButton="false"
        @addNotification="addNotification"
      />
    </v-row>
    <v-row class="mb-0 ml-0 mr-0" style="height: 85%">
      <v-card width="100%" height="400px" class="scroll">
        <NotificationsTable v-if="!notificationsTableLoading" :notifications="adminNotifications"/>
        <div v-if="notificationsTableLoading" class="loading-wrapper">
          <LoadingSpinner
            :loading="notificationsTableLoading"
            :size="'60px'"
            :color="'#70A300'"
            :borderWidth="'6px'"
          />
        </div>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import SButton from '../StyledComponents/Button.vue';
import NotificationsTable from '../Admin/NotificationsTable.vue';
import AddNotificationModal from '../StyledComponents/Modals/AddNotificationModal.vue';
import LoadingSpinner from '../StyledComponents/LoadingSpinner.vue';

export default {
  name: 'Admin',
  components: {
    SButton,
    NotificationsTable,
    AddNotificationModal,
    LoadingSpinner,
  },
  data() {
    return {
      addNotificationActive: false,
      clearNotificationForm: false,
      notificationsTableLoading: true,
    };
  },
  async beforeCreate() {
    await this.$store.dispatch('notifications/getAdminNotifications');
    this.notificationsTableLoading = false;
  },
  methods: {
    addNotification() {
      this.addNotificationActive = true;
    },
    closeModal() {
      this.addNotificationActive = false;
    },
    addNotValidClass(id) {
      const element = document.getElementById(id);
      element.classList.add('is-invalid');
    },
    addNotValidClassToMultiple(ids) {
      ids.forEach((id) => {
        const element = document.getElementById(id);
        element.classList.add('is-invalid');
      });
    },
    removeNotValidClass(id) {
      const element = document.getElementById(id);
      element.classList.remove('is-invalid');
    },
    kickOffStoreLastMonthsData() {
      fetch(`${process.env.VUE_APP_BACKEND_URL}/strategize_frontend/admin/jobs/store_last_months_data`, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }).then(async (res) => {
        const data = await res.json();
        this.$store.commit('admin/setToastMessage', data);
      });
    },
    kickOffCreateMarketLocationData() {
      fetch(`${process.env.VUE_APP_BACKEND_URL}/strategize_frontend/admin/jobs/create_market_location_data`, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }).then(async (res) => {
        const data = await res.json();
        this.$store.commit('admin/setToastMessage', data);
      });
    },
  },
  computed: {
    adminNotifications() {
      return this.$store.state.notifications.adminNotifications;
    },
    total() {
      return this.$store.state.notifications.adminTotal;
    },
  },
};
</script>
<style>
.notification-table {
  background-color: white !important;
  overflow-x: auto;
}
.loading-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style scoped>
.b-table-sticky-header {
  overflow-y: visible !important;
}
</style>
