<template>
  <div>
    <v-row v-for='section in sections'
      :key='section.doughnutTitle'
      class="mt-0 mr-0 ml-0 mt-0 mb-0"
    >
      <v-row class="d-flex justify-center align-center flex-row ml-0 mt-0">
        <v-col>
          <div class="text-h6">{{ section.doughnutTitle }}</div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center flex-row ml-0 mt-0 mb-0 mr-0">
        <v-col v-if="showDoughnutChart" class="d-flex justify-center pt-0">
          <DoughnutChart
            :id='section.dataKey'
            :pie-chart-data='bonusData[section.countKey]'
            :percentage-data='bonusData[section.percentageKey]'
            :total-data='bonusData.count_of_postings'
          />
        </v-col>
        <v-col v-else class="d-flex justify-center pt-0">
          <v-card class="no-data competition-doughnut-chart" elevation="2" width="100%">
            <NoData
              class="competition-page-no-data"
              error-message="No Data to Display"
              error-sub-message="Please adjust your filter selections to get results."
            />
            <img alt="blank pay rate chart icon"
              src="../../assets/Dougnut-chart-background-viz.svg"
              class="no-data-image"
            />
          </v-card>
        </v-col>
        <v-col class="d-flex justify-center">
          <BarChart
            :id="section.dataKey"
            :bar-chart-data="bonusData[section.dataKey]"
            :label-key="section.labelKey"
            data-key="count"
          />
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DoughnutChart from '../Charts/Competition/DoughnutChart.vue';
import BarChart from '../Charts/Competition/BarChart.vue';
import NoData from '../Charts/StyledComponents/NoData.vue';

export default {
  name: 'BonusSection',
  components:
    {
      DoughnutChart,
      BarChart,
      NoData,
    },
  data() {
    return {
      sections: [
        {
          doughnutTitle: 'Postings with a Sign-on Bonus and Their Amounts',
          dataKey: 'sign_on_bonuses',
          countKey: 'sign_on_bonuses_count',
          percentageKey: 'sign_on_bonuses_percentage',
          labelKey: 'sign_on_bonus_amount',
        },
        {
          doughnutTitle: 'Postings with a Referral Bonus and Their Amounts',
          dataKey: 'referral_bonuses',
          countKey: 'referral_bonuses_count',
          percentageKey: 'referral_bonuses_percentage',
          labelKey: 'referral_bonus_amount',
        },
        {
          doughnutTitle: 'Postings with Guaranteed Pay and Their Amounts',
          dataKey: 'guaranteed_weekly_pay_amount',
          countKey: 'guaranteed_weekly_pay_amount_count',
          percentageKey: 'guaranteed_weekly_pay_percentage',
          labelKey: 'rounded_guaranteed_weekly_pay_amount',
        },
      ],
      showDoughnutChart: false,
    };
  },
  computed: {
    ...mapGetters('jobPostings', {
      bonusData: 'getBonusData',
      bonusDataLoading: 'getBonusDataLoading',
      bonusDataPresence: 'getBonusDataPresence',
    }),
  },
  mounted() {
    if (
      this.bonusData.referral_bonuses
      && this.bonusData.referral_bonuses.length > 0
      && this.bonusData.sign_on_bonuses.length > 0
      && this.bonusData.guaranteed_weekly_pay_amount.length > 0
    ) {
      this.showDoughnutChart = true;
    }
  },
};
</script>

<style lang='scss' scoped>
.competition-page-no-data {
  position: absolute;
  height: 100%;
  width: 100%
}
.no-data-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
  opacity: 20%;
}
</style>
