<!-- eslint-disable max-len -->
<template>
  <div>
    <v-dialog
      persistent
      v-model="modalVisible"
      content-class="company-wages-dialog scrollable"
    >
      <template v-slot:default>
        <header>
          <h4 class="text-center">Company Data</h4>
          <span @click="clearModal"><v-icon>mdi-close</v-icon></span>
        </header>
        <div class="company-name">
          <label for="company-name">Company Name*</label>
          <v-text-field
            id="company-name"
            ref="company-name"
            height="40"
            :error="companyNameError"
            placeholder="Type Company Name Here"
            :rules="[v => !!v || 'Company Name is required']"
            v-model="inputtedCompanyName"
            outlined
            required
          />
        </div>
        <v-form ref="addCompanyWagesForm">
          <div class="main-area">
            <label for="wage-time-frame-options">Wage Range*</label>
            <div>
              <v-select
                @change="handleSelection"
                v-model="selectedOption.timeFrame"
                id="wage-time-frame-options"
                :items="determineItems"
                label="Select Option"
                :rules="[v => !!v || 'Wage Timeframe is required']"
                required
                :disabled="determineItems.length === 0"
                dense
                outlined
                clearable
              >
              </v-select>
              <div>
                <label for="">{{ selectedOption.timeFrame }} Wage Range</label>
                <div class="wage-range-area">
                  <div class="min-wage-group">
                    <label class="min-label" for="min-field">Min*</label>
                    <v-text-field
                      id="min-field"
                      ref="min-value"
                      v-model="selectedOption.min"
                      placeholder="Enter Value"
                      :disabled="disabledInput"
                      height="40"
                      :rules="[
                        v => !!v || 'Min wage is required',
                        v => v >= (selectedOption.min !== null && validWageRanges[`${selectedOption.timeFrame}`]['min']) || `Min wage must be greater than or equal to ${validWageRanges[`${selectedOption.timeFrame}`]['min']}`,
                      ]"
                      type="number"
                      :min="0"
                      outlined
                      required
                      validate-on-blur
                    />
                  </div>
                  <div> &nbsp; -  &nbsp; </div>
                  <div class="max-wage-group">
                    <label class="max-label" for="max-field">Max*</label>
                    <v-text-field
                      id="max-field"
                      ref="max-value"
                      v-model="selectedOption.max"
                      :error="maxValueError.error"
                      :errorMessages="maxValueError.message"
                      placeholder="Enter Value"
                      :disabled="disabledInput"
                      :rules="[
                        v => !!v || 'Max wage is required',
                        v => v <= (selectedOption.max !== null && validWageRanges[`${selectedOption.timeFrame}`]['max']) || `Max wage must be less than or equal to ${validWageRanges[`${selectedOption.timeFrame}`]['max']}`,
                      ]"
                      height="40"
                      type="number"
                      :min="0"
                      outlined
                      required
                      validate-on-blur
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="add-more">
              <p v-if="!editing && determineItems.length !== 0"><strong>Add additional Timeframe</strong></p>
              <p v-else-if="editing"><strong>Update Timeframe</strong></p>
              <p v-else-if="!editing && determineItems.length === 0"><strong>Submit</strong></p>
              <v-icon v-if="editing" @click="completeEditing()" class="btn-icon" small>mdi-check-bold</v-icon>
              <v-icon v-else-if="!editing" @click="additionalTimeframe()" class="btn-icon" small>mdi-plus-box</v-icon>
            </div>
            <div v-if="!editing">
              <div class="completed-time-frame" v-for="timeFrameInput in determineLocalCompletedWageTimeFrames" :key="timeFrameInput.timeFrame">
                <div>
                  {{ `${timeFrameInput.timeFrame} Wage` }}
                </div>
                <div>
                  ${{ parseFloat(timeFrameInput.min).toLocaleString() }} - ${{ parseFloat(timeFrameInput.max).toLocaleString() }}
                  <v-icon @click="updateTimeFrameInput(timeFrameInput)" class="btn-icon pencil" small>mdi-pencil</v-icon>
                  <v-icon @click="removeTimeFrameInput(timeFrameInput)" class="btn-icon close" small>mdi-close</v-icon>
                </div>
              </div>
              <div class="completed-time-frame" v-for="timeFrameInput in completedStateWageTimeFrames" :key="timeFrameInput.timeFrame">
                <div>
                  {{ `${timeFrameInput.timeFrame} Wage` }}
                </div>
                <div>
                  ${{ parseFloat(timeFrameInput.min).toLocaleString() }} - ${{ parseFloat(timeFrameInput.max).toLocaleString() }}
                  <v-icon @click="updateTimeFrameInput(timeFrameInput)" class="btn-icon pencil" small>mdi-pencil</v-icon>
                  <v-icon @click="removeTimeFrameInput(timeFrameInput, true)" class="btn-icon close" small>mdi-close</v-icon>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <v-btn outlined class="cancel-btn  m-1 rounded-0 elevation-0" @click="clearModal">Cancel</v-btn>
            <v-btn outlined v-if="showGraySaveButton" class='save-btn m-1 rounded-0 elevation-0' @click="storeCompanyData">Save</v-btn>
            <v-btn outlined v-if="showGreenSaveButton" class='green-button m-1 rounded-0 elevation-0' @click="storeCompanyData">Save</v-btn>
            <v-btn outlined v-if="showCloseButton" class="cancel-btn m-1 rounded-0 elevation-0" @click="clearModal">Close</v-btn>
          </footer>
        </v-form>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CompanyWagesModal',
  components: {},
  props: {
    companyWagesIsVisible: { type: Boolean, required: true },
  },
  data() {
    return {
      inputtedCompanyName: this.$store.getters['jobPostings/getCompanyName'],
      modalVisible: this.$props.companyWagesIsVisible,
      selectedOption: {
        timeFrame: null, min: null, max: null, completed: false,
      },
      editing: false,
      companyNameError: false,
      disabledInput: false,
      maxValueError: {
        error: false,
        message: '',
      },
      completedLocalWageTimeFrames: [],
      completedStateWageTimeFrames: [],
      deletedStateWageTimeFrames: [],
      defaultTimeFrames: ['Yearly', 'Weekly', 'Hourly', 'Cents per Mile'],
      valid: false,
      validWageRanges: {
        Hourly: { min: 15, max: 100 },
        Weekly: { min: 600, max: 13000 },
        Yearly: { min: 30000, max: 550000 },
        'Cents per Mile': { min: 0.34, max: 5.00 },
      },
    };
  },
  watch: {
    determineItems(value) {
      if (value.length === 0) {
        this.disabledInput = true;
      } else {
        this.disabledInput = false;
      }
    },
    companyWagesIsVisible(value) {
      this.modalVisible = value;
    },
    'selectedOption.max': function () {
      if (this.selectedOption.min) {
        // eslint-disable-next-line max-len
        if (Number.parseFloat(this.selectedOption.min) <= Number.parseFloat(this.selectedOption.max)) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      }
    },
    'selectedOption.min': function () {
      if (this.selectedOption.max) {
        // eslint-disable-next-line max-len
        if (Number.parseFloat(this.selectedOption.min) <= Number.parseFloat(this.selectedOption.max)) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      }
    },
  },
  updated() {
    this.completedStateWageTimeFrames = this.determineStateCompletedWageTimeFrames;
  },
  computed: {
    showGraySaveButton() {
      if (this.selectedOption.timeFrame !== null) {
        if (this.valid) {
          return false;
        }

        return true;
      }

      return false;
    },
    showGreenSaveButton() {
      if (this.selectedOption.timeFrame !== null) {
        if (this.valid) {
          return true;
        }
      }

      return false;
    },
    showCloseButton() {
      if (this.showGraySaveButton || this.showGreenSaveButton) {
        return false;
      }

      return true;
    },
    determineItems() {
      // eslint-disable-next-line max-len
      const completedLocalTimeFrames = this.completedLocalWageTimeFrames.filter((cwtf) => cwtf.completed).map((completedWageTimeFrame) => completedWageTimeFrame.timeFrame);
      // eslint-disable-next-line max-len
      const completedStateTimeFrames = this.completedStateWageTimeFrames.filter((cwtf) => cwtf.completed).map((completedWageTimeFrame) => completedWageTimeFrame.timeFrame);
      const completedTimeFrames = [...completedLocalTimeFrames, ...completedStateTimeFrames];
      if (this.editing) {
        return [this.selectedOption.timeFrame];
      }
      // eslint-disable-next-line max-len
      return this.defaultTimeFrames.filter((completedTimeFrame) => !completedTimeFrames.includes(completedTimeFrame) && !completedTimeFrame.deleted);
    },
    determineLocalCompletedWageTimeFrames() {
      // eslint-disable-next-line max-len
      return this.completedLocalWageTimeFrames.filter((cwtf) => !cwtf.deleted).sort((a, b) => this.defaultTimeFrames.indexOf(a.timeFrame) - this.defaultTimeFrames.indexOf(b.timeFrame));
    },
    determineStateCompletedWageTimeFrames() {
      // eslint-disable-next-line max-len
      return [this.yearlyWageRange, this.weeklyWageRange, this.hourlyWageRange, this.cpmWageRange].filter((wage) => wage.completed);
    },
    ...mapGetters('jobPostings', {
      companyName: 'getCompanyName',
      yearlyWageRange: 'getInputtedYearlyWageRange',
      weeklyWageRange: 'getInputtedWeeklyWageRange',
      hourlyWageRange: 'getInputtedHourlyWageRange',
      cpmWageRange: 'getInputtedCPMWageRange',
    }),
  },
  methods: {
    completeEditing() {
      this.selectedOption.completed = true;
      // eslint-disable-next-line max-len
      const localData = this.completedLocalWageTimeFrames.map((c) => c.timeFrame).includes(this.selectedOption.timeFrame);
      if (localData) {
        // eslint-disable-next-line max-len
        const foundIndex = this.completedLocalWageTimeFrames.map((c) => c.timeFrame).indexOf(this.selectedOption.timeFrame);
        // eslint-disable-next-line max-len
        this.completedLocalWageTimeFrames.splice(foundIndex, 1, structuredClone(this.selectedOption));
      } else {
        this.selectedOption.edited = true;
        // eslint-disable-next-line max-len
        const foundIndex = this.completedStateWageTimeFrames.map((c) => c.timeFrame).indexOf(this.selectedOption.timeFrame);
        // eslint-disable-next-line max-len
        this.completedStateWageTimeFrames.splice(foundIndex, 1, structuredClone(this.selectedOption));
      }
      this.editing = false;
      this.$refs.addCompanyWagesForm.reset();
      this.selectedOption = {
        timeFrame: null, min: null, max: null, completed: false, deleted: false,
      };
    },
    clearModal(closeModal = true) {
      this.editing = false;
      this.disabledInput = false;
      this.companyNameError = false;
      this.$refs.addCompanyWagesForm.reset();
      this.completedLocalWageTimeFrames = [];
      this.selectedOption = {
        timeFrame: null, min: null, max: null, completed: false, deleted: false,
      };

      if (closeModal) {
        this.$emit('close');
      }
    },
    formatMinAndMaxValues(min, max) {
      return `$${min.toLocaleString} - $${max.toLocaleString}`;
    },
    updateTimeFrameInput(timeFrameInput) {
      this.editing = true;
      // eslint-disable-next-line no-param-reassign
      timeFrameInput.completed = false;
      this.selectedOption = structuredClone(timeFrameInput);
    },
    handleSelection(selection) {
      this.$refs.addCompanyWagesForm.reset();
      // eslint-disable-next-line max-len
      this.selectedOption = {
        timeFrame: selection, min: null, max: null, completed: false,
      };
    },
    removeTimeFrameInput(timeFrame, state = false) {
      if (state) {
        // remove it from the completedStateWageTimeFrames and add it to the deleted
        // eslint-disable-next-line max-len
        this.deletedStateWageTimeFrames.push(this.completedStateWageTimeFrames.find((tf) => tf.timeFrame === timeFrame.timeFrame));
        // eslint-disable-next-line max-len
        const foundIndex = this.completedStateWageTimeFrames.map((tf) => tf.timeFrame).findIndex((tf) => tf === timeFrame.timeFrame);
        this.completedStateWageTimeFrames.splice(foundIndex, 1);

        // Remove all wage ranges from states immediately.
        if (timeFrame.timeFrame === 'Cents per Mile') {
          this.$store.commit('jobPostings/setInputtedCPMWageRange', {
            timeFrame: 'Cents per Mile', min: null, max: null, completed: false,
          });
        } else {
          // eslint-disable-next-line max-len
          this.$store.commit(`jobPostings/setInputted${timeFrame.timeFrame}WageRange`, {
            timeFrame: timeFrame.timeFrame, min: null, max: null, completed: false,
          });
        }

        // Clear all data when all the wage frames are removed
        if (this.completedStateWageTimeFrames.length === 0) {
          this.clearModal(false);

          if (timeFrame.timeFrame === 'Cents per Mile') {
            this.$store.commit('jobPostings/setInputtedCPMWageRange', {
              timeFrame: 'Cents per Mile', min: null, max: null, completed: false,
            });
          } else {
            // eslint-disable-next-line max-len
            this.$store.commit(`jobPostings/setInputted${timeFrame.timeFrame}WageRange`, {
              timeFrame: timeFrame.timeFrame, min: null, max: null, completed: false,
            });
          }

          // Hide user chart when there is no data
          this.$store.commit('jobPostings/setUserWageChartDisplayState', false);
        }
      } else {
        // eslint-disable-next-line max-len
        this.completedLocalWageTimeFrames = this.completedLocalWageTimeFrames.filter((tf) => tf.timeFrame !== timeFrame.timeFrame);
      }
    },
    isMaxError() {
      // eslint-disable-next-line max-len
      if (Number.parseInt(this.selectedOption.max, 10) < Number.parseInt(this.selectedOption.min, 10)) {
        this.maxValueError.error = true;
        this.maxValueError.message = 'Must be larger value than min value';
        return true;
      }
      this.maxValueError.message = null;
      this.maxValueError.error = false;
      return false;
    },
    additionalTimeframe() {
      this.valid = false;
      if (this.$refs.addCompanyWagesForm.validate() && this.$refs['company-name'].valid) {
        if (this.isMaxError()) {
          return;
        }
        this.selectedOption.completed = true;
        this.completedLocalWageTimeFrames.push(structuredClone(this.selectedOption));

        // Save the inputted range immediately
        if (this.selectedOption.timeFrame === 'Cents per Mile') {
          this.$store.commit('jobPostings/setInputtedCPMWageRange', structuredClone(this.selectedOption));
        } else {
          // eslint-disable-next-line max-len
          this.$store.commit(`jobPostings/setInputted${this.selectedOption.timeFrame}WageRange`, structuredClone(this.selectedOption));
        }

        this.$refs.addCompanyWagesForm.reset();
        this.selectedOption = {
          timeFrame: null, min: null, max: null, completed: false, deleted: false,
        };
        this.editing = false;
      } else if (!this.$refs['company-name'].valid) {
        this.companyNameError = true;
      }
    },
    storeCompanyData() {
      if (this.valid && !this.editing) {
        this.additionalTimeframe();
      } else if (this.valid && this.editing) {
        this.completeEditing();
      }
      // eslint-disable-next-line max-len
      if ((this.determineLocalCompletedWageTimeFrames.length === 0 && this.completedStateWageTimeFrames.length === 0)) {
        return;
      }
      // we have valid information and are trying to click save
      if (this.$refs.addCompanyWagesForm.validate() && this.$refs['company-name'].valid) {
        this.determineAndStoreCompanyData(true);
        // eslint-disable-next-line max-len
      } else if (this.$refs['company-name'].valid && (this.completedLocalWageTimeFrames.length > 0 || this.completedStateWageTimeFrames.length > 0) && !this.$refs.addCompanyWagesForm.validate()) {
        // we have no valid information inside the the form but completed a wage time frame
        this.saveCompanyData();
        this.$refs.addCompanyWagesForm.reset();
        this.$emit('close');
        this.$emit('completed');
      }
    },
    determineAndStoreCompanyData(exit = false) {
      if (this.isMaxError()) {
        return;
      }
      this.selectedOption.completed = true;
      this.saveCompanyData();
      this.editing = false;
      this.companyNameError = false;

      this.selectedOption = {
        timeFrame: null, min: null, max: null, completed: false,
      };
      this.completedWageTimeFrames = [];
      this.$refs.addCompanyWagesForm.reset();
      if (exit || this.determineItems.length === 0) {
        this.$emit('close');
        this.$emit('completed');
      }
    },
    saveCompanyData() {
      this.$store.commit('jobPostings/setCompanyName', this.inputtedCompanyName);

      // remove any state wage time frames
      this.deletedStateWageTimeFrames.forEach((deletedWageTimeFrame) => {
        if (deletedWageTimeFrame.timeFrame === 'Cents per Mile') {
          this.$store.commit('jobPostings/setInputtedCPMWageRange', {
            timeFrame: 'Cents per Mile', min: null, max: null, completed: false,
          });
        } else {
          // eslint-disable-next-line max-len
          this.$store.commit(`jobPostings/setInputted${deletedWageTimeFrame.timeFrame}WageRange`, {
            timeFrame: deletedWageTimeFrame.timeFrame, min: null, max: null, completed: false,
          });
        }
      });
      // add in the completed local wages
      this.completedLocalWageTimeFrames.forEach((completedWageTimeFrame) => {
        // here were saying that the one has been deleted so remove it from state
        if (completedWageTimeFrame.timeFrame === 'Cents per Mile') {
          this.$store.commit('jobPostings/setInputtedCPMWageRange', completedWageTimeFrame);
        } else {
          // eslint-disable-next-line max-len
          this.$store.commit(`jobPostings/setInputted${completedWageTimeFrame.timeFrame}WageRange`, completedWageTimeFrame);
        }
      });
      // updated the state wages
      this.completedStateWageTimeFrames.forEach((completedWageTimeFrame) => {
        if (completedWageTimeFrame.edited) {
          if (completedWageTimeFrame.timeFrame === 'Cents per Mile') {
            this.$store.commit('jobPostings/setInputtedCPMWageRange', completedWageTimeFrame);
          } else {
            // eslint-disable-next-line max-len
            this.$store.commit(`jobPostings/setInputted${completedWageTimeFrame.timeFrame}WageRange`, completedWageTimeFrame);
          }
        }
      });

      // Empty the old deleted timeframe data on save of form.
      this.deletedStateWageTimeFrames = [];
      this.clearModal();
    },
  },
};
</script>
