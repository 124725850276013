export default {
  setMapsData(state, mapsData) {
    state.mapsData = mapsData;
  },
  setMapsDataLoading(state, mapsDataLoading) {
    state.mapsDataLoading = mapsDataLoading;
  },
  setLaborForcePercentileFilter(state, laborForcePercentileFilter) {
    state.laborForcePercentileFilter = laborForcePercentileFilter;
  },
  setMaxLeadCountFilterValue(state, maxLeadCountFilterValue) {
    state.maxLeadCountFilterValue = maxLeadCountFilterValue;
  },
  setSelectedMinLeadCountFilterValue(state, selectedMinLeadCountFilterValue) {
    state.selectedMinLeadCountFilterValue = selectedMinLeadCountFilterValue;
  },
  setMaxHireCountFilterValue(state, maxHireCountFilterValue) {
    state.maxHireCountFilterValue = maxHireCountFilterValue;
  },
  setSelectedMinHireCountFilterValue(state, selectedMinHireCountFilterValue) {
    state.selectedMinHireCountFilterValue = selectedMinHireCountFilterValue;
  },
  setMaxEmploymentCountFilterValue(state, maxEmploymentCountFilterValue) {
    state.maxEmploymentCountFilterValue = maxEmploymentCountFilterValue;
  },
  setSelectedMinEmploymentCountFilterValue(state, selectedMinEmploymentCountFilterValue) {
    state.selectedMinEmploymentCountFilterValue = selectedMinEmploymentCountFilterValue;
  },
  setMapsFiltersUpperLimits(state, mapFilters) {
    state.mapFilters = mapFilters;
  },
};
