function symmetricDifference(first, second) {
  const a = new Set(first);
  const b = new Set(second);

  return [
    ...first.filter((x) => !b.has(x)),
    ...second.filter((x) => !a.has(x)),
  ];
}

function groupBy(list, keyGetter) {
  return list.reduce((map, item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }

    return map;
  }, new Map());
}

export {
  symmetricDifference,
  groupBy,
};
