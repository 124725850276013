<template>
  <div>
    <div class="accordion-panel" :class="{ 'not-expanded': !this.isExpanded }">
      <header v-if="leftArrowDropUp" class="panel-header" @click="togglePanelState">
        <a class="panel-header-icon">
          <span class="icon">
            <v-icon>mdi-menu-down</v-icon>
          </span>
        </a>

        <p class="panel-header-title">
          <slot name="title" v-if="hasTitleSlot"></slot>
          <span v-if="!hasTitleSlot">{{title}}</span>
        </p>
      </header>

      <header v-else class="panel-header" @click="togglePanelState">
        <p class="panel-header-title">
          <slot name="title" v-if="hasTitleSlot"></slot>
          <span v-if="!hasTitleSlot">{{title}}</span>
        </p>
        <a class="panel-header-icon">
          <span class="icon">
            <v-icon>mdi-menu-up</v-icon>
          </span>
        </a>
      </header>

      <transition name="accordion"
        v-on:before-enter="beforeEnter" v-on:enter="enter"
        v-on:before-leave="beforeLeave" v-on:leave="leave">
        <div class="panel-content" v-show="this.isExpanded" ref="content">
          <div class="content">
            <slot></slot>
          </div>
        </div>
      </transition>

      <span class="divider"></span>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
export default {
  name: 'Accordion',
  props: {
    title: String,
    expanded: {
      type: Boolean,
      default: true,
    },
    leftArrowDropUp: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isExpanded: this.expanded,
    };
  },
  computed: {
    hasTitleSlot() {
      return (this.$slots.title || []).length > 0;
    },
  },
  methods: {
    togglePanelState() {
      this.isExpanded = !this.isExpanded;
    },

    beforeEnter(el) {
      el.style.height = '0';
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    beforeLeave(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    leave(el) {
      el.style.height = '0';
    },
    onResize() {
      if (this.isExpanded) {
        this.$refs.content.style.height = 'auto';
        this.$refs.content.style.height = `${this.$refs.content.scrollHeight}px`;
      }
    },
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style scoped lang="scss">
.accordion-panel {
  margin-bottom: 10px;
  width: 100%;
}

.accordion-panel .panel-header {
  min-height: 40px;
  margin-bottom: 16px;
  cursor: pointer;

  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.accordion-panel .panel-header .panel-header-icon {
  display: flex;
  align-items: center;
  padding-right: 10px;

  cursor: pointer;

  text-decoration: none;
}

.accordion-panel .panel-header .panel-header-title {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0;

  font-size: 20px;
}

.accordion-panel .panel-header .icon .v-icon {
  transform: rotate(180deg);
  transition: transform 150ms ease-out;
  color: gray;
}

.accordion-panel.not-expanded .panel-header .icon .v-icon {
  transform: rotate(0deg);
}

.accordion-panel .panel-content {
  transition: 150ms ease;
  overflow: hidden;
}

.panel-content {
  .content {
    margin-bottom: 2%;
  }
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 0 !important;
}
</style>
