<template>
  <div>
    <canvas id="leadToHire"></canvas>
  </div>
</template>

<script>
import { Chart } from 'chart.js';

export default {
  name: 'LeadToHire',
  props: {
    data: { type: Object, required: true },
    showPreviousYear: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    chart: null,
  }),
  watch: {
    showPreviousYear() {
      this.chart.data.datasets[1].hidden = !this.showPreviousYear;
      this.chart.update();
    },
  },
  computed: {
    chartData() {
      return {
        labels: this.$props.data.months,
        spanGaps: false,
        datasets: [
          // Selected Months Data
          {
            fill: false,
            borderColor: '#70A300',
            borderWidth: 5,
            pointBorderWidth: 2,
            currentRatio: this.$props.data.selected_dates_hire_ratio,
            previousRatio: this.$props.data.previous_year_hire_ratio,
            spendAmount: this.$props.data.selectedYearSpendAmount,
            campaignCount: this.$props.data.selectedYearCampaignCount,
            data: this.$props.data.selected_dates_hire_rate,
            tension: 0,
            pointRadius: (context) => {
              const spendAmount = context.dataset.spendAmount[context.dataIndex];
              const campaignCount = context.dataset.campaignCount[context.dataIndex];
              // eslint-disable-next-line max-len
              if ((campaignCount < 8 && campaignCount >= 3) || (spendAmount < 50000 && spendAmount >= 20000)) {
                return 6;
              }
              return 3;
            },
            pointStyle: (context) => {
              const spendAmount = context.dataset.spendAmount[context.dataIndex];
              const campaignCount = context.dataset.campaignCount[context.dataIndex];
              // eslint-disable-next-line max-len
              if ((campaignCount < 8 && campaignCount >= 3) || (spendAmount < 50000 && spendAmount >= 20000)) {
                return 'triangle';
              }
            },
            pointBorderColor: (context) => {
              const spendAmount = context.dataset.spendAmount[context.dataIndex];
              const campaignCount = context.dataset.campaignCount[context.dataIndex];
              // eslint-disable-next-line max-len
              if ((campaignCount < 8 && campaignCount >= 3) || (spendAmount < 50000 && spendAmount >= 20000)) {
                return 'rgba(198, 83, 1, 1)';
              }
              return '#70A300';
            },
            pointBackgroundColor: (context) => {
              const spendAmount = context.dataset.spendAmount[context.dataIndex];
              const campaignCount = context.dataset.campaignCount[context.dataIndex];
              // eslint-disable-next-line max-len
              if ((campaignCount < 8 && campaignCount >= 3) || (spendAmount < 50000 && spendAmount >= 20000)) {
                return 'rgba(198, 83, 1, 1)';
              }
              return '#70A300';
            },
          },
          // Previous Year Data
          {
            fill: false,
            borderColor: '#C4C4C3',
            borderWidth: 5,
            pointBorderWidth: 2,
            currentRatio: this.$props.data.selected_dates_hire_ratio,
            previousRatio: this.$props.data.previous_year_hire_ratio,
            data: this.$props.data.previous_year_hire_rate,
            previousYearSpendAmounts: this.$props.data.previous_year_spend_amounts,
            tension: 0,
            // pointStyle: function(context) {
            //   const spendAmount = context.dataset.previousYearSpendAmounts[context.dataIndex];
            //   if (spendAmount < 50000 && spendAmount >= 20000) {
            //     return 'triangle';
            //   }
            // },
            // pointBorderColor: function(context) {
            //   const spendAmount = context.dataset.previousYearSpendAmounts[context.dataIndex];
            //   if (spendAmount < 50000 && spendAmount >= 20000) {
            //     return 'rgba(198, 83, 1, 1)';
            //   }
            // },
            // pointBackgroundColor: function(context) {
            //   const spendAmount = context.dataset.previousYearSpendAmounts[context.dataIndex];
            //   if (spendAmount < 50000 && spendAmount >= 20000) {
            //     return 'rgba(198, 83, 1, 1)';
            //   }
            // },
          },
        ],
      };
    },
    options() {
      return {
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            enabled: true,
            position: 'nearest',
            label: this.$props.data.tooltipLabels,
            callbacks: {
              title() {
                return '';
              },
              label(data) {
                const { dataIndex, label, chart } = data;
                if (data.datasetIndex === 0) {
                  return `${label} LTH Ratio: ${chart.data.datasets[data.datasetIndex].currentRatio[dataIndex]}`;
                }
                if (!this.chart.data.datasets[1].hidden) {
                  return `Previous Year LTH Ratio: ${chart.data.datasets[data.datasetIndex].previousRatio[dataIndex]}`;
                }
              },
              labelColor(data) {
                const { datasetIndex } = data;
                if (datasetIndex === 0) {
                  return {
                    backgroundColor: '#70A300',
                    borderWidth: 0,
                  };
                }
                return {
                  backgroundColor: '#C4C4C3',
                  borderWidth: 0,
                };
              },
            },
          },
        },
        scales: {
          y:
            {
              ticks: {
                min: 0,
                max: this.max, // Your absolute max value
                callback: (value) => `${value}%`, // convert it to percentage
              },
              id: 'A',
              position: 'left',
              reverse: true,
              scaleLabel: {
                display: true,
              },
              grid: {
                display: false,
              },
              beginAtZero: true,
            },
          x:
            {
              ticks: {
                maxTicksLimit: 60,
                display: true,
              },
              grid: {
                display: false,
              },
            },
        },
        title: {
          display: false,
          text: 'LTH by Month',
        },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
  },
  destroyed() {
    this.$destroy();
  },
  async mounted() {
    // eslint-disable-next-line no-new
    this.chart = new Chart(
      document.getElementById('leadToHire'),
      {
        type: 'line',
        options: this.options,
        data: this.chartData,
      },
    );
    this.chart.data.datasets[1].hidden = !this.showPreviousYear;
    this.chart.update();
  },
};
</script>

<style></style>
