<template>
  <div class="login-page">
    <LoadingSpinner
      class="login-loading-spinner"
      :loading="true"
      :color="'#70A300'"
      :size="'200px'"
      :borderWidth="'14px'"
    />
  </div>
</template>

<script>
import LoadingSpinner from './StyledComponents/LoadingSpinner.vue';

export default {
  name: 'Login',
  components: {
    LoadingSpinner,
  },
};
</script>
