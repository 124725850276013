export default {
  getMostRecentMonthTrends(state) {
    return state.mostRecentMonthTrends;
  },
  getMostRecentMonthTrendsLoading(state) {
    return state.mostRecentMonthTrendsLoading;
  },
  getDriverValues(state) {
    return state.driverValues;
  },
  getRouteTypeValues(state) {
    return state.routeTypeValues;
  },
  getHaulTypeValues(state) {
    return state.haulTypeValues;
  },
  getEndorsementRequiredValues(state) {
    return state.endorsementRequiredValues;
  },
  getCphData(state) {
    return state.cphData;
  },
  getCphChartKey(state) {
    return state.cphChartKey;
  },
  getLthData(state) {
    return state.lthData;
  },
  getCplData(state) {
    return state.cplData;
  },
  getCplChartKey(state) {
    return state.cplChartKey;
  },
  getLthChartKey(state) {
    return state.lthChartKey;
  },
  // getThreeMonthAverageLoading(state) {
  //   return state.threeMonthAverageLoading;
  // },
};
