export default {
  updateModalOpen(state, payload) {
    state.modalOpen = payload;
  },

  setAdminNotifications(state, payload) {
    state.adminNotifications = payload.result;
    state.adminTotal = payload.total;
    state.adminNotificationsCurrentPage = parseInt(payload.page, 10);
  },

  setUserNotifications(state, payload) {
    state.userNotifications = payload.notifications.map((item) => ({
      ...item,
      readMoreActivated: false,
    }));
    state.userTotal = payload.total;
  },

  addNewNotification(state, payload) {
    if (state.lastNotificationReceived === {} || state.lastNotificationReceived !== payload) {
      state.userNotifications = [payload.data.notification, ...state.userNotifications];
      state.lastNotificationReceived = payload;
      state.userTotal += 1;
    }
  },

  addNotificationToAdminTable(state, payload) {
    state.adminNotifications = [...state.adminNotifications, payload.result];
    state.adminTotal += 1;
    state.adminNotificationsCurrentPage = parseInt(payload.page, 10);
  },

  setUserNotificationsReadMoreActivate(state, payload) {
    state.userNotifications = state.userNotifications.map((item) => {
      if (item.id === payload) {
        return {
          ...item,
          readMoreActivated: !item.readMoreActivated,
        };
      }
      return item;
    });
  },
};
