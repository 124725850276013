export default function setDialogPosition() {
  // Need to execute below once dialog loads
  // Parent function does not return Promise. Hence adding timeout
  setTimeout(() => {
    const dialog = document.getElementById('hubspot-form');
    const totalWidth = window.innerWidth;
    const dialogWidth = dialog.offsetWidth;
    const leftPosition = (totalWidth - dialogWidth) / 2;
    dialog.style.left = `${leftPosition}px`;

    const totalHeight = window.innerHeight;
    const dialogHeight = dialog.offsetHeight;
    const topPosition = (totalHeight - dialogHeight) / 2;
    dialog.style.top = `${topPosition}px`;
  }, 10);
}
