<!-- eslint-disable max-len -->
<template>
  <div data-app>
    <v-card class="no-data" elevation="2" width="100%" v-if="determineWhenToShowNoData">
      <NoData
        class="competition-page-no-data"
        error-message="No Data to Display"
        error-sub-message="Please adjust your filter selections to get results."
      />
      <img alt="blank pay rate chart icon" src="../../../assets/Company-Graph-background-viz.svg" class="no-data-image" />
    </v-card>
    <div v-show="determineWhenToShowPayRate">
      <div class="filter-area">
        <div class="company-names">
          <div class="grouped">
            <p>Company Names</p>
            <v-menu
            bottom
            :offset-x="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs"
                  v-on="on" aria-label="filter the data" small> mdi-sort-variant </v-icon>
              </template>

              <v-list dense>
                <v-list-item-group>
                  <v-list-item v-for="item in sortOptions" :key="item.key" @click="handleSorting(item)">
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{item.title}}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
          <!-- <div class="magnifying-glass-pay-rate">
            <v-menu
            @input="openCloseMenu"
            left
            :offset-x="true"
            :close-on-content-click="false"
            location="end"
            >
              <template v-slot:activator="{on, attrs}">
                <v-icon v-bind="attrs"
                v-on="on" aria-label="search for a company in the data" small>mdi-magnify</v-icon>
              </template>
              <v-card>
                <v-autocomplete
                  class="pay-rate-search-company-autocomplete"
                  :no-data-text="'No companies match that search criteria'"
                  placeholder="Find a company"
                  :append-icon="''"
                  multiple
                  solo
                  ref="autocomplete"
                  :cache-items="true"
                  :full-width="false"
                  v-model="chosenCompany"
                  :items="allCompanies"
                  @change="handleCompanySearch" >
                  <template v-slot:selection></template>
                </v-autocomplete>
              </v-card>
            </v-menu>
          </div> -->
        </div>
      </div>
      <div class="loading-animation-for-pay-rate" style="position: absolute;" v-show="selectedDistributionWageRangesLoading">
        <img src="../../../assets/gears.gif" alt="loading"/>
      </div>
      <v-row v-show="this.showUserWageChart" id="static-wage-data-wrapper">
        <canvas id="staticWageData" width="200" class="pay-rate-chart"></canvas>
      </v-row>
      <div class="scrollBoxBody">
        <div :style="{height: determineHeight, 'margin-top:30px': true}" >
          <canvas id='myChart' width="200" class='pay-rate-chart'></canvas>
        </div>
      </div>
      <div style="height: 50px;">
        <canvas id='myChart2'  class='pay-rate-chart'></canvas>
      </div>
      <div v-if="paginated">
        <Pagination
          :paginations="determinePaginations"
          :data-per-page="250"
          :count-of-data="payData.length"
          @page-selected="handlePaginationChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Chart from 'chart.js/auto';
import { mapGetters } from 'vuex';
import NoData from '../StyledComponents/NoData.vue';
import Pagination from '../../StyledComponents/Pagination.vue';

export default {
  name: 'PayRate',
  components: {
    NoData,
    Pagination,
  },
  props: {
    minimumLabel: { type: String, required: true },
    maximumLabel: { type: String, required: true },
    isGreenValue: { type: Object, required: false },
    payData: [Object, Array],
    topLabel: { type: String, required: true },
    searchedTimeFrame: { type: String, required: true, default: 'Yearly' },
  },
  data() {
    return {
      searchedCompanies: [],
      chart: null,
      page: 1,
      companyWageChart: null,
      chosenCompany: null,
      xAxisChart: null,
      sortOptions: [
        {
          title: 'Sort by Max', key: 0, icon: 'mdi-sort-numeric-descending', columnName: this.maximumLabel, ascending: false,
        },
        {
          title: 'Sort by Min', key: 1, icon: 'mdi-sort-numeric-ascending', columnName: this.minimumLabel, ascending: true,
        },
        {
          title: 'Sort by Company Name', key: 2, icon: 'mdi-sort-alphabetical-ascending', columnName: this.topLabel, ascending: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('jobPostings', {
      companyName: 'getCompanyName',
      yearlyWageRange: 'getInputtedYearlyWageRange',
      weeklyWageRange: 'getInputtedWeeklyWageRange',
      hourlyWageRange: 'getInputtedHourlyWageRange',
      cpmWageRange: 'getInputtedCPMWageRange',
      selectedDistributionWageRanges: 'getSelectedDistributionWageRanges',
      selectedDistributionWageRangesUsed: 'getSelectedDistributionWageRangesUsed',
      advertisedWageRangePresence: 'getAdvertisedWageRangePresence',
      showUserWageChart: 'getUserChartDisplayState',
      selectedDistributionWageRangesLoading: 'getSelectedDistributionWageRangesLoading',
    }),
    determineHeight() {
      switch (true) {
        case this.$props.payData.length < 3:
          // eslint-disable-next-line no-case-declarations
          const limitedData = this.$props.payData.length * 60;
          // eslint-disable-next-line prefer-template
          return limitedData + 'px';
        case this.$props.payData.length > 250:
          return '5000px';
        default:
          // eslint-disable-next-line no-case-declarations
          const calculated = this.$props.payData.length * 25;
          // eslint-disable-next-line prefer-template
          return calculated + 'px';
      }
    },
    determinePaginations() {
      if (this.selectedDistributionWageRangesUsed) {
        return Math.round(this.selectedDistributionWageRanges.length / 250);
      }
      return Math.round(this.$props.payData.length / 250);
    },
    paginated() {
      if (this.selectedDistributionWageRangesUsed) {
        return this.selectedDistributionWageRanges.length > 250;
      }
      return this.chosenCompany ? false : this.$props.payData.length > 250;
    },
    allCompanies() {
      return this.$props.payData.map((pd) => pd[`${this.topLabel}`]);
    },
    companies() {
      return this.$props.payData.slice(0, 250).map((pd) => pd[`${this.topLabel}`]);
    },
    determineMinimum() {
      return this.$props.payData.slice(this.page, 250).map((pd) => pd[`${this.minimumLabel}`]);
    },
    determineMaximum() {
      return this.$props.payData.slice(this.page, 250).map((pd) => pd[`${this.maximumLabel}`]);
    },
    determineWhenToShowNoData() {
      return (!this.selectedDistributionWageRangesUsed && !this.advertisedWageRangePresence)
      || (
        this.selectedDistributionWageRangesUsed && this.selectedDistributionWageRanges.length === 0
      );
    },
    determineWhenToShowPayRate() {
      // eslint-disable-next-line max-len
      return (this.selectedDistributionWageRangesUsed && this.selectedDistributionWageRanges.length > 0)
      // eslint-disable-next-line max-len
      || (!this.selectedDistributionWageRangesUsed && this.advertisedWageRangePresence && this.payData.length > 0);
    },
  },
  watch: {
    selectedDistributionWageRangesLoading() {
      if (this.selectedDistributionWageRangesLoading) {
        document.getElementById('myChart').style.opacity = '20%';
        document.getElementById('myChart2').style.opacity = '20%';
        document.getElementsByClassName('scrollBoxBody')[0].style.overflow = 'hidden';
        if (this.$refs['pagination-pay-rate'] && this.$refs['pagination-pay-rate'][0]) {
          this.$refs['pagination-pay-rate'][0].disable = true;
        }
      } else {
        document.getElementById('myChart').style.opacity = '100%';
        document.getElementById('myChart2').style.opacity = '100%';
        document.getElementsByClassName('scrollBoxBody')[0].style.overflowY = 'scroll';
        if (this.$refs['pagination-pay-rate'] && this.$refs['pagination-pay-rate'][0]) {
          this.$refs['pagination-pay-rate'][0].disable = false;
        }
      }
    },
    '$store.state.jobPostings.inputtedYearlyWageRange': function () {
      this.addInCompanyWage();
    },
    '$store.state.jobPostings.inputtedWeeklyWageRange': function () {
      this.addInCompanyWage();
    },
    '$store.state.jobPostings.inputtedHourlyWageRange': function () {
      this.addInCompanyWage();
    },
    '$store.state.jobPostings.inputtedCPMWageRange': function () {
      this.addInCompanyWage();
    },
    '$store.state.jobPostings.selectedDistributionWageRanges': function () {
      this.page = 1;
      if (this.selectedDistributionWageRangesUsed) {
        this.addInSelectedDistributionWageRanges();
      } else {
        this.removeSelectedDistributionWage();
      }
    },
  },
  async mounted() {
    const chartData = [];
    if (this.$props.payData.length > 0) {
      this.$props.payData.slice(0, 250).forEach((pd) => {
        chartData.push([pd[this.$props.maximumLabel], pd[this.$props.minimumLabel]]);
      });
    }
    const labels = this.convertLabels();
    // Load first page initially
    const ctx = document.getElementById('myChart');
    const ctx2 = document.getElementById('myChart2');
    const data = {
      labels,
      datasets: [
        {
          axis: 'y',
          label: 'Min and Max Advertised Pay',
          data: chartData,
          borderRadius: 80,
          borderSkipped: false,
          backgroundColor: ['rgb(146, 146, 146)', 'rgb(146, 146, 146)'],
          minBarLength: 7,
          barPercentage: 0.30,
          categoryPercentage: 0.8,
          maxBarThickness: 5,
        },
      ],
    };
    // eslint-disable-next-line no-new
    this.chart = new Chart(ctx, {
      type: 'bar',
      data,
      options: {
        indexAxis: 'y',
        maintainAspectRatio: false,
        responsive: true,
        elements: {
          bar: {
            borderWidth: 0,
            minBarLength: 7,
            width: 10,
            barPercentage: 0.30,
            categoryPercentage: 0.8,
            maxBarThickness: 5,
          },
        },
        legend: {
          display: false,
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            xAlign: 'left',
            yAlign: 'top',
            callbacks: {
              label(tooltipItem) {
                const minValue = tooltipItem.dataset.data[tooltipItem.dataIndex][1];
                const maxValue = tooltipItem.dataset.data[tooltipItem.dataIndex][0];
                let minLabel = 'Min ';
                let maxLabel = 'Max ';
                if (minValue < 50) {
                  minLabel += new Intl.NumberFormat('en-US', {
                    style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2,
                  }).format(minValue);
                } else {
                  minLabel += new Intl.NumberFormat('en-US', {
                    style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 0,
                  }).format(minValue);
                }

                if (maxValue < 50) {
                  maxLabel += new Intl.NumberFormat('en-US', {
                    style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2,
                  }).format(maxValue);
                } else {
                  maxLabel += new Intl.NumberFormat('en-US', {
                    style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 0,
                  }).format(maxValue);
                }

                return [minLabel, maxLabel];
              },
            },
          },
        },
        scales: {
          x: {
            beginAtZero: false,
            grid: {
              display: false,
              drawTicks: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: false,
              font: {
                weight: 'bold',
              },
              // eslint-disable-next-line object-shorthand, no-unused-vars
              callback: function (value, index, ticks) {
                const label = this.chart.data.labels[index];
                if (label.length > 25) {
                  return `${label.slice(0, 25)}...`;
                }
                return label;
              },
            },
          },
        },
        layout: {
          padding: {
            right: 20,
          },
        },
      },
    });
    const maxData = Math.max(...this.$props.payData.map((pd) => pd[`${this.maximumLabel}`])) + this.determineMaxAddedAmount();
    const minData = Math.min(...this.$props.payData.map((pd) => pd[`${this.minimumLabel}`])) - this.determineMinAddedAmount();

    // eslint-disable-next-line no-new
    this.xAxisChart = new Chart(ctx2, {
      type: 'bar',
      options: {
        layout: {
          padding: {
            right: 10,
          },
        },
        indexAxis: 'y',
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false,
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
        },
        scales: {
          x: {
            beginAtZero: false,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              beginAtZero: false,
              autoSkip: false,
              // eslint-disable-next-line arrow-body-style
              callback: (value) => {
                const absoluteValue = Math.abs(value);
                // eslint-disable-next-line no-nested-ternary
                if (absoluteValue > 999) {
                  return `${Math.sign(value) * ((absoluteValue / 1000).toFixed(1))}k`;
                }

                if (absoluteValue >= 7) {
                  return `$${value.toLocaleString()}`;
                }

                return `${value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`;
              },
            },
          },
          y: {
            afterFit: ((context) => {
              // eslint-disable-next-line no-param-reassign
              context.width += this.chart.chartArea.left;
            }),
            grid: {
              display: false,
              drawTicks: false,
              drawBorder: false,
            },
          },
        },
      },
    });
    this.setXAxisScale(maxData, minData, this.xAxisChart, false);
    this.addInCompanyWage();
  },
  methods: {
    setXAxisScale(maxData, minData, chart, mainChart = true) {
      // eslint-disable-next-line no-param-reassign
      chart.options.scales.x = {
        grid: {
          display: false,
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          beginAtZero: false,
          autoSkip: !mainChart,
          display: !mainChart,
        },
        max: maxData,
        min: minData,
      };
      chart.update();
    },
    convertLabels() {
      return this.$props.payData.slice(0, 250).map((pd) => pd[this.$props.topLabel]);
    },
    adjustMinMaxForAllCharts(minData, maxData) {
      let suggestedMin;
      if (Math.min(...minData) - this.determineMinAddedAmount() <= 0) {
        suggestedMin = 0;
      } else {
        suggestedMin = Math.min(...minData) - this.determineMinAddedAmount();
      }
      const suggestedMax = Math.max(...maxData) + this.determineMaxAddedAmount();
      this.setXAxisScale(suggestedMax, suggestedMin, this.chart);
      this.setXAxisScale(suggestedMax, suggestedMin, this.xAxisChart, false);
      if (this.companyWageChart) {
        this.companyWageChart.options.scales.x = {
          grid: {
            display: false,
            drawTicks: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
          max: suggestedMax,
          min: suggestedMin,
        };
        this.companyWageChart.update();
      }
    },
    adjustMainChartDataAndLabels(chartData, labels, minData, maxData) {
      const formattedLabels = labels.map((l) => {
        if (l.length > 25) {
          return `${l.slice(0, 25)}...`;
        }
        return l;
      });

      this.chart.data.datasets[0].data = chartData;
      this.chart.data.labels = formattedLabels;
      this.adjustMinMaxForAllCharts(minData, maxData);
    },
    getMaxAndMin(chartData) {
      const inputtedWageRange = this.setInputtedWageRange();
      const maxData = chartData.map((d) => d[this.maximumLabel]);
      maxData.push(inputtedWageRange.max);
      const minData = chartData.map((d) => d[this.minimumLabel]);
      minData.push(inputtedWageRange.min);
      return {
        minData,
        maxData,
      };
    },
    setInputtedWageRange() {
      if (this.searchedTimeFrame === 'CPM') {
        return this.cpmWageRange;
      }
      return this.$store.getters[`jobPostings/getInputted${this.searchedTimeFrame}WageRange`];
    },
    addInSelectedDistributionWageRanges() {
      const inputtedWageRange = this.setInputtedWageRange();
      let chartData = this.selectedDistributionWageRanges.slice(0, 250);
      chartData = chartData.sort((a, b) => b.max - a.max);

      const wageRangeData = chartData.map((d) => [d.max, d.min]);
      const labels = chartData.map((d) => d.company_name_to_display);
      const maxData = chartData.map((d) => d.max);
      const minData = chartData.map((d) => d.min);
      if (inputtedWageRange.completed) {
        maxData.push(inputtedWageRange.max);
        minData.push(inputtedWageRange.min);
      }

      this.adjustMainChartDataAndLabels(wageRangeData, labels, minData, maxData);

      this.addInCompanyWage();
    },
    removeSelectedDistributionWage() {
      const inputtedWageRange = this.setInputtedWageRange();
      this.page = 1;

      let chartData = this.$props.payData.slice(0, 250);
      chartData = chartData.sort((a, b) => b.max - a.max);

      const wageRangeData = chartData.map((d) => [d[this.maximumLabel], d[this.minimumLabel]]);
      const labels = chartData.map((d) => d[this.$props.topLabel]);
      const maxData = chartData.map((d) => d[this.maximumLabel]);
      const minData = chartData.map((d) => d[this.minimumLabel]);
      if (inputtedWageRange.completed) {
        maxData.push(inputtedWageRange.max);
        minData.push(inputtedWageRange.min);
      }

      this.adjustMainChartDataAndLabels(wageRangeData, labels, minData, maxData);
    },
    openCloseMenu(open) {
      if (!open) {
        const labels = this.searchedCompanies;
        // eslint-disable-next-line max-len
        let data = this.searchedCompanies.map((searchedCompany) => this.payData.find((company) => company.companies_postings === searchedCompany));
        data = data.map((d) => [d[this.maximumLabel], d[this.minimumLabel]]);
        // this needs to update the xAxisChart as well as the labels can vary
        this.chart.data.datasets[0].data = data;
        this.chart.data.labels = labels;
        this.chart.update();
      }
    },
    addInCompanyWage() {
      if (this.searchedTimeFrame === 'CPM') {
        if (this.cpmWageRange.completed) {
          this.addInCompanyWages(this.cpmWageRange);
        } else {
          this.destroyChartIfExists();
        }
      } else {
        const foundWageData = this.$store.getters[`jobPostings/getInputted${this.searchedTimeFrame}WageRange`];
        if (foundWageData.completed) {
          this.addInCompanyWages(foundWageData);
        } else {
          this.destroyChartIfExists();
        }
      }
    },
    updateUserDataChart(data, minData, maxData) {
      this.companyWageChart.data.datasets[0].data = data;
      this.adjustMinMaxForAllCharts(minData, maxData);
    },
    createUserDataChart(data, elementId, suggestedMax, suggestedMin) {
      let calculatedHeight;
      const element = document.getElementById(elementId);
      this.companyWageChart = new Chart(element, {
        type: 'bar',
        data,
        options: {
          indexAxis: 'y',
          maintainAspectRatio: false,
          responsive: true,
          elements: {
            bar: {
              borderWidth: 0,
              minBarLength: 7,
              barPercentage: 0.30,
              categoryPercentage: 0.8,
              maxBarThickness: 5,
            },
          },
          legend: {
            display: false,
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label(tooltipItem) {
                  const minValue = tooltipItem.dataset.data[tooltipItem.dataIndex][0];
                  const maxValue = tooltipItem.dataset.data[tooltipItem.dataIndex][1];

                  let minLabel = 'Min ';
                  let maxLabel = 'Max ';

                  if (minValue < 50) {
                    minLabel += new Intl.NumberFormat('en-US', {
                      style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2,
                    }).format(minValue);
                  } else {
                    minLabel += new Intl.NumberFormat('en-US', {
                      style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 0,
                    }).format(minValue);
                  }

                  if (maxValue < 50) {
                    maxLabel += new Intl.NumberFormat('en-US', {
                      style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2,
                    }).format(maxValue);
                  } else {
                    maxLabel += new Intl.NumberFormat('en-US', {
                      style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 0,
                    }).format(maxValue);
                  }

                  return [minLabel, maxLabel];
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
                drawTicks: false,
                drawBorder: false,
              },
              ticks: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
              },
              ticks: {
                autoSkip: false,
                font: {
                  weight: 'bold',
                },
              },
              afterFit: ((context) => {
                // eslint-disable-next-line no-param-reassign
                context.width += this.chart.chartArea.left - context.width;
                calculatedHeight = this.chart.chartArea.top;
              }),
            },
          },
        },
      });
      this.companyWageChart.options.scales.x = {
        grid: {
          display: false,
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
        max: Math.max(...suggestedMax),
        min: Math.min(...suggestedMin),
      };
      this.companyWageChart.update();
      this.companyWageChart.canvas.parentNode.style.minHeight = '80px';
      element.style['margin-bottom'] = `-${calculatedHeight}px`;

      // show user chart canvas
      this.$store.commit('jobPostings/setUserWageChartDisplayState', true);

      // we removed this one so that it doesn't overlap the other pieces of data
      // document.getElementById('static-wage-data-wrapper').style.position = 'absolute';
      document.getElementById('static-wage-data-wrapper').style.backgroundColor = 'white';
    },
    handleSorting(data) {
      const chartData = this.sortBy(data.columnName, data.ascending);
      const wageRangeData = chartData.map((d) => [d[this.maximumLabel], d[this.minimumLabel]]);
      const { maxData, minData } = this.getMaxAndMin(chartData);
      this.chart.data.labels = chartData.map((d) => d[this.$props.topLabel]);
      this.chart.data.datasets[0].data = wageRangeData;
      this.adjustMinMaxForAllCharts(minData, maxData);
    },
    sortBy(columnName, ascending) {
      const splitAmount = (this.page - 1) * 250;

      if (columnName !== 'companies_postings') {
        // eslint-disable-next-line max-len, no-confusing-arrow
        return this.payData.sort((a, b) => ascending ? a[columnName] - b[columnName] : b[columnName] - a[columnName]).slice(splitAmount, splitAmount + 250);
      }
      // eslint-disable-next-line max-len, no-confusing-arrow
      return this.payData.sort((a, b) => ascending ? a[columnName].localeCompare(b[columnName]) : b[columnName].localeCompare(a[columnName])).slice(splitAmount, splitAmount + 250);
    },
    handleCompanySearch(data) {
      if (data.length !== 0) {
        this.searchedCompanies = data;
      } else {
        // we reset the view if there is no company found aka they clear their search
        this.handlePaginationChange(this.page);
      }
    },
    handlePaginationChange(page) {
      this.$store.commit('jobPostings/setAdvertisedWageRangesLoading', true);

      const splitAmount = (page - 1) * 250;
      const chartData = this.payData.slice(splitAmount, splitAmount + 250);
      const wageRangeData = chartData.map((d) => [d[this.maximumLabel], d[this.minimumLabel]]);
      const labels = chartData.map((d) => d[this.$props.topLabel]);
      const { maxData, minData } = this.getMaxAndMin(chartData);
      this.adjustMainChartDataAndLabels(wageRangeData, labels, minData, maxData);
      this.$store.commit('jobPostings/setAdvertisedWageRangesLoading', false);
    },
    determineMaxAddedAmount() {
      switch (this.searchedTimeFrame) {
        case 'Yearly':
          return 20000;
        case 'Weekly':
          return 500;
        case 'Hourly':
          return 10;
        default:
          return 0.20;
      }
    },
    determineMinAddedAmount() {
      switch (this.searchedTimeFrame) {
        case 'Yearly':
          return 10000;
        case 'Weekly':
          return 600;
        case 'Hourly':
          return 0;
        default:
          return 0;
      }
    },
    addInCompanyWages(wageData) {
      const data = {
        labels: [this.companyName],
        datasets: [
          {
            axis: 'y',
            data: [[Number.parseFloat(wageData.min), Number.parseFloat(wageData.max)]],
            borderRadius: 80,
            borderSkipped: false,
            backgroundColor: ['rgb(112, 163, 0)', 'rgb(112, 163, 0)'],
            minBarLength: 7,
            barPercentage: 0.30,
            categoryPercentage: 0.8,
            maxBarThickness: 5,
          },
        ],
      };
      let maxData;
      let minData;
      const splitAmount = (this.page - 1) * 250;
      if (this.selectedDistributionWageRanges.length > 0) {
        const inputtedWageRange = this.setInputtedWageRange();
        // eslint-disable-next-line max-len
        const startingData = this.selectedDistributionWageRanges.slice(splitAmount, splitAmount + 250);
        maxData = startingData.map((d) => d.max);
        minData = startingData.map((d) => d.min);
        if (inputtedWageRange.completed) {
          maxData.push(inputtedWageRange.max);
          minData.push(inputtedWageRange.min);
        }
      } else {
        // eslint-disable-next-line max-len
        const maxAndMinData = this.getMaxAndMin(this.payData.slice(splitAmount, splitAmount + 250));
        maxData = maxAndMinData.maxData;
        minData = maxAndMinData.minData;
      }

      if (this.companyWageChart) {
        // eslint-disable-next-line max-len
        this.companyWageChart.data.datasets[0].data = [[Number.parseFloat(wageData.min), Number.parseFloat(wageData.max)]];
        this.companyWageChart.data.labels[0] = this.companyName;
        this.adjustMinMaxForAllCharts(minData, maxData);
      } else {
        // we will have to add in the current min and maxes of all charts
        this.createUserDataChart(data, 'staticWageData', maxData, minData);
        this.adjustMinMaxForAllCharts(minData, maxData);
      }
    },
    destroyChartIfExists() {
      const chartStatus = Chart.getChart('staticWageData');
      if (chartStatus !== undefined) {
        chartStatus.destroy();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#static-wage-data-wrapper {
  margin-left: 0%;
  margin-bottom: 0.2%;
}
.loading-animation-for-pay-rate {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-data-image {
  opacity: 20%;
}
.filter-area {
  .company-names{
    display: flex;
    justify-content: space-between;
  }
  .grouped {
    display:flex;
    .mdi-sort-variant {
      align-items: baseline !important;
    }
  }
}
.competition-page-no-data {
  position: absolute;
  height: 100%;
  width: 100%
}
.no-data {
  min-height: 500px;
}
.pay-rate-search-company-autocomplete {
  width: 304px;
}
.scrollBoxBody{
  height: 469px;
  overflow-y: scroll;
  position: relative;
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .pay-rate-chart {
    width: 95%;
    height: 100%;
  }
}
.v-menu__content {
  box-shadow: unset!important;
  margin-top: -10px;
}
canvas#race{position:relative; z-index:1;}
</style>
