<template>
  <div>
    <v-card
      class="mx-auto location-options-submenu"
      :style="{ top: xOffset + 'px' }"
    >
      <v-list>
        <v-subheader class="submenu-title"
          >Your search result is in the following locations:</v-subheader
        >
        <v-divider></v-divider>
        <v-list-item-group color="primary">
          <v-list-item
            v-if="subMenuData.msa_name"
            tabindex="0"
            :aria-label="'metropolitan area ' + subMenuData.msa_name"
            @click.prevent="
              searchLoc(
                {
                  location_type: 'msa',
                  location_code: subMenuData.msa_location_code,
                  location_name: subMenuData.msa_name,
                  location_code_geoid: subMenuData.msa_location_code
                },
                subMenuData.msa_name,
              )
            "
            @keydown.enter="
              searchLoc(
                {
                  location_code: subMenuData.msa_location_code,
                  location_type: 'msa',
                  location_name: subMenuData.msa_name,
                  location_code_geoid: subMenuData.msa_location_code
                },
                subMenuData.msa_name,
              )
            "
          >
            <v-list-item-content>
              <v-list-item-title>{{ subMenuData.msa_name }}</v-list-item-title>
              <v-list-item-subtitle class="location_type">Metropolitan Area</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="subMenuData.state_name"
            tabindex="0"
            :aria-label="'state ' + subMenuData.state_name"
            @click.prevent="
              searchLoc(
                {
                  location_code: subMenuData.state_location_code,
                  location_type: 'state',
                  location_name: subMenuData.state_name + ` (` + subMenuData.state + `)`,
                  location_code_geoid: subMenuData.state_location_code
                },
                subMenuData.state_name,
              )
            "
            @keydown.enter="
              searchLoc(
                {
                  location_code: subMenuData.state_location_code,
                  location_type: 'state',
                  location_name: subMenuData.state_name + ` (` + subMenuData.state + `)`,
                  location_code_geoid: subMenuData.state_location_code
                },
                subMenuData.state_name,
              )
            "
          >
            <v-list-item-content>
              <v-list-item-title
                >{{ subMenuData.state_name }} ({{ subMenuData.state }})</v-list-item-title
              >
              <v-list-item-subtitle class="location_type">State</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="subMenuData.county_name"
            tabindex="0"
            :aria-label="'county ' + subMenuData.county_name"
            @click.prevent="
              searchLoc(
                {
                  location_code: subMenuData.county_location_code,
                  location_type: 'county',
                  location_name: subMenuData.county_name,
                  location_code_geoid: subMenuData.county_location_code
                },
                subMenuData.county_name,
              )
            "
            @keydown.enter="
              searchLoc(
                {
                  location_code: subMenuData.county_location_code,
                  location_type: 'county',
                  location_name: subMenuData.county_name,
                  location_code_geoid: subMenuData.county_location_code
                },
                subMenuData.county_name,
              )
            "
          >
            <v-list-item-content>
              <v-list-item-title>{{ subMenuData.county_name }}</v-list-item-title>
              <v-list-item-subtitle class="location_type">County</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'SubMenuItems',
  props: {
    subMenuData: { type: Object, required: true },
    selectedMenuItem: { type: String, required: true },
  },
  data() {
    return {
      xOffset: 0,
    };
  },
  mounted() {
    this.changeOffset();
  },
  methods: {
    searchLoc(value, locationName) {
      this.$emit('searchLoc', value, locationName);
    },
    changeOffset() {
      const parent = document.querySelector(`${this.selectedMenuItem}`).getBoundingClientRect();
      const child = document.querySelector('.location-options-submenu').getBoundingClientRect();
      const parentPosition = parent.y;
      const childPosition = child.y;

      this.xOffset = parentPosition - childPosition;
    },
  },
  watch: {
    selectedMenuItem() {
      // Simply calling change offset results in wrong positions
      const parentElement = document.querySelector(`${this.selectedMenuItem}`);
      const childElement = document.querySelector('.location-options-submenu');
      const parentBox = parentElement.getBoundingClientRect();
      const childBox = childElement.getBoundingClientRect();
      const parentPosition = parentBox.y;
      const childPosition = childBox.y;
      const difference = Math.abs(parentPosition - childPosition);

      // Element.style.top return '100px'
      let childElementTop = childElement.style.top.split('p')[0];
      childElementTop = parseFloat(childElementTop, 10);

      if (parentPosition > childPosition) {
        this.xOffset = childElementTop + difference;
      } else {
        this.xOffset = childElementTop - difference;
      }
    },
  },
};
</script>

<style scoped>
.location-options-submenu {
  position: absolute;
  top: 100%;
  left: 61%;
  width: 70%;
  z-index: 2;
}
.submenu-title {
  color: #70a300 !important;
  font-weight: bold;
  font-size: 1.1rem;
}

@media (max-width: 866px) {
  .location-options-submenu {
    left: unset;
    right: -3%;
    width: 60%;
  }
}
</style>
