export default {
  getUserEmail: (state) => state.userEmail,
  getUserFullName: (state) => state.userFullName,
  getUserSsoId: (state) => state.userSsoId,
  getUserRoles: (state) => state.userInfo.roles,
  getUserRolesObject: (state) => state.userRolesObject,
  getRestrictPageAccess: (state) => state.restrictPageAccess,
  getRestrictedPageAccessType: (state) => state.restrictedPageAccessType,
  getChameleonId: (state) => state.userInfo.chameleonId,
};
