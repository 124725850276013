<template>
  <div class="mapbox-maps-section">
    <v-row class="pl-12">
      <v-col cols="12" class="pt-7 pb-0">
        <h4 class="text-left">Maps</h4>
      </v-col>
    </v-row>
    <v-row class="d-flex flex-row mt-0 pl-12 mt-n8">
      <v-col cols="4" class="d-flex text-no-wrap metric-selector mr-4 align-center pt-0">
        <label
          for="metric-selector-select"
          class=""
        >Metric Selector:</label>
        <v-select
          id="metric-selector-select"
          :menu-props="{offsetY: true}"
          v-model="metricSelected"
          :items="formatItems"
          @change="handleChange"
          height="30"
          outlined
          dense
        />
      </v-col>
      <v-col cols="2" class="d-flex text-no-wrap metric-selector align-center pt-0">
        <label
          for="location-type-select"
          class=""
        >Location Type:</label>
        <v-select
          id="location-type-select"
          outlined
          v-model="locationTypeSelected"
          :menu-props="{offsetY: true}"
          :items="locationTypeItems"
          @change="handleChange"
          dense
          height="30"
        />
      </v-col>
      <v-col class="align-center d-flex pb-6 flex-column p-0">
        <v-row class="mb-0 mt-0" style="font-weight: 500;">
          {{ determineTitle }}
        </v-row>
        <v-row class="mt-0">
          <div class="maps-gradient"></div>
        </v-row>
        <v-row class="mt-0" style="width: 150px">
          <v-col class="pl-0">
            1
          </v-col>
          <v-col class="text-right pr-0">
            {{ determineEnd }}
          </v-col>
        </v-row>
      </v-col>
      <v-col class="mt-n7">
        <v-col cols="12" class="">
          <label style="font-weight: 500;">Accessibility Options</label>
        </v-col>
        <v-col class="p-0">
          <v-row class="d-flex switch p-0" align="start">
            <v-col cols="6" class="p-0">
              <p class="bold switch-label ma-0">CVD Palette</p>
            </v-col>
            <v-col cols="3" class="p-0">
              <v-switch v-model="cvdAccesibleOpen" />
            </v-col>
          </v-row>
          <v-row class="d-flex switch p-0 mt-n3" align="start">
            <v-col cols="6" class="p-0">
              <p class="bold switch-label">Show data in table</p>
            </v-col>
            <v-col cols="3" class="p-0">
              <v-switch v-model="dataTableOpen" />
            </v-col>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
    <div
      class="loading-animation-for-pay-rate"
      style="position: absolute;"
      v-show="mapsDataLoading"
    >
      <img src="../../../assets/gears.gif" alt="loading"/>
    </div>
    <div style="height: 100vh; position: relative;">
      <div class="area-container">
        <div class="p-0" :class="{ 'col-12': !dataTableOpen, 'col-8': dataTableOpen}">
          <div class="map-container">
            <div id="map" :style="{ width: mapWidth }" class="p-0 m-0"></div>
          </div>
        </div>
        <div class="data-dable-container">
          <v-data-table
            v-if="dataTableOpen"
            style="position: sticky; top: 0;"
            :headers="filteredHeaders"
            :items="determineDataTableItems"
            hide-default-footer
            :items-per-page="100000"
            :height="determineHeight"
            fixed-header
            :search="search"
            class="mt-5"
            @after-leave="resetTableWidth"
          >
            <template v-slot:top>
              <v-row class="d-flex flex-row mb-1">
                <v-text-field
                  v-model="search"
                  label="Search"
                  class="mx-4"
                ></v-text-field>
                <v-menu
                  :close-on-content-click="true"
                  v-model="downloadMenu"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="maps-download-button"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Download
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(type, index) in downloadTypes"
                      class="v-list-item--link"
                      :key="index"
                    >
                      <v-list-item-title @click.stop="exportData(type)">
                        {{ type }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import { mapGetters } from 'vuex';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import excelParser from '../../../helpers/excel-parser';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

export default {
  name: 'MapsMapboxSection',
  props: {
    selectedStates: { type: Array, required: true },
  },
  data() {
    return {
      downloadMenu: false,
      downloadTypes: ['csv', 'xlsx'],
      mapWidth: '100%',
      search: '',
      dataTableWidth: '100%',
      accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
      mapStyle: process.env.VUE_APP_MAPBOX_STYLE,
      cvdAccesibleOpen: false,
      dataTableOpen: false,
      mapType: {
        State: {
          layerName: process.env.VUE_APP_MAPBOX_SOURCE_NAME_STATES,
          layerURL: process.env.VUE_APP_MAPBOX_SOURCE_URL_STATES,
        },
        MSA: {
          layerName: process.env.VUE_APP_MAPBOX_SOURCE_NAME_MSA,
          layerURL: process.env.VUE_APP_MAPBOX_SOURCE_URL_MSA,
        },
        County: {
          layerName: process.env.VUE_APP_MAPBOX_SOURCE_NAME_COUNTIES,
          layerURL: process.env.VUE_APP_MAPBOX_SOURCE_URL_COUNTIES,
        },
      },
      locationTypeItems: ['MSA', 'State', 'County'],
      metricSelected: 'Recruiting Difficulty Score',
      locationTypeSelected: 'MSA',
      defaultZoom: 3.8,
      metricSelectorItems: [
        {
          name: 'Recruiting Difficulty Score', db_column: 'recruiting_difficulty_score',
        },
        {
          name: 'Estimated Cost per Lead', db_column: 'estimated_cpl',
        },
        {
          name: 'Lead-to Hire Ratio', db_column: 'lead_to_hire_ratio_12mo',
        },
        {
          name: 'Estimated Cost per Hire', db_column: 'estimated_cph',
        },
        {
          name: 'Posting Competition', db_column: 'posting_competition_from_national_avg',
        },
        {
          name: 'Driver Interest', db_column: 'driver_interest_from_national_avg',
        },
        {
          name: 'Trucking Employment Count', db_column: 'trucking_employment_count',
        },
        {
          name: 'Job Postings (past 3 months)', db_column: 'active_job_postings',
        },
        {
          name: 'Companies with a Job Posting (past 3 months)', db_column: 'active_job_posting_companies',
        },
        {
          name: 'Median Advertised Salary from Job Postings', db_column: 'median_advertised_salary',
        },
        {
          name: 'Average Reported Salary', db_column: 'average_reported_salary',
        },
      ],
      mapControl: null,
      reverseColorMetrics: ['Estimated Cost per Lead', 'Lead-to Hire Ratio', 'Estimated Cost per Hire', 'Driver Interest'],
      sequentialMetrics: ['Job Postings (past 3 months)', 'Companies with a Job Posting (past 3 months)'],
      pageValue: {
        link: {
          title: 'maps',
          linkId: 'Maps',
          section: 'driver',
        },
      },
      filteredMapsData: [],
      timeoutId: null,
    };
  },
  async created() {
    let data = sessionStorage.getItem('mapsPageSearch');
    data = JSON.parse(data);
    let metricSelector;
    if (data) {
      // eslint-disable-next-line max-len
      metricSelector = this.metricSelectorItems.find((msi) => msi.name === data.metricSelector).db_column;
    }
    await this.$store.dispatch('maps/getMapsData', {
      metric_selector: metricSelector || 'recruiting_difficulty_score',
      location_type: data?.locationType || 'MSA',
      selected_states: this.selectedStates,
      labor_force_size_percentile: this.laborForcePercentileFilter,
    });
  },
  mounted() {
    const lastPageViewed = JSON.stringify(this.pageValue.link);
    sessionStorage.setItem('lastPageViewed', lastPageViewed);
    this.$store.dispatch('global/addPageLinkHighlightAndNavLinkBorder', this.pageValue);
    mapboxgl.accessToken = this.accessToken;
    const map = new mapboxgl.Map({
      container: 'map',
      style: this.mapStyle,
      center: this.defaultCenter,
      zoom: this.defaultZoom, // starting zoom
    });
    this.mapControl = map;
    this.geocoderControl = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl,
      flyTo: false,
      marker: false,
      countries: 'us',
    });
    const popup = new mapboxgl.Popup({
      closeButton: false,
      className: 'mapbox-popup',
    });
    map.addControl(this.geocoderControl);
    map.addControl(new mapboxgl.NavigationControl());
    this.geocoderControl.on('result', (e) => {
      map.fitBounds(e.result.bbox, { maxZoom: 7 });
      this.geocoderControl.clear();
    });
    map.on('load', () => {
      // This will load all of the sources and layers on initial load so we reduce the amount of
      // times we ping mapbox for tilesets
      Object.keys(this.mapType).forEach((key) => {
        const sourceLayerURL = this.mapType[key].layerURL;
        const sourceLayerName = this.mapType[key].layerName;

        this.mapControl.addSource(`${key}-locations`, {
          type: 'vector',
          url: sourceLayerURL,
        });

        this.mapControl.addLayer(
          {
            id: `${key}-locations`,
            type: 'fill',
            source: `${key}-locations`,
            'source-layer': sourceLayerName,
            paint: {
              'fill-outline-color': 'rgba(0, 0, 0, 0)',
              'fill-color': 'rgba(0, 0, 0, 0)',
            },
          },
        );

        this.mapControl.addLayer(
          {
            id: `${key}-locations-highlighted`,
            type: 'fill',
            source: `${key}-locations`,
            'source-layer': sourceLayerName,
            paint: {
              'fill-color': 'rgba(0,0,0,0)',
            },
          },
        );
      });

      map.setLayoutProperty('State-locations', 'visibility', 'none');
      map.setLayoutProperty('State-locations-highlighted', 'visibility', 'none');
      map.setLayoutProperty('County-locations', 'visibility', 'none');
      map.setLayoutProperty('County-locations-highlighted', 'visibility', 'none');
      let data = sessionStorage.getItem('mapsPageSearch');
      data = JSON.parse(data);
      if (data) {
        this.locationTypeSelected = data.locationType;
        this.metricSelected = data.metricSelector;
        this.cvdAccesibleOpen = data.cvdAccessible;
        this.dataTableOpen = data.dataTable;
      }
      this.setMapColors();
      map.on('idle', () => {
        if (this.dataTableOpen) {
          const renderedFeatures = map.queryRenderedFeatures();
          const renderedFeaturesGEOID = renderedFeatures.map((rf) => rf.properties.GEOID);
          // eslint-disable-next-line max-len
          this.filteredMapsData = this.mapsData.result.filter((md) => renderedFeaturesGEOID.includes(md.formatted_location_code));
        }
      });
    });
    map.on('mousemove', ['MSA-locations', 'State-locations', 'County-locations'], (e) => {
      map.getCanvas().style.cursor = 'pointer';
      // Use the first found feature.
      const feature = e.features[0];
      const hoveredOverArea = map.queryRenderedFeatures(
        [e.point.x, e.point.y],
        {
          layers: [feature.source],
        },
      );

      // eslint-disable-next-line max-len
      if (this.mapsData.result.find((md) => md.formatted_location_code === hoveredOverArea[0].properties.GEOID)) {
        // eslint-disable-next-line max-len
        const difficultyScore = this.mapsData.result.find((md) => md.formatted_location_code === hoveredOverArea[0].properties.GEOID)?.metric_score;
        // eslint-disable-next-line max-len
        const difficultyPercentile = this.mapsData.result.find((md) => md.formatted_location_code === hoveredOverArea[0].properties.GEOID)?.metric_percentile;
        const innerHTML = `
        <div style="display:flex;flex-direction: column;background-color:'#66666';"
          <v-row>${hoveredOverArea[0].properties.NAME}</v-row>
          <v-row>${difficultyScore || ''}</v-row>
          ${difficultyPercentile ? `<v-row v-if="difficultyPercentile">Percentile: ${difficultyPercentile}</v-row>` : ''}
        </div>`;

        popup.setLngLat(e.lngLat).setHTML(innerHTML).addTo(map);
      }
    });

    map.on('mouseleave', ['MSA-locations', 'State-locations', 'County-locations'], () => {
      map.getCanvas().style.cursor = '';
      popup.remove();
    });
  },
  computed: {
    defaultCenter() {
      if (this.dataTableOpen) {
        return [-98.120887, 30.651322];
      }
      return [-95.7129, 37.0902];
    },
    determineHeight() {
      return window.innerHeight - (window.innerHeight * (33 / 100));
    },
    determineDataTableItems() {
      if (this.dataTableOpen) {
        return this.filteredMapsData;
      }
      return this.mapsData.result;
    },
    formatItems() {
      return this.metricSelectorItems.map((item) => `${item.name}`);
    },
    ...mapGetters('maps', {
      mapsData: 'getMapsData',
      mapsDataLoading: 'getMapsDataLoading',
      selectedMinLeadCount: 'getSelectedMinLeadCountFilterValue',
      selectedMinHireCount: 'getSelectedMinHireCountFilterValue',
      selectedMinEmploymentCount: 'getSelectedMinEmploymentCountFilterValue',
      laborForcePercentileFilter: 'getLaborForcePercentileFilter',
      mapFilters: 'getMapFilters',
    }),
    determineTitle() {
      if (this.metricSelected !== 'Recruiting Difficulty Score') return 'Percentile';
      return 'Score';
    },
    determineEnd() {
      if (this.metricSelected === 'Recruiting Difficulty Score') return 10;
      return 100;
    },
    filteredHeaders() {
      // eslint-disable-next-line max-len
      return this.headers.filter((header) => this.mapsData.result.some((item) => item[header.value] !== null && item[header.value] !== undefined));
    },
    headers() {
      return [
        {
          text: 'Location Name',
          align: 'start',
          sortable: true,
          value: 'location_name',
        },
        { text: `${this.metricSelected}`, value: 'metric_score' },
        { text: `${this.metricSelected} Percentile`, value: 'metric_percentile' },
      ];
    },
  },
  watch: {
    locationTypeSelected(newLocationType, oldLocationType) {
      const locType = newLocationType.toLowerCase();

      // Reset max value for filters (depends on location type)
      this.$store.commit('maps/setMaxLeadCountFilterValue', this.mapFilters[`${locType}LeadCountValue`]);
      this.$store.commit('maps/setMaxHireCountFilterValue', this.mapFilters[`${locType}HireCountValue`]);
      this.$store.commit('maps/setMaxEmploymentCountFilterValue', this.mapFilters[`${locType}EmploymentCountValue`]);
      // Reset selection to 0
      this.$store.commit('maps/setSelectedMinLeadCountFilterValue', 0);
      this.$store.commit('maps/setSelectedMinHireCountFilterValue', 0);
      this.$store.commit('maps/setSelectedMinEmploymentCountFilterValue', 0);

      this.metricSelected = this.metricSelected.replace(oldLocationType, newLocationType);
    },
    selectedStates() {
      this.handleChange();
    },
    selectedMinLeadCount() {
      this.handleChange();
    },
    selectedMinHireCount() {
      this.handleChange();
    },
    selectedMinEmploymentCount() {
      this.handleChange();
    },
    laborForcePercentileFilter() {
      this.handleChange();
    },
    cvdAccesibleOpen: {
      immediate: false,
      handler() {
        const cacheLocation = {
          metricSelector: this.metricSelected,
          locationType: this.locationTypeSelected,
          cvdAccessible: this.cvdAccesibleOpen,
          dataTable: this.dataTableOpen,
        };
        sessionStorage.setItem('mapsPageSearch', JSON.stringify(cacheLocation));
        this.setMapColors();
      },
    },
    dataTableOpen: {
      immediate: false,
      handler(val) {
        const cacheLocation = {
          metricSelector: this.metricSelected,
          locationType: this.locationTypeSelected,
          cvdAccessible: this.cvdAccesibleOpen,
          dataTable: this.dataTableOpen,
        };
        sessionStorage.setItem('mapsPageSearch', JSON.stringify(cacheLocation));
        if (val) {
          this.handleShowDataTableChange(val);
        } else {
          this.resetTableWidth();
        }
      },
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeoutId);
    this.mapControl.remove();
  },
  methods: {
    exportData(exportType) {
      const userDownloadData = this.filteredMapsData.map((fmd) => {
        const response = {};
        response['Location Name'] = fmd.location_name;
        response[`${this.metricSelected}`] = fmd.metric_score;
        if (fmd.metric_percentile) {
          response[`${this.metricSelected} Percentile`] = fmd.metric_percentile;
        }
        return response;
      });
      const fileName = `${this.metricSelected}_By_${this.locationTypeSelected}`;
      excelParser().exportDataFromJSON(userDownloadData, fileName, 'Strategize Locations Data', exportType);
      this.downloadMenu = false;
    },
    resetTableWidth() {
      this.$nextTick(() => {
        this.mapControl.resize();
      });
    },
    handleShowDataTableChange(val) {
      this.dataTableOpen = val;
      this.$nextTick(() => {
        this.mapControl.resize();
      });
    },
    addLegendColors(leftColor, middleColor, rightColor) {
      const gradient = document.getElementsByClassName('maps-gradient')[0];
      if (gradient) {
        if (middleColor) {
          gradient.style.background = `linear-gradient(90deg, ${leftColor} 0%, ${middleColor} 50%, ${rightColor} 100%)`;
        } else {
          gradient.style.background = `linear-gradient(90deg, ${leftColor} 0%, ${rightColor} 100%)`;
        }
      }
    },
    // eslint-disable-next-line space-before-function-paren
    handleChange: _.debounce(async function(item) {
      if (this.locationTypeItems.includes(item)) {
        this.loadNewTileset(item);
      }
      this.$store.commit('maps/setMapsDataLoading', true);
      if (document.getElementById('map')) {
        document.getElementById('map').style.opacity = '20%';
      }
      const metricSelector = this.metricSelectorItems.find((msi) => msi.name === this.metricSelected.split(' by')[0]).name;
      const cacheLocation = {
        metricSelector,
        locationType: this.locationTypeSelected,
        cvdAccessible: this.cvdAccesibleOpen,
        dataTable: this.dataTableOpen,
      };
      sessionStorage.setItem('mapsPageSearch', JSON.stringify(cacheLocation));
      await this.$store.dispatch('maps/getMapsData', {
        metric_selector: this.metricSelectorItems.find((msi) => msi.name === this.metricSelected.split(' by')[0]).db_column,
        location_type: this.locationTypeSelected,
        selected_states: this.selectedStates,
        lead_count_12mo: this.selectedMinLeadCount,
        hire_count_12mo: this.selectedMinHireCount,
        trucking_employment_count: this.selectedMinEmploymentCount,
        labor_force_size_percentile: this.laborForcePercentileFilter,
      });
      this.setLoading();
    }, 2000),
    setLoading() {
      this.timeoutId = setTimeout(() => {
        this.$store.commit('maps/setMapsDataLoading', false);
        document.getElementById('map').style.opacity = '100%';
      }, 1000);
      this.setMapColors();
    },
    generateColors(colorBlind = false) {
      const colorSets = {
        default: {
          recruiting: ['#70A300', '#C4CEAE', '#BC2333', 'D6C8CA'],
          reverse: ['#BC2333', '#D9D9D9', '#70A300', 'D4D6CE'],
          trucking: ['#D9D9D9', null, '#70A300'],
          sequential: ['#D9D9D9', null, '#BC2333'],
          fallback: ['#70A300', '#D9D9D9', '#BC2333', 'D7CECF'],
        },
        colorBlind: {
          recruiting: ['#1065AB', '#CFDEEB', '#B31529', 'ECD0D3'],
          reverse: ['#B31529', '#F6EEEF', '#1065AB', 'EEF2F5'],
          trucking: ['#D9D9D9', null, '#1065AB'],
          sequential: ['#D9D9D9', null, '#B31529'],
          fallback: ['#1065AB', '#F9F9F9', '#B31529', 'D4D6CE'],
        },
      };
      const colors = colorBlind ? colorSets.colorBlind : colorSets.default;
      if (this.metricSelected === 'Recruiting Difficulty Score') {
        this.addLegendColors(...colors.recruiting.slice(0, 3));
        return this.blendRange(colors.recruiting[0].replace('#', ''), colors.recruiting[1].replace('#', ''), 3)
          .concat(this.blendRange(colors.recruiting[3].replace('#', ''), colors.recruiting[2].replace('#', ''), 3));
      }
      if (this.reverseColorMetrics.includes(this.metricSelected)) {
        this.addLegendColors(...colors.reverse.slice(0, 3));
        return this.blendRange(colors.reverse[0].replace('#', ''), colors.reverse[1].replace('#', ''), 48)
          .concat(this.blendRange(colors.reverse[3].replace('#', ''), colors.reverse[2].replace('#', ''), 48));
      }
      if (this.metricSelected === 'Trucking Employment Count') {
        this.addLegendColors(...colors.trucking);
        return this.blendRange(colors.trucking[0].replace('#', ''), colors.trucking[2].replace('#', ''), 98);
      }
      if (this.sequentialMetrics.includes(this.metricSelected)) {
        this.addLegendColors(...colors.sequential);
        return this.blendRange(colors.sequential[0].replace('#', ''), colors.sequential[2].replace('#', ''), 98);
      }
      this.addLegendColors(...colors.fallback.slice(0, 3));
      return this.blendRange(colors.fallback[0].replace('#', ''), colors.fallback[1].replace('#', ''), 48)
        .concat(this.blendRange(colors.fallback[3].replace('#', ''), colors.fallback[2].replace('#', ''), 48));
    },
    setMapColors() {
      const matchExpression = ['match', ['get', 'GEOID']];
      const fillOutlineColor = ['match', ['get', 'GEOID']];
      const colorBlind = this.cvdAccesibleOpen;
      const colors = this.generateColors(colorBlind);
      // eslint-disable-next-line no-restricted-syntax
      for (const row of this.mapsData.result) {
        if (this.metricSelected === 'Recruiting Difficulty Score') {
          const metric = Math.ceil(parseFloat(row.metric_score));
          matchExpression.push(row.formatted_location_code, colors[metric - 1]);
        } else {
          const metric = Math.ceil(parseFloat(row.metric_percentile));
          matchExpression.push(row.formatted_location_code, colors[metric - 1]);
        }
        fillOutlineColor.push(row.formatted_location_code, 'rgba(0, 0, 0, 0.5)');
      }
      matchExpression.push('rgba(0, 0, 0, 0)');
      fillOutlineColor.push('rgba(0, 0, 0, 0)');
      if (this.mapControl && this.mapControl.getLayer('State-locations-background-highlighted')) {
        this.mapControl.removeLayer(`State-locations-background-highlighted`);
      }
      if (this.mapControl && this.mapControl.getLayer('County-locations-background-highlighted')) {
        this.mapControl.removeLayer(`County-locations-background-highlighted`);
      }
      if (this.mapControl && this.mapControl.getLayer('MSA-locations-background-highlighted')) {
        this.mapControl.removeLayer(`MSA-locations-background-highlighted`);
      }
      this.mapControl.addLayer(
        {
          id: `${this.locationTypeSelected}-locations-background-highlighted`,
          type: 'fill',
          source: `${this.locationTypeSelected}-locations`,
          'source-layer': this.mapType[this.locationTypeSelected].layerName,
          paint: {
            'fill-color': matchExpression,
            'fill-outline-color': fillOutlineColor,
            'fill-opacity': 0.75,
          },
        },
      );
    },
    hexToDecimal(hex) {
      return parseInt(hex, 16);
    },
    decimalToHex(decimal) {
      return decimal.toString(16);
    },
    mix(color1, color2, weight) {
      // if weight hasn't been set (undefined)
      // or outside allowed range (0-100)
      // well make it 50 (an equal mix of both colors)
      // eslint-disable-next-line no-param-reassign, space-unary-ops, radix
      weight = (typeof(weight) === 'undefined' || parseInt(weight) < 0 || parseInt(weight) > 100) ? 50 : weight;

      // holding variable for new colour
      let newColor = '';

      // loop through hex
      // we want to extract each of the colour triplets (rr, gg, bb)
      // so we use a step of 2
      for (let i = 0; i < 6; i += 2) {
        // retrieve individual color triplet
        // and convert to decimal
        const color1triplet = this.hexToDecimal(color1.substr(i, 2));
        const color2triplet = this.hexToDecimal(color2.substr(i, 2));

        // blend colour triplets
        // eslint-disable-next-line max-len
        let calculatedTriplet = Math.floor(color1triplet + ((color2triplet - color1triplet) * (weight / 100)));

        // convert decimal back to hex
        calculatedTriplet = this.decimalToHex(calculatedTriplet);

        // if only one digit prepend with 0
        // and add to newColor variable
        // eslint-disable-next-line prefer-template
        newColor += (calculatedTriplet.length === 1) ? '0' + calculatedTriplet : calculatedTriplet;
      }
      // eslint-disable-next-line prefer-template
      return '#' + newColor;
    },
    blendRange(color1, color2, midpoints) {
      // make sure that the number of midpoints is bigger than 0
      // and add 1 because we want to include the original colours
      // eslint-disable-next-line no-param-reassign, radix
      midpoints = (parseInt(midpoints) >= 1) ? parseInt(midpoints) + 1 : 2;

      // create array for the blended colours
      const blendArray = [];

      // loop through number of midpoints
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = midpoints; i < (len + 1); i++) {
        // mix the two colours with a weight calculated on the position in the blend
        // and add to blendArray
        blendArray.push(this.mix(color1, color2, (100 / len) * i));
      }

      // ... and ship back
      return blendArray;
    },
    loadNewTileset(item) {
      // make all layers hidden
      this.mapControl.setLayoutProperty('State-locations', 'visibility', 'none');
      this.mapControl.setLayoutProperty('State-locations-highlighted', 'visibility', 'none');
      this.mapControl.setLayoutProperty('County-locations', 'visibility', 'none');
      this.mapControl.setLayoutProperty('County-locations-highlighted', 'visibility', 'none');
      this.mapControl.setLayoutProperty('MSA-locations', 'visibility', 'none');
      this.mapControl.setLayoutProperty('MSA-locations-highlighted', 'visibility', 'none');
      // make the chosen layer visible
      this.mapControl.setLayoutProperty(`${item}-locations`, 'visibility', 'visible');
      this.mapControl.setLayoutProperty(`${item}-locations-highlighted`, 'visibility', 'visible');
    },
  },
};
</script>

<style lang="scss" scoped>
  #map {
    height: 100vh;
    width: 100vw;
  }
.stratas-footer-content {
  position: fixed;
}
.loading-animation-for-pay-rate {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
#map {
  top: 0;
  height: 89%;
  position: absolute;
}
.map-container, .mapboxgl-map {
  height: 100%;
  min-height: 350px;
}
.map-container {
  flex: 1;
  position: relative;
  max-width: 100%;
}
.area-container {
  height: calc(100vh - 207px);
  display: flex;
}
.data-dable-container {
  width: 100%;
  overflow-y: auto;
  max-height: 89%;
}
.maps-gradient {
  width: 150px;
  height: 32px;
}
</style>
