<template>
  <div>
    <canvas id="costPerHire"></canvas>
  </div>
</template>

<script>
import { Chart } from 'chart.js';

export default {
  name: 'CostPerHire',
  props: {
    data: { type: Object, required: true },
    showPreviousYear: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    currentMonthInQuery: false,
    chart: null,
  }),
  watch: {
    showPreviousYear() {
      this.chart.data.datasets[1].hidden = !this.showPreviousYear;
      this.chart.update();
    },
  },
  async mounted() {
    const { months } = this.$props.data;
    const lastMonthResult = months[months.length - 1];
    this.currentMonthInQuery = await this.$store.dispatch(
      'trends/currentMonthInResult',
      lastMonthResult,
    );
    this.setRestOfMonthData();
    // eslint-disable-next-line no-new
    this.chart = new Chart(
      document.getElementById('costPerHire'),
      {
        type: 'line',
        options: this.options,
        data: this.chartData,
      },
    );
    this.chart.data.datasets[1].hidden = !this.showPreviousYear;
    this.chart.update();
  },
  computed: {
    chartData() {
      return {
        labels: this.$props.data.months,
        spanGaps: false,
        datasets: [
          // Selected Months Data
          {
            fill: false,
            borderColor: '#70A300',
            borderWidth: 5,
            pointBorderWidth: 2,
            previousYearCPH: this.$props.data.previousYearCPH,
            currentYearCPH: this.$props.data.selectedDatesCPH,
            spendAmount: this.$props.data.selectedYearSpendAmount,
            campaignCount: this.$props.data.selectedYearCampaignCount,
            data: this.$props.data.selectedDatesCPH,
            tension: 0,
            pointRadius: (context) => {
              const spendAmount = context.dataset.spendAmount[context.dataIndex];
              const campaignCount = context.dataset.campaignCount[context.dataIndex];
              // eslint-disable-next-line max-len
              if ((campaignCount < 8 && campaignCount >= 3) || (spendAmount < 50000 && spendAmount >= 20000)) {
                return 6;
              }
              return 3;
            },
            pointStyle: (context) => {
              const spendAmount = context.dataset.spendAmount[context.dataIndex];
              const campaignCount = context.dataset.campaignCount[context.dataIndex];
              // eslint-disable-next-line max-len
              if ((campaignCount < 8 && campaignCount >= 3) || (spendAmount < 50000 && spendAmount >= 20000)) {
                return 'triangle';
              }
            },
            pointBorderColor: (context) => {
              const spendAmount = context.dataset.spendAmount[context.dataIndex];
              const campaignCount = context.dataset.campaignCount[context.dataIndex];
              // eslint-disable-next-line max-len
              if ((campaignCount < 8 && campaignCount >= 3) || (spendAmount < 50000 && spendAmount >= 20000)) {
                return 'rgba(198, 83, 1, 1)';
              }
              return '#70A300';
            },
            pointBackgroundColor: (context) => {
              const spendAmount = context.dataset.spendAmount[context.dataIndex];
              const campaignCount = context.dataset.campaignCount[context.dataIndex];
              // eslint-disable-next-line max-len
              if ((campaignCount < 8 && campaignCount >= 3) || (spendAmount < 50000 && spendAmount >= 20000)) {
                return 'rgba(198, 83, 1, 1)';
              }
              return '#70A300';
            },
          },
          // Previous Year Data
          {
            fill: false,
            borderColor: '#C4C4C3',
            borderWidth: 5,
            pointBorderWidth: 2,
            currentYearCPH: this.$props.data.selectedDatesCPH,
            previousYearCPH: this.$props.data.previousYearCPH,
            data: this.$props.data.previousYearCPH,
            previousYearSpendAmounts: this.$props.data.previous_year_spend_amounts,
            tension: 0,
            // pointStyle: function(context) {
            //   const spendAmount = context.dataset.previousYearSpendAmounts[context.dataIndex];
            //   if (spendAmount < 50000 && spendAmount >= 20000) {
            //     return 'triangle';
            //   }
            // },
            // pointBorderColor: function(context) {
            //   const spendAmount = context.dataset.previousYearSpendAmounts[context.dataIndex];
            //   if (spendAmount < 50000 && spendAmount >= 20000) {
            //     return 'rgba(198, 83, 1, 1)';
            //   }
            // },
            // pointBackgroundColor: function(context) {
            //   const spendAmount = context.dataset.previousYearSpendAmounts[context.dataIndex];
            //   if (spendAmount < 50000 && spendAmount >= 20000) {
            //     return 'rgba(198, 83, 1, 1)';
            //   }
            // },
          },
        ],
      };
    },
    options() {
      return {
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
          titleFont: {
            size: 16,
          },
          bodyFont: {
            size: 16,
          },
          footerFont: {
            size: 16,
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            enabled: true,
            position: 'nearest',
            callbacks: {
              title() {
                return '';
              }, // Don't show title of tooltip
              label: (data) => {
                // Adds month name as label for tooltip hover
                const { dataIndex, label, chart } = data;
                if (data.datasetIndex === 0) {
                  return `${label} $${Math.round(
                    parseFloat(chart.data.datasets[data.datasetIndex].data[dataIndex]),
                  )}`;
                }
                if (!this.chart.data.datasets[1].hidden) {
                  return `Previous Year $${Math.round(parseFloat(chart.data.datasets[data.datasetIndex].previousYearCPH[dataIndex]))}`;
                }
              },
              labelColor: (data) => {
                const { datasetIndex } = data;
                if (datasetIndex === 0) {
                  return {
                    backgroundColor: '#70A300',
                    borderWidth: 0,
                  };
                }
                return {
                  backgroundColor: '#C4C4C3',
                  borderWidth: 0,
                };
              },
              footer: (tooltipItems) => {
                // eslint-disable-next-line max-len
                if (!tooltipItems[0].chart.data.datasets[1].hidden && tooltipItems[1] && tooltipItems[0]) {
                  const diff = parseFloat(tooltipItems[0].raw) - parseFloat(tooltipItems[1].raw);
                  return `Difference: $${Math.round(Math.abs(diff))}`;
                }
              },
            },
          },
        },
        scales: {
          y:
            {
              ticks: {
                display: true,
                callback: (value) => `$${value.toLocaleString('en-US')}`,
                step: 500,
                min: 0,
              },
              grid: {
                display: false,
              },
              beginAtZero: true,
            },
          x:
            {
              ticks: {
                max: 60,
                display: true,
              },
              grid: {
                display: false,
              },
            },
        },
        title: {
          display: false,
          text: 'CPH by Month',
        },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
  },
  destroyed() {
    this.$destroy();
  },
  methods: {
    setRestOfMonthData() {
      const selectedLength = this.chartData.datasets[0].data.length;
      const lastMonthData = [];
      const selectedDatesData = [];
      // If the query doesn't include this current month we don't need to show the light green line
      if (this.currentMonthInQuery) {
        // The purpose of this function is to make the last two values of the second dataset equal
        // to the two values of the first dataset and the rest to NaN
        for (let i = 0; i < selectedLength; i += 1) {
          if (i === selectedLength - 1) {
            lastMonthData.push(this.chartData.datasets[0].data.slice(-1).pop());
            selectedDatesData.push(NaN);
          } else if (i === selectedLength - 2) {
            lastMonthData.push(this.chartData.datasets[0].data[selectedLength - 2]);
            selectedDatesData.push(this.chartData.datasets[0].data[i]);
          } else {
            lastMonthData.push(NaN);
            selectedDatesData.push(this.chartData.datasets[0].data[i]);
          }
        }
        this.chartData.datasets[1].data = lastMonthData;
        this.chartData.datasets[0].data = selectedDatesData;
      }
    },
  },
};
</script>

<style></style>
