var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"location-selected-select filter-style"},[_c('v-text-field',{ref:"location-listing-text-field",attrs:{"id":_vm.searchFieldId,"placeholder":_vm.placeholder,"label":_vm.label,"outlined":_vm.outlined,"required":"","clearable":true,"hint":_vm.hint,"persistent-hint":"","cache-items":""},on:{"paste":_vm.handlePastedText,"click":_vm.handleTextFieldClick,"click:clear":_vm.handleClearInput},scopedSlots:_vm._u([(_vm.outsideIcon.name)?{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"left":true,"x-large":""}},[_vm._v(" "+_vm._s(_vm.outsideIcon.name)+" ")])]},proxy:true}:null],null,true),model:{value:(_vm.searchedLocation),callback:function ($$v) {_vm.searchedLocation=$$v},expression:"searchedLocation"}}),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.toggleDropDownOpen),expression:"toggleDropDownOpen"}],staticClass:"menu-wrapper"},[(
        _vm.searchedLocation &&
        _vm.searchedLocation.length >= 3 &&
        _vm.dropdownOpen &&
        _vm.initialLocation.location_name !== _vm.searchedLocation
      )?_c('v-card',{staticClass:"mx-auto location-options-menu",attrs:{"tile":""},nativeOn:{"scroll":function($event){return _vm.handleLocationScroll.apply(null, arguments)}}},[_c('v-list',[(!_vm.loading)?_c('v-list-item-group',{attrs:{"color":"primary"}},[(_vm.filteredLocations.length === 0)?_c('v-list-item',{on:{"click":_vm.toggleDropDownOpen}},[_c('v-list-item-title',[_vm._v(" No Result Found")])],1):_vm._e(),_vm._l((_vm.filteredLocations),function(location,i){return _c('v-list-item',{key:i,class:{
              'dropdown-submenu':
                location.location_type === 'zip' || location.location_type === 'city',
            },attrs:{"id":`location_item${i}`,"tabindex":"0"},on:{"click":function($event){$event.preventDefault();return _vm.determineOpenOrEmit(location, i)}}},[_c('v-list-item-content',{on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.determineOpenOrEmit(location, i)}}},[(location.location_type === 'zip' || location.location_type === 'city')?_c('div',{staticClass:"list-item-content"},[_c('div',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.determineLocationName(location)))]),_c('v-list-item-subtitle',{staticClass:"location_type"},[_vm._v(_vm._s(location.location_type))])],1),_c('v-icon',{attrs:{"aria-label":"expand location to view more","color":"grey lighten-1"}},[_vm._v("arrow_right")])],1):_c('div',[_c('v-list-item-title',[_vm._v(" "+_vm._s(location.location_name))]),_c('v-list-item-subtitle',{staticClass:"location_type"},[_vm._v(_vm._s(location.location_type))])],1)])],1)})],2):[_c('loading-spinner',{staticClass:"loading-spinner",attrs:{"loading":_vm.loading,"color":'#70A300',"size":'50px'}})]],2)],1):_vm._e(),(_vm.isSubMenuVisible)?_c('SubMenuItems',{attrs:{"subMenuData":this.subMenuData,"selectedMenuItem":this.selectedMenuItem},on:{"searchLoc":function($event){return _vm.searchLocation($event)}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }