import { debounce } from 'lodash/function';

function debounceLocalMethod(debounceMethod, milliseconds) {
  const debounceCaller = debounce(debounceMethod, milliseconds);
  debounceCaller();
}

function addLabelWithInput(mainNode, labelTitle) {
  const newElement = document.createElement('label');
  const inputId = mainNode.childNodes[0].getAttribute('id');
  newElement.setAttribute('for', inputId);
  newElement.style.opacity = 0;
  const textElement = document.createTextNode(labelTitle);
  newElement.appendChild(textElement);
  mainNode.prepend(newElement);
}

function addLabelForMdInputFake(mainNode, labelTitle) {
  const newElement = document.createElement('label');
  mainNode.setAttribute('id', 'forFakeInput');
  newElement.setAttribute('for', 'forFakeInput');
  newElement.style.opacity = 0;
  const textElement = document.createTextNode(labelTitle);
  newElement.appendChild(textElement);
  mainNode.parentNode.insertBefore(newElement, mainNode.previousSibling);
}

function appendChildToParent(className, childClassName) {
  class ChildComponent {
    // eslint-disable-next-line class-methods-use-this
    onAdd() {
      const div = document.createElement('div');
      div.className = className;
      div.appendChild(document.getElementById(childClassName));

      return div;
    }
  }
  return new ChildComponent();
}

export {
  addLabelWithInput, addLabelForMdInputFake, appendChildToParent, debounceLocalMethod,
};
