<template>
  <div>
    <div v-if="!dataPresent">
      <v-card class="no-data" elevation="2" width="100%">
        <NoData
          class="competition-page-no-data"
          error-message="No Data to Display"
          error-sub-message="Please adjust your filter selections to get results."
        />
        <img alt="blank pay rate chart icon"
          src="../../../assets/Company-Graph-background-viz.svg"
          class="no-data-image"
          />
      </v-card>
    </div>
    <v-col v-if="dataPresent">
      <v-row v-if="!!title">
        <v-col>
          <v-card-title>
            {{ title }}
          </v-card-title>
        </v-col>
        <v-col cols="3" class="float-right">
          <slot></slot>
        </v-col>
      </v-row>
      <v-row class="mb-0">
        <v-card-subtitle v-if="useFilters" class="mt-0 pt-0 pb-0 mb-0">
          <div class="grouped">
            <p class="mb-0" v-if="useFilterName"><b>{{ filterName }}</b></p>
            <v-menu
              bottom
              :offset-x="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs"
                  v-on="on" aria-label="filter the data" small> mdi-sort-variant </v-icon>
              </template>

              <v-list dense>
                <v-list-item-group>
                  <v-list-item
                    v-for="item in sortByOptions"
                    :key="item.key"
                    @click="handleSorting(item)"
                  >
                    <v-list-item-icon><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
                    <v-list-item-title>{{item.title}}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
        </v-card-subtitle>
      </v-row>
      <v-row class="d-flex flex-column mr-0 ml-0 mt-0">
        <div style="overflow-y: scroll; max-width: 100%;"
          :class="{'scroll-box-body': scrollable}"
        >
          <div>
            <canvas :id="id" :height="determineHeight"></canvas>
          </div>
        </div>
        <v-row style="height: 50px;" v-show="scrollable" class="mb-0">
          <canvas :id="`${id}-x-axis-chart`"></canvas>
        </v-row>
        <div v-if="paginated && usePagination" class="justify-space-between mb-0">
          <Pagination
            :paginations="determinePaginations"
            :data-per-page="100"
            :key-for-footer="keyForFooter"
            :count-of-data="barChartData.length"
            @page-selected="handlePaginationChange"
          />
        </div>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import NoData from '../StyledComponents/NoData.vue';
import Pagination from '../../StyledComponents/Pagination.vue';

export default {
  name: 'HorizontalBarChart',
  components: {
    NoData,
    Pagination,
  },
  props: {
    keyForFooter: {
      type: String,
      required: false,
      default: 'companies',
    },
    id: {
      type: String,
      required: true,
      default: 'horizontal-bar-chart',
    },
    barChartData: {
      type: Array,
      required: true,
      default: () => [],
    },
    keyForLabels: {
      type: String,
      required: true,
      default: '',
    },
    keyForData: {
      type: String,
      required: true,
      default: '',
    },
    dataPresent: {
      type: Boolean,
      required: true,
      default: true,
    },
    usePagination: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    useFilters: {
      type: Boolean,
      required: false,
      default: false,
    },
    scrollable: {
      type: Boolean,
      required: false,
      default: false,
    },
    useFilterName: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterName: {
      type: String,
      required: false,
      default: 'Company Names',
    },
    sortByOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    barColor: {
      type: String,
      required: false,
      default: '#929292',
    },
  },
  data() {
    return {
      chart: null,
      page: 1,
      xAxisChart: null,
    };
  },
  computed: {
    determinePaginations() {
      return Math.ceil(this.barChartData.length / 100);
    },
    paginated() {
      return this.barChartData.length > 100;
    },
    determineHeight() {
      if (this.determinePaginations > 1) {
        return 3000;
      }
      return this.barChartData.length * 50;
    },
    suggestedMax() {
      return Math.max(...this.barChartData.slice(0, 100).map((bcd) => bcd[this.keyForData]));
    },
    suggestedMin() {
      return Math.min(...this.barChartData.slice(0, 100).map((bcd) => bcd[this.keyForData]));
    },
  },
  mounted() {
    if (this.dataPresent) {
      const labels = this.barChartData.slice(0, 100).map((data) => data[this.keyForLabels]);
      const chartData = this.barChartData.slice(0, 100).map((data) => data[this.keyForData]);
      const data = {
        labels,
        datasets: [{
          axis: 'y',
          data: chartData,
          fill: false,
          barThickness: 8,
          minBarLength: 5,
          borderRadius: 5,
          backgroundColor: this.barColor,
        }],
      };
      const ctx = document.getElementById(this.id);
      const ctx2 = document.getElementById(`${this.id}-x-axis-chart`);
      this.chart = new Chart(ctx, {
        type: 'bar',
        data,
        options: {
          indexAxis: 'y',
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
          },
          scales: {
            y: {
              grid: {
                display: false,
              },
              ticks: {
                autoSkip: false,
                font: {
                  weight: 'bold',
                },
                // eslint-disable-next-line object-shorthand, no-unused-vars
                callback: function (value, index, ticks) {
                  const label = this.chart.data.labels[index];
                  if (label.length > 25) {
                    return `${label.slice(0, 25)}...`;
                  }
                  return label;
                },
              },
            },
          },
        },
      });
      if (this.scrollable) {
        this.xAxisChart = new Chart(ctx2, {
          type: 'bar',
          options: {
            layout: {
              padding: {
                right: 10,
              },
            },
            indexAxis: 'y',
            maintainAspectRatio: false,
            responsive: true,
            legend: {
              display: false,
            },
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: false,
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  autoSkip: false,
                  preceision: 0,
                },
                suggestedMax: 3,
              },
              y: {
                afterFit: ((context) => {
                  // eslint-disable-next-line no-param-reassign
                  context.width += this.chart.chartArea.left;
                }),
                grid: {
                  display: false,
                  drawTicks: false,
                  drawBorder: false,
                },
              },
            },
          },
        });
        this.adjustSuggestedMinAndMaxForCharts(chartData, this.xAxisChart, false);
      }
      this.adjustSuggestedMinAndMaxForCharts(chartData, this.chart);
    }
  },
  beforeDestroy() {
    if (this.xAxisChart) {
      this.xAxisChart.destroy();
    }

    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods: {
    adjustSuggestedMinAndMaxForCharts(formattedChartData, chart, mainChart = true) {
      const maxData = Math.max(...formattedChartData);
      // eslint-disable-next-line no-param-reassign
      chart.options.scales.x = {
        grid: {
          display: false,
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          autoSkip: false,
          display: (!mainChart && this.scrollable) || (mainChart && !this.scrollable),
          precision: 0,
        },
        max: maxData + 1,
        min: 0,
      };
      chart.update();
    },
    handlePaginationChange(page) {
      const splitAmount = (page - 1) * 100;
      // eslint-disable-next-line max-len
      const chartData = this.barChartData.slice(splitAmount, splitAmount + 100).map((data) => data[this.keyForData]);
      // eslint-disable-next-line max-len
      const labels = this.barChartData.slice(splitAmount, splitAmount + 100).map((data) => data[this.keyForLabels]);
      this.chart.data.labels = labels;
      this.chart.data.datasets[0].data = chartData;
      this.adjustSuggestedMinAndMaxForCharts(chartData, this.chart);
      if (this.xAxisChart) {
        this.adjustSuggestedMinAndMaxForCharts(chartData, this.xAxisChart, false);
      }
    },
    handleSorting(data) {
      const chartData = this.sortBy(data.columnName, data.ascending, data.integer);
      const formattedBarData = chartData.map((d) => d[this.keyForData]);
      const labels = chartData.map((d) => d[this.keyForLabels]);
      this.chart.data.labels = labels;
      this.chart.data.datasets[0].data = formattedBarData;
      this.chart.update();
    },
    sortBy(columnName, ascending, integer) {
      const splitAmount = (this.page - 1) * 100;

      if (integer) {
        // eslint-disable-next-line max-len, no-confusing-arrow
        return this.barChartData.sort((a, b) => ascending ? a[columnName] - b[columnName] : b[columnName] - a[columnName]).slice(splitAmount, splitAmount + 100);
      }

      // eslint-disable-next-line max-len, no-confusing-arrow
      return this.barChartData.sort((a, b) => ascending ? a[columnName].localeCompare(b[columnName]) : b[columnName].localeCompare(a[columnName])).slice(splitAmount, splitAmount + 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.v-card__title {
  word-break: break-word;
}
.grouped {
  display:flex;
  .mdi-sort-variant {
    align-items: baseline !important;
  }
}
.scroll-box-body {
  height: 450px;
  &::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/* Set height for screen resolutions up to 1024x768 */
@media screen and (max-width: 1920px) and (max-height: 1080px) {
  .scroll-box-body {
    height: 450px;
  }
}

/* Set height for screen resolutions up to 1440x900 */
@media screen and (max-width: 1440px) and (max-height: 900px) {
  .scroll-box-body {
    height: 900px;
  }
}

.titles {
  display: flex;
  .title-and-subtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.competition-page-no-data {
  position: absolute;
  height: 100%;
  width: 100%
}
.no-data-image {
  opacity: 20%;
}
</style>
