<!-- eslint-disable max-len -->
<template>
  <div>
    <div v-if="mostRecentMonthTrendsLoading" class="three-month-average-grid">
      <LoadingSpinner
        class="three-month-loading-spinner"
        :loading="mostRecentMonthTrendsLoading"
        :size="'100px'"
        :color="'#70A300'"
        :borderWidth="'6px'"
      />
    </div>
    <div v-else class="d-flex center">
      <v-tooltip top :disabled="!determineWarning(mostRecentMonthTrends.cpl_banner_data, 'current_month_cpl') && !determineNoData(mostRecentMonthTrends.cpl_banner_data, 'current_month_cpl')">
        <template v-slot:activator="{ on, attrs}">
          <div v-bind="attrs" v-on="on" class="three-month-average-grid v-sheet v-card theme--light" :class="{'no-data-bin': determineNoData(mostRecentMonthTrends.cpl_banner_data, 'current_month_cpl'), 'warning-data': determineWarning(mostRecentMonthTrends.cpl_banner_data, 'current_month_cpl')}">
            <v-col cols="sm" class="pb-0 mt-4">
              <div class="three-month-average-cpl-title three-month-average-title center">
                {{ mostRecentMonthTrends.cpl_banner_data?.cpl_month || '-' }} CPL
              </div>
            </v-col>
            <v-col cols="sm" class="pb-0 mt-n3">
              <div v-if="!determineNoData(mostRecentMonthTrends.cpl_banner_data, 'current_month_cpl')" class="three-month-average-cpl-value three-month-average-value center">
                ${{ mostRecentMonthTrends.cpl_banner_data?.current_month_cpl?.toFixed(2) && !determineNoData(mostRecentMonthTrends.cpl_banner_data, 'current_month_cpl') ?  mostRecentMonthTrends.cpl_banner_data?.current_month_cpl?.toFixed(2) : ' - ' }}
              </div>
              <div v-else class="three-month-average-cpl-value three-month-average-value center">
                $-
              </div>
            </v-col>
            <v-col cols="sm" class="mt-0 pt-0 mb-0 mt-0 pb-0 mt-n3">
              <span class="three-month-average-cpl-mom subtext center ">
                <UpOrDownArrow
                  :up="mostRecentMonthTrends.cpl_banner_data?.mom_cpl >= 0"
                  :color="mostRecentMonthTrends.cpl_banner_data?.mom_cpl >= 0 ? '#666666' : '#70A300'"
                  :size="'50px'"
                />
                {{ (mostRecentMonthTrends.cpl_banner_data?.mom_cpl * 100).toFixed(0) && !determineNoData(mostRecentMonthTrends.cpl_banner_data, 'current_month_cpl') ? Math.abs(mostRecentMonthTrends.cpl_banner_data?.mom_cpl * 100).toFixed(0) : ' - ' }}% MoM
              </span>
            </v-col>
            <v-col cols="sm" class="mt-0 pt-0 mb-4 mt-0 pb-0 mt-n3">
              <span class="three-month-average-cpl-yoy subtext center">
                <UpOrDownArrow
                  :up="mostRecentMonthTrends.cpl_banner_data?.yoy_cpl >= 0"
                  :color="mostRecentMonthTrends.cpl_banner_data?.yoy_cpl >= 0 ? '#666666' : '#70A300'"
                  :size="'50px'"
                />{{ (mostRecentMonthTrends.cpl_banner_data?.yoy_cpl* 100).toFixed(0) && !determineNoData(mostRecentMonthTrends.cpl_banner_data, 'current_month_cpl') ? Math.abs(mostRecentMonthTrends.cpl_banner_data?.yoy_cpl* 100).toFixed(0) : ' - '}}% YoY
              </span>
            </v-col>
          </div>
        </template>
        <span v-if="determineWarning(mostRecentMonthTrends.cpl_banner_data, 'current_month_cpl')"><p>Small sample size</p>
                    Due to the small sample size of your search, the data is more susceptible
                    to being skewed by outliers. Consider expanding your search criteria.</span>
                    <span v-else><p>Sample size is too small</p>
                      Your search does not capture enough data to display results.
                      You will need to widen your search criteria.</span>
      </v-tooltip>
      <v-tooltip top :disabled="!determineWarning(mostRecentMonthTrends.cph_and_lth_banner_data, 'current_month_cph') && !determineNoData(mostRecentMonthTrends.cph_and_lth_banner_data, 'current_month_cph')">
        <template v-slot:activator="{ on, attrs}">
          <div v-bind="attrs" v-on="on" class="three-month-average-grid v-sheet v-card theme--light" :class="{'no-data-bin': determineNoData(mostRecentMonthTrends.cph_and_lth_banner_data, 'current_month_cph'), 'warning-data': determineWarning(mostRecentMonthTrends.cph_and_lth_banner_data, 'current_month_cph')}">
            <v-col cols="sm" class="pb-0 mt-4">
              <div class="three-month-average-cph-title three-month-average-title center">
                {{ mostRecentMonthTrends.cph_and_lth_banner_data?.cph_and_lth_month || '-' }} CPH
              </div>
            </v-col>
            <v-col cols="sm" class="pb-0 mt-n3">
              <div class="three-month-average-cph-value three-month-average-value center">
                ${{ mostRecentMonthTrends.cph_and_lth_banner_data?.current_month_cph?.toLocaleString() && !determineNoData(mostRecentMonthTrends.cph_and_lth_banner_data, 'current_month_cph') ? mostRecentMonthTrends.cph_and_lth_banner_data?.current_month_cph?.toLocaleString() : ' - ' }}
              </div>
            </v-col>
            <v-col cols="sm" class="mt-0 pt-0 mb-0 mt-0 pb-0 mt-n3">
              <span class="three-month-average-cph-mom subtext center">
                <UpOrDownArrow
                  :up="mostRecentMonthTrends.cph_and_lth_banner_data?.mom_cph >= 0"
                  :color="mostRecentMonthTrends.cph_and_lth_banner_data?.mom_cph >= 0 ? '#666666' : '#70A300'"
                  :size="'50px'"
                />{{ (mostRecentMonthTrends.cph_and_lth_banner_data?.mom_cph * 100).toFixed(0) && !determineNoData(mostRecentMonthTrends.cph_and_lth_banner_data, 'current_month_cph') ? Math.abs(mostRecentMonthTrends.cph_and_lth_banner_data?.mom_cph * 100).toFixed(0) : ' - ' }}% MoM
              </span>
            </v-col>
            <v-col cols="sm" class="mt-0 pt-0 mb-4 mt-0 pb-0 mt-n3">
              <span class="three-month-average-cph-yoy subtext center">
                <UpOrDownArrow
                  :up="mostRecentMonthTrends.cph_and_lth_banner_data?.yoy_cph >= 0"
                  :color="mostRecentMonthTrends.cph_and_lth_banner_data?.yoy_cph >= 0 ? '#666666' : '#70A300'"
                  :size="'50px'"
                />{{ (mostRecentMonthTrends.cph_and_lth_banner_data?.yoy_cph * 100)?.toFixed(0) && !determineNoData(mostRecentMonthTrends.cph_and_lth_banner_data, 'current_month_cph') ? Math.abs(mostRecentMonthTrends.cph_and_lth_banner_data?.yoy_cph * 100)?.toFixed(0) : ' - ' }}% YoY
              </span>
            </v-col>
          </div>
        </template>
        <span v-if="determineWarning(mostRecentMonthTrends.cph_and_lth_banner_data, 'current_month_cph')"><p>Small sample size</p>
                    Due to the small sample size of your search, the data is more susceptible
                    to being skewed by outliers. Consider expanding your search criteria.</span>
                    <span v-else><p>Sample size is too small</p>
                      Your search does not capture enough data to display results.
                      You will need to widen your search criteria.</span>
      </v-tooltip>
      <v-tooltip top :disabled="!determineWarning(mostRecentMonthTrends.cph_and_lth_banner_data, 'yoy_lth') && !determineNoData(mostRecentMonthTrends.cph_and_lth_banner_data, 'current_month_lth')">
        <template v-slot:activator="{ on, attrs}">
          <div v-bind="attrs" v-on="on" class="three-month-average-grid v-sheet v-card theme--light" :class="{'no-data-bin': determineNoData(mostRecentMonthTrends.cph_and_lth_banner_data, 'current_month_lth'), 'warning-data': determineWarning(mostRecentMonthTrends.cph_and_lth_banner_data, 'current_month_lth')}">
            <v-col cols="sm" class="pb-0 mt-4">
              <div class="three-month-average-lth-title three-month-average-title center">
                {{ mostRecentMonthTrends.cph_and_lth_banner_data?.cph_and_lth_month || '-' }} LTH
              </div>
            </v-col>
            <v-col cols="sm" class="pb-0 mt-n3">
              <div class="three-month-average-lth-value three-month-average-value center">
                {{ mostRecentMonthTrends.cph_and_lth_banner_data?.current_month_lth && !determineNoData(mostRecentMonthTrends.cph_and_lth_banner_data, 'current_month_lth') ? mostRecentMonthTrends.cph_and_lth_banner_data?.current_month_lth : ' - ' }}
              </div>
            </v-col>
            <v-col cols="sm" class="mt-0 pt-0 mb-0 mt-0 pb-0 mt-n3">
              <span class="three-month-average-lth-mom subtext center">
                <UpOrDownArrow
                  :up="mostRecentMonthTrends.cph_and_lth_banner_data?.mom_lth >= 0"
                  :color="mostRecentMonthTrends.cph_and_lth_banner_data?.mom_lth >= 0 ? '#666666' : '#70A300'"
                  :size="'50px'"
                />{{ (mostRecentMonthTrends.cph_and_lth_banner_data?.mom_lth * 100).toFixed(0) && !determineNoData(mostRecentMonthTrends.cph_and_lth_banner_data, 'current_month_lth') ? Math.abs(mostRecentMonthTrends.cph_and_lth_banner_data?.mom_lth * 100).toFixed(0) : ' - ' }}% MoM
              </span>
            </v-col>
            <v-col cols="sm" class="mt-0 pt-0 mb-4 mt-0 pb-0 mt-n3">
              <span class="three-month-average-lth-yoy subtext center">
                <UpOrDownArrow
                  :up="mostRecentMonthTrends.cph_and_lth_banner_data?.yoy_lth >= 0"
                  :color="mostRecentMonthTrends.cph_and_lth_banner_data?.yoy_lth >= 0 ? '#666666' : '#70A300'"
                  :size="'50px'"
                />{{ (mostRecentMonthTrends.cph_and_lth_banner_data?.yoy_lth * 100)?.toFixed(0) && !determineNoData(mostRecentMonthTrends.cph_and_lth_banner_data, 'current_month_lth') ? Math.abs(mostRecentMonthTrends.cph_and_lth_banner_data?.yoy_lth * 100)?.toFixed(0) : ' - ' }}% YoY
              </span>
            </v-col>
          </div>
        </template>
        <span v-if="determineWarning(mostRecentMonthTrends.cph_and_lth_banner_data, 'current_month_lth')"><p>Small sample size</p>
                    Due to the small sample size of your search, the data is more susceptible
                    to being skewed by outliers. Consider expanding your search criteria.</span>
                    <span v-else><p>Sample size is too small</p>
                      Your search does not capture enough data to display results.
                      You will need to widen your search criteria.</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import UpOrDownArrow from '../StyledComponents/UpOrDownArrow.vue';
import LoadingSpinner from '../StyledComponents/LoadingSpinner.vue';

export default {
  name: 'TrendsThreeMonthAverage',
  components: {
    UpOrDownArrow,
    LoadingSpinner,
  },
  computed: {
    ...mapGetters({
      mostRecentMonthTrendsLoading: 'trends/getMostRecentMonthTrendsLoading',
      mostRecentMonthTrends: 'trends/getMostRecentMonthTrends',
    }),
  },
  methods: {
    determineNoData(dataObject, attribute) {
      if (!dataObject) {
        return true;
      }
      if (!dataObject[attribute]) {
        return true;
      }
      return dataObject.campaign_count < 3 || dataObject.spend_amount < 20000;
    },
    determineWarning(dataObject, attribute) {
      if (this.determineNoData(dataObject, attribute)) {
        return false;
      }
      // eslint-disable-next-line max-len
      if (!dataObject) {
        return false;
      }
      if (!dataObject[attribute]) {
        return false;
      }
      // eslint-disable-next-line max-len
      return (dataObject.campaign_count < 8 && dataObject.campaign_count >= 3) || (dataObject.spend_amount < 50000 && dataObject.spend_amount >= 20000);
    },
  },
};
</script>
